<template lang="pug">
  .phone-capture-modal
    CaptureModal(:modal-shown="isPhoneModalVisible" :close-modal-method="closePhoneModal")
      template(#header="")
        .email-success-header.xs-pb-100m.xs-mb-100m
          .ty-icon.brand-color-1
            mr-icon(name="check-circle" height="1.3em")
          transition(name="fade")
            .ty-text.upper.xs-f-small.max-at-tweak.brand-color-1 {{ upperTitle }}

        .title.upper.brand-color-1.bold.xs-f-xmedium.max-at-tweak(v-if="!subscribedToSms") {{ title }}

      template(#content="")
        transition(name="fade")
          p.description.mb-20(v-if="subscribedToSms" v-html="successDescription")
          p.description(v-else v-html="defaultDescription")

        template(v-if="!subscribedToSms")
          transition(name="fade")
            PhoneCaptureForm(@submit-phone="onSubmitPhoneNumber" :btn-disabled="loading")
          transition(name="fade")
            p.error-message.feedback-color-1.xs-f-xsmall.sm-f-xxsmall.max-at-tweak.mb-10(v-if="notEligible") {{ phoneCaptureError }}

        p.conditions.xs-f-xsmall.sm-f-xxsmall.max-at-tweak(v-if="!subscribedToSms" v-html="phoneModalConfig.conditions")

</template>

<script>
  import { mapState, mapActions, mapMutations } from "vuex";
  import CaptureModal from "../CaptureModal/CaptureModal";
  import PhoneCaptureForm from "../PhoneCaptureForm/PhoneCaptureForm";
  import { addFirstTimeVisitorPhoneAndOffer } from "@services/vueCustomerSvc";
  export default {
    name: "PhoneCaptureModal",
    components: {
      PhoneCaptureForm,
      CaptureModal
    },

    inject: ['getObjProperty'],

    data() {
      return {
        notEligible: false,
        phoneCaptureError: null,
        loading: false
      };
    },

    computed: {
    ...mapState("emailCapture", [
      "isPhoneModalVisible",
      "subscribedToSms",
      "phoneModalConfig"
    ]),

    upperTitle() {
      // The initial "Thank you!" title is only shown when the user has just subscribed to email and before subscribed to sms
      return this.subscribedToSms ? this.getObjProperty(this.phoneModalConfig, 'title.success') : 'Thank you!';
    },

    title() {
      return this.getObjProperty(this.phoneModalConfig, 'title.default');
    },

    successDescription() {
      return this.getObjProperty(this.phoneModalConfig, 'description.success');
    },

    defaultDescription() {
      return this.getObjProperty(this.phoneModalConfig, 'description.default');
    },
    },

    methods: {
    ...mapActions(['notifyError', 'notifyWarn']),
    ...mapActions("emailCapture", [
      "closePhoneModal"
    ]),

    ...mapActions('customer', [
      'refreshCustomerCdata',
      'getMarketingPreferences',
    ]),

    ...mapMutations("emailCapture", [
      "setSubscribedToSms"
    ]),

    onSubmitPhoneNumber(number) {
      this.phoneCaptureError = null;
      const isHcbPhoneCapture = this.getObjProperty(this.phoneModalConfig, 'phoneSource') === 'HCB_popup_phone_capture';

      const payload = {
        phone: number,
        offerCode: this.getObjProperty(this.phoneModalConfig, 'code'),
      };

      if (isHcbPhoneCapture) {
        payload.isBookingOfferCode = true;
      }

      this.loading = true;
      addFirstTimeVisitorPhoneAndOffer(payload).then((res) => {
        let eventName = 'Email capture phone # - Submitted';
        if (isHcbPhoneCapture) {
          eventName = 'HCB capture phone # - Submitted';
        }
        this.trackMREvent(eventName);

        const offerApplied = !!this.getObjProperty(res, 'data.offerApplied');
        if (!offerApplied) {
          return this.notifyWarn(`You are not eligible for this offer`);
        }

        this.setSubscribedToSms(true);
        this.refreshCustomerCdata();
        this.getMarketingPreferences();
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
        this.notEligible = true;
        const code = this.getObjProperty(err, 'response.data.code');

        if (['USER_ALREADY_EXISTS', 'PHONE_ALREADY_SUBSCRIBED'].includes(code)) {
          this.phoneCaptureError = this.getObjProperty(err, 'response.data.message');
          return;
        }

        this.phoneCaptureError = "We were not able to apply this promotion.";
      });
    }
    }
  };
</script>

<style scoped lang="stylus">
.phone-capture-modal
  .email-success-header
    border-bottom 1px solid ui-color-5
    display flex
    flex-direction row
    justify-content flex-start
    align-items baseline

    .ty-icon
      padding 0 0.4em

  >>> .phone-capture-form
    padding-bottom 0.5em

    .input-errors
      position fixed
</style>