
import trackEventMixin from "@mixins/trackEventMixin";

export default {
  mixins: [trackEventMixin],

  methods: {
    _mix_notifyExperimentsViewed(iterableMenu, possibleChildrenNames, cumulativeExperimentsViewed) {
      (iterableMenu || []).forEach(menuItem => {
        let experimentName = menuItem.testExperiment && menuItem.testExperiment.name;

        if (experimentName && this.experiments[experimentName] && !cumulativeExperimentsViewed[experimentName]) {
          this.mix_trackExperimentViewed(experimentName);
          cumulativeExperimentsViewed[experimentName] = true;
        }

        (possibleChildrenNames || []).forEach(childItemName => {
          let childIterable = iterableMenu[childItemName] || [];

          childIterable.forEach(subIterablemMenu => this._mix_notifyExperimentsViewed(subIterablemMenu, possibleChildrenNames, cumulativeExperimentsViewed));
        });
      });
    },

    mix_notifyExperimentsViewed(iterableMenu, possibleChildrenNames) {
      this._mix_notifyExperimentsViewed(iterableMenu, possibleChildrenNames, {});
    },

    mix_filterElementsWeHaveToShow (objectsArray) {
      return (objectsArray || []).filter(element => {
        if (!element.testExperiment || Object.keys(element.testExperiment).length === 0) {
          return true;
        }

        let experimentVersion = this.experiments && this.experiments[element.testExperiment.name];
        let inSpecificExperimentVersion = experimentVersion == (element.testExperiment.version || 'B');

        if (element.testExperiment.exclude) {
          return (!experimentVersion || !inSpecificExperimentVersion);
        }

        return this.experiments && experimentVersion !== undefined && inSpecificExperimentVersion;
      });
    },

    mix_copyOnlyElementsToShow (originalObject) {
      if (!Array.isArray(originalObject)) {
        if (originalObject && typeof originalObject === 'object' && originalObject.constructor === Object) {
          let copy = {};

          Object.keys(originalObject).forEach(key => copy[key] = this.mix_copyOnlyElementsToShow(originalObject[key]));

          return copy;
        } else {
          return originalObject;
        }
      }

      return this.mix_filterElementsWeHaveToShow(originalObject).map(element => this.mix_copyOnlyElementsToShow(element));
    },
  },
};
