<template lang="pug">
  nav.site-nav-wrap.xs-12(:class="{ open: siteNavMobileNavOpen }")
    template(v-if="showMobileNav")
      SiteNavMobile

    template(v-else)
      SiteNavDesktop

</template>

<script>
  import dataToolSvc from '@services/dataToolSvc';
  import SiteNavDesktop from "./SiteNavDesktop";
  import SiteNavMobile from "./SiteNavMobile";
  import { mapGetters, mapState } from "vuex";

  export default {
    name: "SiteNav",

    components: {
      SiteNavDesktop,
      SiteNavMobile,
    },

    data() {
      return {
        shopNav: null,
        navItems: null,
        navLinks: null
      };
    },

    computed: {
      ...mapGetters('customer', [
        'isCustomerPresent',
      ]),

      ...mapState('siteNav', [
        'siteNavMobileNavOpen'
      ]),

      ...mapState('global', [
        'windowData'
      ]),

      showMobileNav() {
        const { breakpoint } = this.windowData;
        return !['desktop', ''].includes(breakpoint);
      },

      showRcdNavRedesign() {
        return this.isCustomerPresent;
      },
    },

    mounted() {
      const mixinKey = this.showRcdNavRedesign ? 'top-nav-rc' : 'top-nav';
      dataToolSvc.getData({
        mixinKey
      }).then(res => {
        this.shopNav = res.data.ShopNav;
        this.navItems = res.data.NavItems;
        this.navLinks = res.data.NavLinks;
      });
    }
  };
</script>

<style scoped lang="stylus">
  @media mq-desktop-md-less
    nav.site-nav-wrap
      position fixed
      right 0
      top 0
      bottom 0
      display flex
      align-self flex-start
      flex-direction column
      left 0
      transform translateX(-100%)
      transition(transform 0.3s ease-in-out)
      background-color color-white

      &.open
        transform none
        transition(transform 0.3s ease-in-out)

      .site-nav-item
      .site-nav-title
        width 100%
        display block
        text-align left

      #extole_zone_global_header
        display inline-block

</style>