import vueCmsSvc from "@services/vueCmsSvc";

export const state = {};

export const actions = {

  async showPartialModal({ dispatch }, params) {

    let props = {};
    let mixinKey = params;
    if (typeof params === 'object') {
      mixinKey = params.mixinKey;
      props = params;
    }

    let response;
    try {
      response = await vueCmsSvc.getPartial({ mixinKey });
    } catch (err) {
      dispatch('notifyError', { silent: true, err }, { root: true });
    }

    if (!response) {
      return;
    }

    const { data: { html, css } } = response;

    dispatch('modal/showModal', {
      component: 'PartialModal',
      props: {
        mixinKey,
        html,
        css,
      },
      ...props,
    }, { root: true });

  },
};

export const getters = {};

export const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};