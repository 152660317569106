import mrApi from './mrApi';

export default {
  getUrmProduct,
  getClientUsageWindow,
  doDowngrade
};

export function getUrmProduct(params) {
  return mrApi.get('/api/urm/getUrmProduct', { params });
}

export function getClientUsageWindow(params) {
  return mrApi.get('/api/urm/getClientUsageWindow', { params });
}

export function doDowngrade(params) {
  return mrApi.post('/api/urm/downgradeMembership', params);
}