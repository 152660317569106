<template lang="pug">
  transition(name="scroll-bottom")
    .capture-modal.xs-f-small.max-at-tweak(v-if="modalShown")
      .capture-modal-wrapper.xs-12.xs-f-small.sm-f-xsmall.md-f-xsmall.max-at-tweak
        .header.mb-10
          slot(name="header")

          button.btn-close.transparent-btn.xs-f-medium.sm-f-small(type="button" @click="closeModalMethod" aria-label="Close email capture modal" data-mr-ass="close-email-capture-modal")
            span.icon-x

        .capture-modal-content
          slot(name="content")
</template>

<script>
  export default {
    name: "CaptureModal",

    props: {
      modalShown: {
        type: Boolean,
        required: true
      },

      closeModalMethod: {
        type: Function,
        required: true
      }
    },
  };
</script>

<style scoped lang="stylus">
  .scroll-bottom-enter-active,
  .scroll-bottom-leave-active
    transition transform .4s ease-out

  .scroll-bottom-enter,
  .scroll-bottom-leave-to
    transform(translateY(100%))

  .capture-modal
    position fixed
    max-width 100%
    z-index 49
    background-color ui-color-3
    border 1px solid color-fog-light
    border-top-right-radius 1em
    border-top-left-radius 1em
    max-height 80vh
    width 30em
    overflow hidden
    padding 2em 2em 3.3em 2em
    bottom 0
    left 1em
    box-shadow 0 -2px 12px 0 rgba(0, 0, 0, 0.12)

    .capture-modal-wrapper
      .header
        .btn-close
          top -1.5em
          position absolute
          right -1.5em
          padding 0.5em
          color brand-color-2

  @media mq-mobile
    .capture-modal
      padding 2em 1em 3.3em 1em
      left 0
      width 100%
      max-width 100%

      .capture-modal-wrapper
        .header
          .btn-close
            right -0.5em
</style>
