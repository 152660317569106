<template lang="pug">
  .sticky-promo-drawer.xs-f-small.max-at-tweak(v-if="!hideStickyPromoDrawer || overrideHideStickyPromoDrawer")
    StickyWrap(stop-at="footer-bottom" :bottom-relative="true")
      .sticky-promo-drawer-inner.text-center(:class="{open: couponDrawerOpen}")
        .close-background(@click="togglePromoDrawer")
        .button-wrap
          button.drawer-toggle.btn-reset.upper.semi-bold.color-white.xs-f-small.max-at-tweak(type="button" @click="togglePromoDrawer")
            | exclusive offers&nbsp;
            span.icon-caret-down.color-white.xs-f-medium.max-at-tweak

        .promos-wrap
          p.promos-title.upper.bold.xs-f-small.max-at-tweak Promos, Offers + More...
          .coupons
            .coupon(v-for="partial in couponPartials" :key="partial.mixinKey" )
              CMSPartialTemplate(v-if="partial.html" :html="partial.html" :css="partial.css")

</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex';
  import StickyWrap from '@components/StickyWrap';
  import CMSPartialTemplate from "@components/CMSPartialTemplate";

  import trackEventMixin from '@mixins/trackEventMixin';

  export default {
    name: "StickyPromoDrawer",

    components: {
      StickyWrap,
      CMSPartialTemplate
    },

    mixins: [trackEventMixin],

    props: {
      hideStickyPromoDrawer: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapState('global', ['overrideHideStickyPromoDrawer']),
      ...mapState('coupons', ['couponPartials', 'couponDrawerOpen']),
      ...mapState('modal', ['visible']),
    },

    watch: {
      visible(val) {
        if (val) {
          this.drawerOpen = false;
        }
      }
    },

    mounted() {
      this.initCart();
    },

    methods: {
      ...mapActions('cart', [
        'initCart'
      ]),

      ...mapMutations('coupons', [
        'setCouponDrawerOpen'
      ]),

      togglePromoDrawer() {
        this.setCouponDrawerOpen(!this.couponDrawerOpen);
        if (this.couponDrawerOpen) {
          this.trackMREvent('Promo Drawer - Sticky Drawer Opened',);
        }
      },

      trackTileClick(key) {
        this.trackMREvent('Promo Drawer - Tile Clicked', {
          ctaSource: key
        });
      }
    },
  };
</script>

<style scoped lang="stylus">
.sticky-promo-drawer
  .sticky-promo-drawer-inner
    position fixed
    left 0
    right 0
    bottom 0
    z-index 10
    transition(opacity 400ms ease-in-out)

    .close-background
      display none

    .drawer-toggle
      position absolute
      top -3.5em
      left 50%
      margin-left -8em
      height 3.5em
      width 16em
      padding 1em 2em
      background-color color-black
      color color-white

      .icon-caret-down
        &:before
          transition(all 300ms ease-in-out)

    .promos-wrap
      position relative
      max-height 0
      overflow hidden
      transition(max-height 300ms ease-in-out)
      background-color color-white

      .promos-title
        padding-top 1.5em

      .coupons
        padding 1.5em 5%
        white-space nowrap
        overflow auto

        .coupon
          display inline-block
          vertical-align top
          width 15em
          margin-right 1em

          &:last-child
            margin-right 0

    &.open
      z-index 9999999999

      .close-background
        display block
        background-color color-black
        opacity 0.4
        position fixed
        top 0
        right 0
        bottom 0
        left 0

      .drawer-toggle
        .icon-caret-down
          &:before
            transform rotate(-180deg)


      .promos-wrap
        max-height 30em

  .stopped
    .sticky-promo-drawer-inner
      pointer-events none
      opacity 0

  @media mq-mobile
    .sticky-promo-drawer-inner
      .drawer-toggle
        width 100%
        left 0
        right 0
        margin-left 0

</style>