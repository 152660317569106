<script>
  export default {
    render(createElement) {
      return createElement("div", {
        staticClass: "mr-spinner"
      }, [createElement("mr-icon", {
        staticClass: "spinner",
        props: {
          name: "spinner-v2",
          original: true,
          fill: false
        }
      })]);
    }
  };
</script>

<style scoped lang="stylus">
  .mr-spinner
    position relative
    text-align center

    .spinner
      margin auto
      border none
      height 3em
      width 3em
      -webkit-animation spin 1s linear infinite
      -moz-animation spin 1s linear infinite
      animation spin 1s linear infinite
      animation-direction reverse

      &:before
        content icon-process
        font-family "fontello"
        font-size 2em
        line-height 1em
        width 1em
        height 1em
        position absolute
        top 0
        right 0
        bottom 0
        left 0

</style>
