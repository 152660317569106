<template lang="pug">
  .promo-tag-counter(aria-label="Open promos, offers + more")
    mr-icon(name="tag" :height="iconSize")
    .count-bubble(v-if="couponCount > 0" :class="size") {{ couponCount }}

</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PromoTagCounter",

  props: {
    size:{
      required: false,
      type: String
    }
  },

  computed: {
    ...mapGetters('coupons', [
      'couponCount'
    ]),

    iconSize() {
      return this.size == 'small' ? '1.5em' : '1.8em';
    }
  }
};
</script>

<style scoped lang="stylus">
  .promo-tag-counter
    position relative

    .count-bubble
      color white
      height 1.2em
      width 1.2em
      background-color text-color-1
      border-radius 50%
      position absolute
      left 0.6em
      top 0.8em
      line-height initial
      text-align center

      &.small
        height 1em
        width 1em
        line-height 1em
        font-size 0.9em

</style>