const state = {
  theme: 'default',
  returnFocusElement: null,
  visible: false,
  persistent: false, //- no background clicks
  modalComponent: null,
  stepBackActions: [], //- has to be namespaced e.g addressBook/editAddressModal,
  stepBackActionParams: [],
  modalProps: {},
  onClose: null,
  portalName: null,
  disableClose: false,
  mobileEffect: 'modal-body-fade',
  desktopEffect: 'modal-body-fade',
};

export const getters = {

};

export const actions = {
  executeStepBackAction({ commit, dispatch, state }) {
    let action = state.stepBackActions[state.stepBackActions.length - 1];
    let params = state.stepBackActionParams[state.stepBackActionParams.length - 1] || {};

    if (action) {
      dispatch(action, {...params}, { root: true });
      commit('popModalStepBackAction');
    }
  },

  hideModal({ state, commit, dispatch }) {
    return new Promise((resolve) => {
      //- hide current modal first
      commit('setVisible', false);
      commit('setPersistent', false);
      // timeout resets the theme after .2s to compinsate for css transition
      setTimeout(() => {
        commit('setTheme', 'default');
        commit('setMobileEffect', 'modal-body-fade');
        commit('setDesktopEffect', 'modal-body-fade');
        commit('setPortalName', null);
        commit('setDisableClose', false);
        commit('setModalProps', {});
        if (state.returnFocusElement) {
          state.returnFocusElement.focus();
          commit('setReturnFocus', null);
        }

        //- only then dispatch back action, it could be a modal that needs visible
        dispatch('executeStepBackAction');

        if (state.onClose) {
          state.onClose();
          commit('setOnClose', null);
        }
        resolve();
      }, 200);
    });
  },

  showModal({ commit }, params = {}) {
    commit('setVisible', true);
    const {
      desktopEffect,
      mobileEffect,
      theme,
      disableClose,
      props,
      returnFocusElement,
      persistent,
      component,
      onClose,
    } = params;

    if (onClose) {
      commit('setOnClose', onClose);
    }

    if (mobileEffect) {
      commit('setMobileEffect', mobileEffect);
    }

    if (desktopEffect) {
      commit('setDesktopEffect', desktopEffect);
    }

    if (theme) {
      commit('setTheme', theme);
    }

    if (disableClose) {
      commit('setDisableClose', disableClose);
    }

    if (props) {
      commit('setModalProps', props);
    }

    if (returnFocusElement) {
      commit('setReturnFocus', returnFocusElement);
    }

    if (persistent) {
      commit('setPersistent', persistent);
    }

    commit('setComponent', component);
  },

  showPortalModal({ commit, dispatch }, params) {
    commit('setPortalName', params.portalName);
    dispatch('showModal', {
      component: 'PortalModal',
      theme: params.theme,
    });
  },

  showRedirectModal({ dispatch }) {
    dispatch('showModal', {
      component: 'RedirectModal',
      theme: 'redirect-modal',
      disableClose: true
    });
  },

  showPrompt({ commit, dispatch }, params) {
    commit('setModalProps', params);
    dispatch('showModal', params);
  }
};

export const mutations = {
  setOnClose(state, val) {
    state.onClose = val;
  },

  setDesktopEffect(state, val) {
    state.desktopEffect = val;
  },

  setMobileEffect(state, val) {
    state.mobileEffect = val;
  },

  setComponent(state, val) {
    state.modalComponent = val;
  },

  setReturnFocus(state, val) {
    state.returnFocusElement = val;
  },

  setVisible(state, val) {
    state.visible = val;
  },

  setTheme(state, val) {
    state.theme = val;
  },

  setDisableClose(state, val) {
    state.disableClose = Boolean(val);
  },

  pushModalStepBackAction(state, payload) {
    state.stepBackActions.push(payload.action);
    state.stepBackActionParams.push(payload.params || {});
  },

  popModalStepBackAction(state) {
    state.stepBackActions.pop();
    state.stepBackActionParams.pop();
  },

  clearStepBackActions(state) {
    state.stepBackActions = [];
    state.stepBackActionParams = [];
  },

  setModalProps(state, val) {
    state.modalProps = val || {};
  },

  setPersistent(state, val) {
    state.persistent = val || false;
  },

  setPortalName(state, val) {
    state.portalName = val;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
