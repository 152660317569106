<template lang="pug">
  .site-nav-item-wrap
    template(v-if="item.url")
      a.site-nav-title.upper.semi-wide-letter-spacing.xs-f-small.md-f-medium.lg-f-xsmall.max-at-tweak(:href="item.url" target="_self" @click="selectNavItem" :class="{ 'selected': selected }" role="menuitem" :aria-label="ariaLabel")
        span.site-nav-title-content
          template(v-if="showIcon")
            span.site-nav-item-icon.xs-f-large.max-at-tweak.brand-color-1.xs-pr-50m
              mr-icon(:name="item.icon")

          span(:class="classesForTitleText") {{ item.title }}

    template(v-else)
      button.site-nav-title.upper.semi-wide-letter-spacing.xs-f-small.md-f-medium.lg-f-xsmall.max-at-tweak(@click="selectNavItem" :class="{ 'selected': selected }" role="menuitem" aria-haspopup="true" :aria-expanded="selected" :aria-label="ariaLabel")
        span.site-nav-title-content(tabindex="-1")
          template(v-if="showIcon")
            span.site-nav-item-icon.xs-f-large.max-at-tweak.brand-color-1.xs-pr-50m
              mr-icon(:name="item.icon")

          span {{ item.title }}

          i.icon-caret-down(:class="{'rotate': selected }")
            span.ada-tooltip nav {{ status }}

      transition(name="fade-block")
        .site-nav-desktop-drawer(v-show='selected' :class="{ 'shop-drawer': isShopMenu }")
          template(v-if="isShopMenu")
            SiteNavDesktopShopMenu

          template(v-else)
            SiteNavDesktopSubMenu.pull-left(v-for="menu in subNav" :subNavData="menu" :key="menu.title")

</template>

<script>
import SiteNavDesktopSubMenu from '../SiteNavDesktopSubMenu';
import SiteNavDesktopShopMenu from '../SiteNavDesktopShopMenu';

import constants from '@components/SiteNav/constants';
import { mapMutations, mapState } from "vuex";
import SiteNavTrackMixin from '@components/SiteNav/SiteNavTrackMixin';
import menuMixin from "@mixins/menuMixin";

export default {
  name: "SiteNavDesktopTopItem",

  components: {
    SiteNavDesktopSubMenu,
    SiteNavDesktopShopMenu
  },

  mixins: [SiteNavTrackMixin, menuMixin],

  inject: ['getObjProperty'],

  props: {
    item: {
      type: Object,
      required: true
    }
  },

computed: {
    ...mapState('siteNav', [
      'selectedSiteDesktopNav'
    ]),

    ...mapState('global', [
      'windowData',
    ]),

    ariaLabel() {
      return this.item.ariaLabel || this.item.title;
    },

    isShopMenu() {
      return this.item.subNav == 'SHOP_NAV';
    },

    selected() {
      return this.item.id == this.selectedSiteDesktopNav;
    },

    status() {
      return this.selected ? 'open' : 'closed';
    },

    showIcon() {
      return Boolean(this.item.icon) && !['desktop', ''].includes(this.windowData.breakpoint);
    },

    drawerIsOpen() {
      return Boolean(this.selectedSiteDesktopNav);
    },

    currentDesktopNavData() {
      let nav = constants.TOP_NAV.navItems.find(nav => nav.id == this.item.id) || {};
      return constants[nav.subNav];
    },

    subNav() {
      let subNavSections = [];
      let subCategories = this.getObjProperty(this.currentDesktopNavData, 'subCategories');

      if (subCategories && subCategories.length) {
        subNavSections = this.mix_copyOnlyElementsToShow(this.currentDesktopNavData.subCategories);
      } else if (this.currentDesktopNavData) {
        subNavSections.push(this.mix_copyOnlyElementsToShow(this.currentDesktopNavData));
      }

      return subNavSections;
    },

    classesForTitleText() {
      let classObject = {};

      classObject.bold = this.item.desktop && this.item.desktop.showAsTextCta;
      classObject['cta-color-2'] = this.item.desktop && this.item.desktop.showAsTextCta;

      return classObject;
    }
  },

  methods: {
    ...mapMutations('siteNav', [
      'setSelectedSiteDesktopNav',
    ]),

    selectNavItem() {
      if (this.item.subNav) {
        this.mix_trackNav(this.item.subNav);
      } else if (this.item.url) {
        this.mix_trackItemClick(this.item.customEventNameTitle || this.item.title);
      }

      this.setSelectedSiteDesktopNav(this.item.id);
    },
  }
};
</script>

<style scoped lang="stylus">
.site-nav-item-wrap
  .site-nav-title
    background-color transparent
    border none
    position relative
    padding 0

    &:focus
      outline -webkit-focus-ring-color auto 5px

    .site-nav-title-content
      display block

      &:focus
        outline none

    &:before
    &:after
      content ''
      position absolute
      bottom -2px
      height 3px
      left 1.25em
      width calc(99% - 2.5em)
      z-index 101
      background-color transparent
      transition(background 0.35s ease-in-out)

    &.selected
      &:after
        background-color brand-color-2

  a
    display block
    color text-color-1

  a
  button
    background-color transparent
    border none
    padding 0

    .site-nav-title-content
      padding 0.5em 1.20em

      .icon-caret-down
        cursor pointer

        &:before
          transition transform 350ms ease

        &.rotate
          &:before
            transform rotate(-180deg)

  .site-nav-desktop-drawer
    position absolute
    background white
    width 100%
    left 0
    margin-top 4px
    padding 0em 5em
    box-shadow 0 2px 4px 0 rgba(0, 0, 0, 0.1)
    border-top 1px solid ui-color-4

    &.shop-drawer
      padding 1.5em 3em 0.5em

    &.open
      opacity 1
      display block

    .site-nav-desktop-sub-menu
      width 17em
      display inline-block

  @media screen and (min-width: 1080px)
    .site-nav-desktop-drawer
      padding 0 8%

  @media screen and (min-width: 1300px)
    .site-nav-desktop-drawer
      padding 0 10.3em

</style>