<template lang='pug'>
  .sticky-wrap(v-bind:class="{fixed, stopped, permanent}")
    slot
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'StickyWrap',
    props: {
      bottomRelative: Boolean,
      stopAt: String,
      permanent: Boolean
    },

    data() {
      return {
        fixed: false,
        stopped: false,
        interval: null
      };
    },
    computed: {
      ...mapState('global', ['windowData']),
    },

    mounted() {
      var self = this;
      self.update();
      self.interval = window.setInterval(function() {
        self.update();
      }, 200);
    },

    beforeDestroy() {
      window.clearInterval(this.interval);
    },

    methods: {
      update() {
        var elementTop = this.$el.getBoundingClientRect().top;
        var shouldBeFixed = this.fixed;
        var shouldBeStopped = this.stopped;

        if (this.top != elementTop) {
          var elementBottom = elementTop + this.$el.clientHeight;
          var windowHeight = this.windowData.height;

          shouldBeFixed = this.permanent || elementBottom <= 0 || (this.bottomRelative && (elementBottom - windowHeight) <= 0);

          if (this.stopAt) {
            var stopAtElement = document.getElementById(this.stopAt);
            if (stopAtElement) {
              var stopAtTop = stopAtElement.getBoundingClientRect().top;
              shouldBeStopped = stopAtTop - windowHeight <= 0;
            }
          }

          if (shouldBeFixed != this.fixed) {
            this.fixed = shouldBeFixed;
            this.$emit(this.fixed ? 'fixed' : 'unfixed');
          }

          if (!this.permanent && shouldBeStopped != this.stopped) {
            this.stopped = shouldBeStopped;
            this.$emit(this.stopped ? 'stopped' : 'unstopped');
          }
        }
      }
    },
  };
</script>
<style lang="stylus" scoped>
  .sticky-wrap
    &.permanent
      height 0px
      min-height 0px !important
</style>