import Vue from 'vue';
import Router from 'vue-router';

//- ROUTES
import shopProductRoutes from '@components/Shop/routes';

Vue.use(Router);

export function createRouter() {
  return new Router({
    mode: 'history',
    routes: [
      ...shopProductRoutes,
    ],
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else if (to.hash) {
        return {
          selector: to.hash,
          offset: {
            x: 0,
            y: 10,
          },
        };
      } else if (to.meta.noScroll) {
        return false;
      } else {
        return {
          x: 0,
          y: 0,
        };
      }
    },
  });
}