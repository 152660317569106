import { mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations('global', [
      'setBrowserContext'
    ]),

    mix_hydrateBrowserBasedSettings() {
      if (window.browserContext) {
        this.setBrowserContext(window.browserContext);
      }
    },
  }, 
  mounted() {
    this.mix_hydrateBrowserBasedSettings();
  }
};