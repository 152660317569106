export const EMAIL_CAPTURE_CAMPAIGN = 'email_capture_modal';

export const EMAIL_CAPTURE_COOKIE = 'no_email_capture_modal';

export const EMAIL_SOURCE = 'popup_email_capture';

export const EMAIL_SOURCE_HCB = 'HCB_email_capture';

export const PHONE_CAPTURE_COOKIE = 'no_phone_capture_modal';

export const EXPERIMENT_CONFIGS = {};
