import commonChatWidgetMixin from "./commonChatWidgetMixin";
import { mapState } from 'vuex';

export default {
  mixins: [commonChatWidgetMixin],

  mounted() {
    this.mix_addChatWidgetLoadScript();
  },

  methods: {
    /**
     * Configures scripts for loading the widget.
     *
     * @param {object} script
     * @returns script
     */
    mix_configureChatWidgetLoadScript(script) {
      script.text = `!function(c,n,r,t){if(!c[r]){var i,d,p=[];d="PROD"!==t&&t?"STAGING"===t?"https://cdn.gladly.qa/gladly/chat-sdk/widget.js":t:"https://cdn.gladly.com/chat-sdk/widget.js",c[r]={init:function(){i=arguments;var e={then:function(t){return p.push({type:"t",next:t}),e},catch:function(t){return p.push({type:"c",next:t}),e}};return e}},c.__onHelpAppHostReady__=function(t){if(delete c.__onHelpAppHostReady__,(c[r]=t).loaderCdn=d,i)for(var e=t.init.apply(t,i),n=0;n<p.length;n++){var a=p[n];e="t"===a.type?e.then(a.next):e.catch(a.next)}},function(){try{var t=n.getElementsByTagName("script")[0],e=n.createElement("script");e.async=!0,e.src=d+"?q="+(new Date).getTime(),t.parentNode.insertBefore(e,t)}catch(t){}}()}}(window,document,'Gladly','${this.MRConfig.gladlyWidgetConfigs.scriptEnv}')`;
      return script;
    },

    /**
     * Once the script is added, it waits until the support script has been initialized.
     */
    mix_afterLoadScriptAdded() {
      let attempts = 0;

      let timeoutForLoading = setInterval(() => {

        if (this.mix_isMainScriptLoaded()) {
          clearInterval(timeoutForLoading);
          this.mix_onScriptLoaded();
        } else {
          attempts++;
          if (attempts === 80) {
            clearInterval(timeoutForLoading);
          }
        }
      },
      200);
    },

    /**
     * Once the script has been initialized, it configures and instantiate the widget.
     */
    mix_onScriptLoaded() {
      let Gladly = window.Gladly;

      if (!this.mix_isMainScriptLoaded()) {
        return;
      }

      Gladly.init({
        autoShowButton: true,
        appId: this.currentGladlyConfig.appId,
      }).then(function() {
        // This is called once Sidekick has been initialized.

        // An element with the id "start-chat" is assumed to be on the page.
        let startChatButton = document.getElementById('start-chat');

        // An onClick handler can be attached to the "start-chat" element
        startChatButton.onclick = function() {Gladly.show();};

        // This API call gets the current availability
        let availablility = Gladly.getAvailability();

        // Setting the availability as the class name on the "start-chat" element,
        // this allows us to change the look of the button via CSS.
        startChatButton.setAttribute('class', availablility.toLowerCase());

        // Subscribe to changes in availability, and update the class on the "start-chat" element.
        Gladly.on('availability:change', function(availablility) {
          startChatButton.setAttribute('class', availablility.toLowerCase());
        });

      }).catch(function() {
        // If anything goes wrong when Sidekick is being initialized, this gets called.
      });
    },

    /**
     * This is a hook for when the router informs that there was a navigation in the router.
     * @returns
     */
    mix_onNavigation() {
      if (!this.mix_isMainScriptLoaded() || !window.Gladly || !window.Gladly.navigate) {
        return;
      }

      window.Gladly.navigate();
    },

    /**
     * Checks if the the main script was already loaded
     * @returns {boolean} status
     */
    mix_isMainScriptLoaded() {
      return !!window.Gladly;
    },

    /**
     * Function that do opens chat manually.
     *
     * @param {string} eventName
     */
     mix_doOpenChat(eventName) {
      this.mix_trackSegmentEvent(eventName);

      window.Gladly.show();
    }
  },

  computed: {
    ...mapState('global', ['MRConfig']),

    /**
     * Using enviroment gladly config, it searches which config adjust to the url with a given pattern, and returns the specific widget config for it.
     *
     * @returns gladlyConfig
     */
    currentGladlyConfig() {
      let configs = this.MRConfig.gladlyWidgetConfigs.configs || [];

      return configs.find(config => {
        let regex = new RegExp(config.pattern, "g");
        return  regex.test(this.$route.fullPath);
      });
    },
  },

  /**
   * Before destroy life-cycle hook
   */
   beforeDestroy() {
    window.removeEventListener('load', this.mix_addChatWidgetLoadScript);
  }
};