/* global MR */
import sentry from '../../addSentry';
import {getObjProperty} from "@utilities/mrVueUtils";

const state = {
  notifications: [],
  notificationId: 0,
  skipNotifySuccess: false
};

export const getters = {};

export const actions = {
  notifySuccess({ dispatch }, msg) {
    let payload = {
      class: 'success',
    };

    if (typeof msg === 'string') {
      payload.message = msg;
    }

    if (typeof msg === 'object') {
      payload = {
        ...payload,
        ...msg,
      };
    }

    if (!payload.message) {
      payload.message = `Success!`;
    }

    return dispatch('notify', payload);
  },

  notifyError({ dispatch }, msg) {
    let err;
    let silent;

    let payload = {
      class: 'error',
      message: `Something went wrong and your action could not be completed. Please review your information or call us at ${MR.config.contactPhone}`,
    };

    if (msg !== null) {
      if (typeof msg == 'string') {
        payload.message = msg;
      } else if (typeof msg == 'object' && Object.keys(msg).length) {
        let message = getObjProperty(msg, 'response.data.message');

        if (message) {
          payload.message = message;
        } else {
          err = msg.err;
          silent = msg.silent;
          payload.message = msg.msg;
        }
      }
    }

    if (err) {
      //- send it to Sentry
      sentry.captureException(err, {
        tags: {
          location: "notifyError",
        }
      });
      //- log the errors in console if not in production
      if (process.env.NODE_ENV != 'production') {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
    //- do not show anything to the customer
    if (silent) {
      return;
    }

    return dispatch('notify', payload);
  },

  /**
   * THIS MESSAGE TYPE IS DEPRECATED
   */
  notifyInfo({ dispatch }, msg) {
    let payload = {
      class: 'info',
    };

    if (typeof msg === 'string') {
      payload.message = msg;
    }

    if (typeof msg === 'object') {
      payload = {
        ...payload,
        ...msg,
      };
    }

    if (!payload.message) {
      return;
    }

    return dispatch('notify', payload);
  },

  notifyWarn({ dispatch }, msg) {
    let payload = {
      class: 'warn',
    };

    if (typeof msg === 'string') {
      payload.message = msg;
    }

    if (typeof msg === 'object') {
      payload = {
        ...payload,
        ...msg,
      };
    }

    if (!payload.message) {
      return;
    }

    return dispatch('notify', payload);
  },

  notify({ commit, state }, notification) {
    if (typeof notification != 'object') {
      // eslint-disable-next-line no-console
      console.error(`"notification" must be an object, but got ${typeof notification}`);
      return;
    }

    if (state.skipNotifySuccess && notification.class === 'success') {
      commit('setSkipNotifySuccess', false);
      return;
    }

    var duplicateMessage = false;

    state.notifications.forEach(notif => {
      if (notif.message === notification.message) {
        duplicateMessage = true;
      }
    });

    if (duplicateMessage) {
      return;
    }

    notification.id = state.notificationId;

    commit('addNotification', notification);
    commit('increaseId');
    setTimeout(() => commit('popNotification'), notification.time);
    return notification.id;
  },

  removeNotification({ commit, state }, id) {
    var noteIdx = 0;
    for (noteIdx = 0; noteIdx < state.notifications.length; noteIdx++) {
      if (state.notifications[noteIdx].id == id) {
        break;
      }
    }

    if (noteIdx < state.notifications.length) {
      commit('removeByIndex', noteIdx);
    }
  },

  cleanAllNotifications({ commit }, timeout = 0) {
    setTimeout(() => {
      commit('cleanAllNotifications');
    }, timeout);
  },
};

export const mutations = {
  cleanAllNotifications(state) {
    state.notifications = [];
    state.notificationId = 0;
  },

  addNotification: (state, notification) => {
    if (!notification.time) {
      notification.time = 6000;
    }

    if (!notification.class) {
      notification.class = 'warn';
    }

    state.notifications.unshift(notification);
  },

  popNotification: state => state.notifications.pop(),

  increaseId: state => state.notificationId++,

  removeByIndex: (state, idx) => {
    state.notifications.splice(idx, 1);
  },

  setSkipNotifySuccess(state, val) {
    state.skipNotifySuccess = val;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
