import { getPromoDrawerPartials } from "@services/vueCmsSvc";

export const state = {
  couponPartials: [],
  couponsLoaded: false,
  couponDrawerOpen: false
};

export const getters = {
  couponCount(state) {
    return state.couponPartials.length;
  }
};

export const actions = {
  openCouponDrawer({ dispatch }) {
    dispatch('modal/showModal', {
      component: 'PromoDrawerModal',
      theme: 'mobile-drawer right-modal right-modal-small',
    }, { root: true });
  },

  initCoupons({ state, dispatch }) {
    if (state.couponsLoaded) {
      return;
    }

    dispatch('loadCoupons');
  },

  loadCoupons({ commit, dispatch }) {
    getPromoDrawerPartials().then(res => {
      commit('setCouponPartials', res.data);
      commit('setCouponsLoaded', true);
    }).catch(err => {
      dispatch('notifyError', { silent: true, err }, { root: true });
    });
  }
};

export const mutations = {
  setCouponsLoaded(state, val) {
    state.couponsLoaded = Boolean(val);
  },

  setCouponDrawerOpen(state, val) {
    state.couponDrawerOpen = val;
  },

  setCouponPartials(state, val) {
    state.couponPartials = val;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};