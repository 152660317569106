
<template lang="pug">
  .mr-input(:class="{ 'raise-label': raiseLabel }")
    textarea(v-if="inputType == 'textarea'"
      v-bind="combinedAttrs"
      v-on="combinedListeners"
      ref="input"
    )

    input(v-else
      v-bind="combinedAttrs"
      v-on="combinedListeners"
      ref="input"
    )

    label(:for="inputId") {{ label }}

    TransitionExpand
      .input-errors(v-if="inputErrors && inputErrors.length")
        .input-error.xs-f-xsmall.lg-f-xxsmall.max-at-tweak.text-left(role="alert" v-for="error in inputErrors")
          i.icon-warning
          | {{ error }}

</template>

<script>
  import TransitionExpand from '../TransitionExpand';

  export default {
    name: "MrInput",

    components: {
      TransitionExpand
    },

    props: {
      name: {
        type: String,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        required: false
      },
      inputType: {
        type: String,
        default: "text"
      },
      autocomplete: {
        type: String,
        required: false
      },
      focusOnMount: {
        type: Boolean,
        default: false
      },
      dataMrAss: {
        type: String
      },
      v: {
        type: Object,
        default: () => ({})
      },
    },

    data() {
      return {
        isFocused: false,
        validateTimeout: 0,
        inputId: 'mrInput_' + this._uid,
      };
    },

    computed: {
      raiseLabel() {
        if (this.isFocused) {
          return true;
        }
        if (this.$attrs.value) {
          return true;
        }
        return false;
      },
      combinedAttrs() {
        return {
          ...this.$attrs,
          type: this.inputType,
          id: this.inputId,
          'data-mr-ass': this.dataMrAss,
          'class': { 'invalid': this.v.$error },
          autocomplete: this.autocomplete,
          disabled: this.disabled,
          name: this.name,
        };
      },
      combinedListeners() {
        return {
          ...this.$listeners,
          focus: event => this.onFocus(event),
          blur: event => this.onBlur(event),
          input: event => this.update(event.target.value),
        };
      },
      text: {
        get(){
          if (!this.$attrs.value) {
            return undefined;
          }
          return this.$attrs.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      },

      inputError: function() {
        var error = null;
        if (!this.v.$params) {
          return error;
        }

        if (this.v.$params.minLength && !this.v.minLength && this.v.$error) {
          error = `Must be at least ${this.v.$params.minLength.min} characters.`;
        } else if (this.v.$params.maxLength && !this.v.maxLength && this.v.$error) {
          error = `Must be less than ${this.v.$params.maxLength.max + 1} characters.`;
        } else if ((this.v.$params.email && !this.v.email) || (this.v.$params.TLDValidator && !this.v.TLDValidator) && this.v.$error) {
          error = `Must be a valid email.`;
        } else if (Object.hasOwnProperty.call(this.v.$params, 'mrPhoneValidator') && !this.v.mrPhoneValidator && this.v.$error) {
          error = `Please enter a valid 10-digit phone number.`;
        } else if (Object.hasOwnProperty.call(this.v.$params, 'passwordSameAs') && !this.v.passwordSameAs) {
          error = `Passwords do not match`;
        } else if (Object.hasOwnProperty.call(this.v.$params, 'mrCardValidator') && !this.v.mrCardValidator && this.v.$error) {
          error = `Please enter valid credit card`;
        } else if (Object.hasOwnProperty.call(this.v.$params, 'mrCardExpirationMMYYValidator') && !this.v.mrCardExpirationMMYYValidator && this.v.$error) {
          error = `Please enter a valid expiration date`;
        } else if (Object.hasOwnProperty.call(this.v.$params, 'strongPassword') && !this.v.strongPassword && this.v.$error) {
          error = `Your password must contain a mix of upper and lower case letters, numbers, and special characters.`;
        } else if (this.v.$params.required && this.v.$error) {
          error = `This field is required.`;
        }

        return error;
      },

      inputErrors() {
        let errors = [];

        if (!this.v.$params) {
          return errors;
        }

        if (this.v.$params.minLength && !this.v.minLength && this.v.$error) {
          errors.push(`Must be at least ${this.v.$params.minLength.min} characters.`);
        }
        if (this.v.$params.maxLength && !this.v.maxLength && this.v.$error) {
          errors.push(`Must be less than ${this.v.$params.maxLength.max + 1} characters.`);
        }
        if ((this.v.$params.email && !this.v.email) || (this.v.$params.TLDValidator && !this.v.TLDValidator) && this.v.$error) {
          errors.push(`Must be a valid email.`);
        }
        if (Object.hasOwnProperty.call(this.v.$params, 'mrPhoneValidator') && !this.v.mrPhoneValidator && this.v.$error) {
          errors.push(`Please enter a valid 10-digit phone number.`);
        }
        if (Object.hasOwnProperty.call(this.v.$params, 'passwordSameAs') && !this.v.passwordSameAs) {
          errors.push(`Passwords do not match`);
        }
        if (Object.hasOwnProperty.call(this.v.$params, 'mrCardValidator') && !this.v.mrCardValidator && this.v.$error) {
          errors.push(`Please enter valid credit card`);
        }
        if (Object.hasOwnProperty.call(this.v.$params, 'mrCardExpirationMMYYValidator') && !this.v.mrCardExpirationMMYYValidator && this.v.$error) {
          errors.push(`Please enter a valid expiration date`);
        }
        if (Object.hasOwnProperty.call(this.v.$params, 'containsUpperAndLowerCaseCharacters') && !this.v.containsUpperAndLowerCaseCharacters && this.v.$error) {
          errors.push(`Must contain a mix of upper and lower case letters.`);
        }
        if (Object.hasOwnProperty.call(this.v.$params, 'containsNumber') && !this.v.containsNumber && this.v.$error) {
          errors.push(`Must contain a number.`);
        }
        if (Object.hasOwnProperty.call(this.v.$params, 'containsSymbol') && !this.v.containsSymbol && this.v.$error) {
          errors.push(`Must contain a special character (!@#$%^&*()?][{}+_\\-=\`~).`);
        }
        if (this.v.$params.required && this.v.$error && !errors.length) {
          errors.push(`This field is required.`);
        }

        return errors;
      }
    },

    mounted() {
      if (this.focusOnMount) {
        this.$refs.input.focus();
      }
    },

    methods: {
      onBlur (e) {
        this.isFocused = false;
        if (e) {
          this.$nextTick(() => this.$emit('blur', e));
        }
      },
      onFocus (e) {
        if (!this.$refs.input) {
          return;
        }

        if (!this.isFocused) {
          this.isFocused = true;
          e && this.$emit('focus', e);
        }
      },
      update(value) {
        this.delayTouch(this.v);
        this.$emit('input', value);
      },

      delayTouch($v) {
        if (!$v.$params) {
          return;
        }

        $v.$reset();
        clearTimeout(this.validateTimeout);
        this.validateTimeout = setTimeout(() => {
          $v.$touch();
        }, 800);
      }

    }
  };
</script>

<style scoped lang="stylus">
  .mr-input
    position relative
    display block
    margin 0.55em 0
    font-size 16px

    label
      position absolute
      top 12px
      left 1.1em
      z-index 1
      cursor text
      pointer-events none
      color #999
      font-size 14px
      line-height 1.3em

    input
    textarea
      border-radius 4px
      transition(border-color 0.6s)

      &.invalid
        border 2px solid color-attention

      &:focus
        border 2px solid brand-color-2

    .customDropdown
      .option.expanded
        z-index 2

    &.raise-label
      label
        top 5px
        font-size 10px
        background-color color-white

      input
      textarea
      .select
        padding-top 15px
        padding-bottom 5px

  .input-error
    color color-attention
</style>
