<template lang="pug">
  .add-promo-btn
    slot(:promo-code="promoCode" :apply-promo="addPromoTotCart" :applying="applying" :promo-is-applied="promoIsApplied" :err-message="errMessage")

</template>

<script>
  import { mapState, mapActions } from 'vuex';

  export default {
    name: 'AddPromo',

    props: {
      promoCode: {
        type: String,
        required: true
      }
    },

    inject: ['getObjProperty'],

    data() {
      return {
        applying: false,
        errMessage: null,
      };
    },

    computed: {
      ...mapState('cart', ['cart']),
      promoIsApplied() {
        const cartPromos = this.getObjProperty(this.cart, 'cartPromos') || [];
        const promoCode = (this.promoCode || '').toUpperCase();
        return cartPromos.some(promo => promo.code === promoCode);
      },
    },

    methods: {
      ...mapActions('cart', [
        'applyCoupon'
      ]),

      clearErrMessage() {
        setTimeout(() => {
          this.errMessage = null;
        }, 7000);
      },

      addPromoTotCart() {
        this.applying = true;
        this.applyCoupon({ code: this.promoCode }).then(() => {
          this.applying = false;
        }).catch(err => {
          this.applying = false;
          this.errMessage = this.getObjProperty(err, 'message') || 'Something went wrong';
          this.clearErrMessage();
        });
      }
    }
  };
</script>
