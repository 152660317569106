import mrApi from './mrApi';

export default {
  getDoorDashEligible,
  getAvailableDoorDashDeliveryTimes
};

export function getDoorDashEligible(params) {
  return mrApi.get('/api/doordash/getDoorDashEligible', { params });
}

export function getAvailableDoorDashDeliveryTimes(params) {
  return mrApi.get('/api/doordash/getAvailableDoorDashDeliveryTimes', { params });
}