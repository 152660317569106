/* global cdata */
import * as sentry from '@sentry/browser';

if (process.env.SENTRY_DSN) {
  // sentry JS Error Tracking
  sentry.init({
    release: `${process.env.SENTRY_RELEASE}`,
    dsn: `${process.env.SENTRY_DSN}`,
    environment: process.env.NODE_ENV,
    integrations: [
      sentry.browserTracingIntegration(),
      sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: true,
        useCompression: true,
        blockAllMedia: false
      }),
      sentry.thirdPartyErrorFilterIntegration({
        // Specify the application keys that you specified in the Sentry bundler plugin (webpack)
        filterKeys: ["mr-website"],

        // Defines how to handle errors that contain third party stack frames.
        // Possible values are:
        // - 'drop-error-if-contains-third-party-frames'
        // - 'drop-error-if-exclusively-contains-third-party-frames'
        // - 'apply-tag-if-contains-third-party-frames'
        // - 'apply-tag-if-exclusively-contains-third-party-frames'
        behaviour: "drop-error-if-contains-third-party-frames",
      }),
    ],
    sampleRate: 1.0,
    tracesSampleRate: 0.04,
    replaysOnErrorSampleRate: 0.20,
    ignoreErrors:[
      "Non-Error exception captured",
      "Non-Error promise rejection captured",
      "e.checkPerfReady"
    ]
  });

  // Set user data with cdata
  try {
    const scope = sentry.getCurrentScope();
    scope.setTag("framework", "vue");
    scope.setTag("initFile", "addSentry.js");

    if (typeof cdata === 'undefined') {
      sentry.captureException(new Error ('cdata not defined'));
    } else {
      scope.setUser({
        'email': cdata.email ? cdata.email : null,
        'id' : cdata.id ? cdata.id : cdata.dug,
        'visitorType': cdata.visitorType ? cdata.visitorType : null,
      });
    }

  } catch (err) {
    sentry.captureException(err);
  }
}

export default sentry;
