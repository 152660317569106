import mrApi from './mrApi';

export default {
    trackSegmentEvent,
    trackSegmentPage
};

export function trackSegmentEvent(params) {
    return mrApi.post('/api/customer/trackSegmentEvent',  params);
}

export function trackSegmentPage(params) {
    return mrApi.post('/api/customer/trackSegmentPage',  params);
}