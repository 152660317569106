// CONSTANTS
var constants = {
  orderType: {
    ONE_DASH_TIME: 'one-time',
    SUBSCRIPTION: 'subscription',
    RENEWAL: 'renewal',
    SERVICE: 'service',
  },
  order_status: {
    PENDING: 'pending',
    RESHIPPED: 'reshipped',
    PROCESSING: 'processing',
    DECLINED: 'declined',
    CANCELED: 'canceled',
    SHIPPED: 'shipped',
    RETURNED: 'returned',
    COMPLETED: 'completed',
    SCHEDULED: 'scheduled',
    BACKORDERED: 'backordered',
    DELIVERED: 'delivered',
    MODIFIED: 'modified', // Not actually written to the order_status column, just used for history
    UPGRADED: 'subscriptionUpgraded', // Not actually written to the order_status column, just used for history
    READY_FOR_PICKUP: 'ready_for_pickup'
  },
  payment_status: {
    PENDING: 'pending',
    AUTHED: 'authed',
    DECLINED: 'declined',
    VOID: 'void',
    AUTH_CAPTURED: 'auth_captured',
    CAPTURED: 'captured',
    COLLECTION: 'collection',
    UNPAID: 'unpaid',
    PAID: 'paid'
  },
  shipping_status: {
    PENDING: 'pending',
    NO_SHIPPING_REQUIRED: 'no_shipping_required',
    CANCELED: 'canceled',
    PROCESSING: 'processing',
    SHIPPED: 'shipped',
    RESHIPPED: 'reshipped',
    RESHIPPING: 'reshipping',
    RETURNED: 'returned',
    EXCEPTION: 'exception',
    FAILED: 'failed',
    BACKORDERED: 'backordered',
    DELIVERED: 'delivered',
    BOPIS_PENDING_FULFILLMENT: 'bopis_pending_fulfillment', //Order has been placed and is in the hold window before being sent to the HCB for fulfillment
    BOPIS_NEW: 'bopis_new', //Orders that have been passed to rec app waiting to be fulfilled
    BOPIS_READY: 'bopis_ready', //Orders that have been picked and packed by HCB and are ready for customer
    BOPIS_COMPLETED: 'bopis_completed', //Orders that have been picked up and customer has walked out the door
    BOPIS_PENDING_RESTOCK: 'bopis_pending_restock', //Orders that need to be restocked at the HCB due to an order being cancelled or not picked up
    BOPIS_CANCELED: 'bopis_canceled' //Orders that have either been canceled by the customer, internal HCB/CC employee, or because a customer did not pick up and the order expired
  },
  refund_status: {
    PARTIAL: 'partial',
    FULL: 'full'
  },
  statusTypes: {
    ORDER: 'order_status',
    SHIPPING: 'shipping_status',
    PAYMENT: 'payment_status',
    APPOINTMENT: 'appointment_status',
    NEEDS_REVIEW: 'needs_review',
    RESHIP: 'reship',
    REFUND: 'refund_status',
    DELETED: 'deleted',
    TEST_ORDER: 'test_order',
    NOTE: 'note',
    APPEASEMENT: 'appeasement',
  },
  skip_payment: {
    CASH: 'cash',
    NO_PAYMENT: 'no_payment'
  },
  channel: {
    DIRECT: 'MR-DIRECT',
    RESALE: 'MR-WHOLESALE-SALON',
    COLORBAR: 'MR-COLORBAR'
  },
  DELETED: 'deleted_status',
  needsReviewStatus: {
    NO_NEEDED: 0,
    NEW: 1,
    INITIAL_OUTREACH: 2,
    FINAL_OUTREACH: 3,
  },
  BOPIS_PICKUP_WINDOW_DAYS: 5,
  BOPIS_VALID_DAYS: 9,
  stock: {
    ALL_IN_STOCK: 'allInStock',
    SOME_OUT_OF_STOCK: 'someOutOfStock',
    ALL_OUT_OF_STOCK: 'allOutOfStock',
  },
  BOPIS_CANCEL_REASON: {
    EXPIRED: 'expired',
    OUT_OF_STOCK: 'out_of_stock',
    AGENT_CANCEL: 'agent_cancel',
    CUSTOMER_CANCEL: 'customer_cancel',
    ORPHAN_CANCEL: 'orphan_cancel',
  },
  DOORDASH_CANCEL_REASON: {
    UNFULFILLED: 'doorDash_unfulfilled',
    VENDOR_CANCEL: 'doorDash_vendorCancel',
  }
};

constants.UNPAID_PAYMENT_STATUS = [
  constants.payment_status.DECLINED,
  constants.payment_status.PENDING,
];
constants.INVALID_SHIPPING_STATUS = [
  constants.shipping_status.CANCELED,
];
constants.NOT_CANCELABLE_SHIPPING_STATUS = [
  constants.shipping_status.CANCELED,
  constants.shipping_status.SHIPPED,
  constants.shipping_status.BOPIS_CANCELED,
  constants.shipping_status.BOPIS_PENDING_RESTOCK,
  constants.shipping_status.BOPIS_NEW,
];
constants.VALID_SHIPPING_STATUS = [
  constants.shipping_status.PENDING,
  constants.shipping_status.PROCESSING,
  constants.shipping_status.SHIPPED,
  constants.shipping_status.DELIVERED,
  constants.shipping_status.EXCEPTION,
];
constants.VALID_PAYMENT_STATUS = [
  constants.payment_status.AUTHED,
  constants.payment_status.AUTH_CAPTURED,
  constants.payment_status.CAPTURED,
  constants.payment_status.UNPAID,
  constants.payment_status.PAID,
];
constants.AUTHED_PAYMENT_STATUS = [
  constants.payment_status.AUTHED,
  constants.payment_status.AUTH_CAPTURED,
  constants.payment_status.UNPAID,
];
constants.CAPTURED_PAYMENT_STATUS = [
  constants.payment_status.CAPTURED,
  constants.payment_status.AUTH_CAPTURED,
  constants.payment_status.UNPAID,
  constants.payment_status.PAID,
];
constants.PROCESSED_SHIPPING_STATUS = [
  constants.shipping_status.SHIPPED,
  constants.shipping_status.BOPIS_COMPLETED,
];
constants.PENDING_SHIPPING_SHIPPING_STATUS = [
  constants.shipping_status.PENDING,
  constants.shipping_status.RESHIPPING,
];
constants.COMPLETED_ORDER_STATUS = [
  constants.order_status.COMPLETED,
  constants.order_status.DELIVERED,
];
constants.CAN_REVIEW_STATUS = [
  constants.order_status.COMPLETED,
  constants.order_status.DELIVERED,
  constants.order_status.SHIPPED,
];
constants.MODIFIABLE_SHIPPING_STATUS = [
  constants.shipping_status.PENDING,
  constants.shipping_status.FAILED,
];
constants.CUSTOMER_CAN_CANCEL = [
  constants.shipping_status.PENDING,
  constants.shipping_status.BOPIS_PENDING_FULFILLMENT,
];
constants.PROCESSING_SHIPPING_STATUS = [
  constants.shipping_status.PROCESSING,
  constants.shipping_status.FAILED,
  constants.shipping_status.BOPIS_READY,
];

constants.ACTIVE_BOPIS = [
  constants.shipping_status.BOPIS_PENDING_FULFILLMENT,
  constants.shipping_status.BOPIS_NEW,
  constants.shipping_status.BOPIS_READY,
  constants.shipping_status.BOPIS_PENDING_RESTOCK,
];

constants.LOYALTY_PROGRAM_SPEND_CHANNEL = [
  constants.channel.DIRECT,
  constants.channel.COLORBAR,
];

constants.NO_FAIL_SHIPPING_STATUS = [
  constants.shipping_status.SHIPPED,
  constants.shipping_status.RESHIPPED,
  constants.shipping_status.DELIVERED,
  constants.shipping_status.BOPIS_COMPLETED,
];

constants.PRE_CAPTURE_SHIPPING_STATUS = [
  constants.shipping_status.PENDING,
  constants.shipping_status.NO_SHIPPING_REQUIRED,
  constants.shipping_status.PROCESSING,
  constants.shipping_status.FAILED,
];

constants.NETSUITE_APPROPRIATE_SHIPPING_STATUS = [
  constants.shipping_status.NO_SHIPPING_REQUIRED,
  constants.shipping_status.BOPIS_COMPLETED,
  constants.shipping_status.SHIPPED,
  constants.shipping_status.RESHIPPED,
  constants.shipping_status.EXCEPTION,
  constants.shipping_status.FAILED,
  constants.shipping_status.DELIVERED,
  constants.shipping_status.CANCELED,
];

constants.NETSUITE_APPROPRIATE_PAYMENT_STATUS = [
  constants.payment_status.CAPTURED,
  constants.payment_status.AUTH_CAPTURED,
  constants.payment_status.COLLECTION,
  constants.payment_status.PAID,
  constants.payment_status.VOID,
];

constants.UNSHIPPED_STATUS = [
  constants.shipping_status.PENDING,
  constants.shipping_status.PROCESSING,
  constants.shipping_status.FAILED,
];

Object.freeze(constants);
exports.constants = constants;
