<template lang="pug">
  .gladly-chat-widget

</template>

<script>
  import { mapState } from 'vuex';
  import gladlyChatWidgetMixin from "@mixins/chatWidgetMixin/gladlyChatWidgetMixin";

  export default {
    name: "GladlyWidget",

    mixins: [
      gladlyChatWidgetMixin,
    ],

    computed: {
      ...mapState('global', ['showChat']),
      ...mapState('chatWidget', ['openChatData']),
    },

    watch: {
      // Watches the route changes for setting the load mode dynamically
      $route (to, from) {
        if (to.path !== from.path) {
          this.mix_onNavigation();
        }
      },

      openChatData: function (data) {
        this.mix_openChat(data.eventName);
      },
    },
  };
</script>

<style lang="stylus" scoped>
</style>