<template lang="pug">
  .email-capture-form
    form(@submit.prevent="submitEmail" :class="type")
      MrEmailInput(
        v-model="email"
        :valid.sync="validEmail"
        autofocus
        :disabled="loading"
        :label="inputLabel"
        :class="{ 'input-flat': theme === 'flat' }"
      )
        button.mr-btn.submit-button.max-at-tweak(
          v-if="type != 'cta-below'"
          :class="{ disabled: disableButton, [submitBtnClasses]: true }"
          :disabled="disableButton"
          type="submit"
          data-mr-ass="email-submit-button"
        ) {{ submitLabel }}
      button.mr-btn.btn-block.xs-f-xsmall.max-at-tweak(v-if="type == 'cta-below'") {{ submitLabel }}

    .captcha(v-if="showCaptcha")
      Captcha(@captcha-response="onCaptchaResponse")

    p.email-in-use-message.feedback-color-1.xs-f-xsmall.sm-f-xxsmall.max-at-tweak.mb-10(v-if="emailInUse")
      | Email already subscribed. Try a different email.

</template>

<script>
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import { getObjProperty } from '@utilities/mrVueUtils';
  import { createCustomerFromEmailCapture } from '@services/vueCustomerSvc';
  import MrEmailInput from '@components/MrEmailInput';
  import Captcha from '@components/captcha';

  const EMAIL_CAPTURE_HCB = 'email capture hcb';

  export default {
    name: 'EmailCaptureForm',

    components: {
      MrEmailInput,
      Captcha,
    },

    inject: ['getObjProperty'],

    props: {
      type: {
        type: String,
        required: true,
      },

      code: {
        type: String,
        default: null,
      },

      emailSource: {
        type: String,
        default: null,
      },
      submitLabel: {
        type: String,
        default: 'Submit'
      },
      inputLabel: {
        type: String,
        default: 'Email'
      },
      eventName: {
        type: String,
        default: null
      },
      theme: {
        type: String,
        default: 'original',
        validator: (value) => ['original', 'color-advisor', 'flat'].includes(value),
      }
    },

    data: () => ({
      email: null,
      captchaResponse: null,
      loading: false,
      validEmail: false,
      showCaptcha: false,
      emailInUse: false,
    }),

    computed: {
      ...mapState('emailCapture', [
        'configs'
      ]),

      ...mapGetters('emailCapture', [
        'subscribed',
      ]),

      promoCode() {
        return this.code || this.getObjProperty(this.configs, 'EMAIL_CAPTURE_DEFAULT_CONFIG.code');
      },

      source() {
        return this.emailSource || this.getObjProperty(this.configs, 'EMAIL_CAPTURE_DEFAULT_CONFIG.emailSource');
      },

      disableButton() {
        return !this.validEmail || this.loading;
      },
      submitBtnClasses() {
        if (this.theme === 'color-advisor') {
          return 'btn-primary btn-color-advisor';
        }
        if (this.theme === 'flat') {
          return 'btn-flat';
        }
        return 'btn-secondary';
      }
    },

    methods: {
      ...mapActions(['notifyError']),
      ...mapActions('emailCapture', [
        'trackEvent',
      ]),

      ...mapMutations('emailCapture', [
        'setSubscribed',
      ]),

      ...mapActions('customer', [
        'refreshCustomerCdata',
        'getMarketingPreferences',
      ]),

      onCaptchaResponse(response) {
        this.captchaResponse = response;
        this.submitEmail();
      },

      async submitEmail() {
        if (!this.validEmail) {
          return;
        }

        this.loading = true;
        this.emailInUse = false;
        this.showCaptcha = false;
        this.createCustomerWithPromo().then((response) => {
          const offerApplied = !!this.getObjProperty(response, 'data.offerApplied');

          this.loading = false;

          if (!offerApplied) {
            this.emailInUse = true;
            return;
          }

          let variation;
          if (window.location.pathname.indexOf('/colorbar') === 0) {
            variation = EMAIL_CAPTURE_HCB;
          }

          if (this.eventName) {
            this.trackEvent(this.eventName);
          } else if (this.type === 'modal') {
            this.trackEvent('emailCaptureModalSubmit', {
              variation,
            });
          } else {
            this.trackEvent('emailCaptureFooterSubmit');
          }

          this.refreshCustomerCdata()
            .then(this.getMarketingPreferences)
            .then(() => {
              this.$emit('email-submitted');
              this.setSubscribed(true);
            });
        }).catch((err) => {
          this.loading = false;
          const code = getObjProperty(err, 'response.data.code');

          if (['MISSING_PASSWORD', 'USER_ALREADY_EXISTS'].includes(code)) {
            this.emailInUse = true;
            return;
          }

          if (code === 'CAPTCHA') {
            this.showCaptcha = true;
            return;
          }

          this.notifyError({ err });
        });
      },

      createCustomerWithPromo() {
        const isBookingOfferCode = this.source === 'HCB_email_capture';
        return createCustomerFromEmailCapture({
          offerCode: this.promoCode,
          isBookingOfferCode,
          captchaResponse: this.captchaResponse,
          email: this.email,
          email_source: this.source,
          skipPassword: true,
        });
      },
    },
  };
</script>

<style lang="stylus">
  .email-capture-form
    overflow hidden

    form
      display flex

      .input-flat
        font-family f-primary!important
        color #000!important

        .suggestion-container
          color #fff!important

        label
          font-family f-primary-bold!important
          color brand-color-1

      .btn-flat
        font-family f-primary
        letter-spacing 0.04038rem
        border 2px solid brand-color-1
        background-color brand-color-1


      &.cta-below
        display block

        .mr-email-input
          margin-bottom 0.75em

      .mr-email-input
        flex-basis 100%

      .inner-button
        .mr-btn
          height 45px
          padding 0.5em 1em
          margin 0 0 0 -0.3em

    @media mq-mobile
      form
        display block

        .inner-button
          .mr-btn
            width 100%
            margin 0.5em 0 0 0

          .mr-btn:not(.btn-color-advisor)
            height initial
</style>
