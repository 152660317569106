<template lang='pug'>
  .toggle
    slot(v-bind:val="val" v-bind:set-val="setVal" v-bind:toggle-val="toggleVal")
</template>

<script>
  export default {
    props: {
      initialValue: {
        type: [String, Number],
        required: false,
        default: 0
      },
      delay: {
        type: Number,
        required: true,
      },
      intervalValues: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        val: 0,
        intervalId: null
      };
    },
    destroyed() {
      window.clearInterval(this.intervalId);
    },
    mounted() {
      if (!this.intervalValues || this.intervalValues.length <= 0) {
        return;
      }
      if (this.initialValue && this.intervalValues.indexOf(this.initialValue) !== -1) {
        this.val = this.initialValue;
      } else {
        this.val = this.intervalValues[0];
      }

      this.initInterval();
    },

    methods: {
      setVal(newVal) {
        if (this.intervalValues.indexOf(newVal) !== -1) {
          window.clearInterval(this.intervalId);
          this.val = newVal;
          this.initInterval();
        }
      },

      toggleVal(newVal) {
        if (this.val == newVal) {
          this.val = 0;
        } else {
          this.val = newVal;
        }
      },
      initInterval() {
        this.intervalId = window.setInterval(() => {
          const idx = this.intervalValues.indexOf(this.val);
          if (idx !== -1 && idx + 1 < this.intervalValues.length) {
            this.val = this.intervalValues[idx + 1];
          } else {
            this.val = this.intervalValues[0];
          }
        }, this.delay);
      }
    }
  };
</script>
