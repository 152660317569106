<template lang="pug">
  .notify-box
    CustomNotifications(:notifications="notifications.notifications" @remove-notification="removeNotification")
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import CustomNotifications from "@components/CustomNotifications";

  export default {
    name: "Notifications",
    
    components: {
      CustomNotifications,
    },

    computed: {
      ...mapState(['notifications']),
    },

    methods: {
      ...mapActions(['removeNotification']),
    }
   };
</script>

<style scoped lang="stylus">
  .notify-box
    top 0
    left 0
    right 0
    width 100%
    z-index 9999999
    position fixed

    .custom-notify-box
      width 100%

</style>