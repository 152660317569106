import { getObjProperty } from "../utilities/mrVueUtils";

export default {
  extoleSignUp,
  extoleHeaderTag,
  showExtoleReferralPostPurchasePopup,
};

export function extoleSignUp(customerId, email) {
  initExtole();

  if (!window.extole) {
    return;
  }

  window.extole.createZone({
    name: "registration",
    data: {
      "email": email,
      "partner_user_id": customerId
    }
  });
}

export function extoleHeaderTag() {
  initExtole();

  if (!window.extole) {
    return;
  }

  window.extole.createZone({
    name: "global_header",
    element_id: 'extole_zone_global_header',
    data: {
      "email": getEmail(),
      "first_name": getFirstName(),
      "last_name": getLastName(),
      "partner_user_id": getCustomerId(),
      "labels": determineTagLabel() // "refer-a-friend" is a program in Extole
    }
  });
}

export function showExtoleReferralPostPurchasePopup() {
  initExtole();

  if (!window.extole) {
    return;
  }

  window.extole.createZone({
    name: "autopop",
    element_id: 'extole_zone_autopop',
    data: {
      "email": getEmail(),
      "first_name": getFirstName(),
      "last_name": getLastName(),
      "partner_user_id": getCustomerId(),
      "labels": determineTagLabel() // "refer-a-friend" is a program in Extole
    }
  });
}

function determineTagLabel() {
  let tagLabel = "refer-a-friend";

  if (window.env === 'staging') {
    tagLabel += '-qa';
  } else if (window.env === 'development') {
    tagLabel += '-staging'; // yep, this is how it is. On MR qa servers window.env is set to "staging" but in Extole local dev program is called that.
  }

  return tagLabel;
}

function initExtole() {
  (function(c,b,f,k,a){c[b]=c[b]||{};for (c[b].q=c[b].q||[];a<k.length;)f(k[a++],c[b]);})(window,"extole",function (c,b){b[c]=b[c]||function (){b.q.push([c,arguments]);};},["createZone"],0);
}

function getFirstName() {
  let userData = getUserData();
  return userData.firstName;
}

function getLastName() {
  let userData = getUserData();
  return userData.lastName;
}

function getEmail() {
  let userData = getUserData();
  return userData.email;
}

function getCustomerId() {
  let userData = getUserData();
  return userData.userId;
}

function getUserData() {
  return getObjProperty(window, 'MR.bt_track') || {};
}