

const state = {
  openChatData: {
    eventName: null,
  },
  overrideHideChat: false
};

const getters = {};

export const mutations = {
  setDataForOpeningChat(state, data = {}) {
    let actualData = { ...data };
    state.openChatData = { eventName: actualData.eventName };
  },

  setOverrideHideChat(state, val) {
    state.overrideHideChat = val;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
