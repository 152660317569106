import trackEventMixin from "../trackEventMixin";
export default {
  mixins: [trackEventMixin],

  methods: {
    /**
     * Ensures we are sending the right Segment event related to chat.
     * 
     * @param {string} eventName 
     */
     mix_trackSegmentEvent(eventName) {
      if (!eventName || typeof eventName != 'string') {
        eventName = 'CC Chat';
      }

      this.mix_trackEvent(eventName);
    },

    /**
     * Ensures we are sending the right MR Event related to chat.
     * 
     * @param {string} eventName 
     * @param {object} options 
     */
    mix_trackMrEvent(eventName, options) {
      if (!eventName || typeof eventName != 'string') {
        eventName = 'CC Chat';
      }

      this.trackMREvent(eventName, options);
    },

    /**
     * Inserts and loads the script into the DOM
     */
    mix_addChatWidgetLoadScript() {
      if (this.mix_isMainScriptLoaded()) {
        return;
      }

      // Create the script
      const script = document.createElement('script');

      this.mix_configureChatWidgetLoadScript(script);

      // Register the callback after it's loaded
      script.onload = this.mix_onScriptLoaded.bind(this);

      // Insert it into the DOM
      document.body.appendChild(script);

      this.mix_afterLoadScriptAdded();
    },

    /**
     * Once the script is added, it waits until the support script has been initialized.
     */
    mix_afterLoadScriptAdded() {

    },

    /**
     * Configures scripts for loading the widget.
     * 
     * @param {object} script 
     * @returns script
     */
    mix_configureChatWidgetLoadScript(script) {
      return script;
    },

    /**
     * Registers show/hide events after the full script was loaded and sets up prefill and event tracking
     */
    mix_onScriptLoaded() {
      return;
    },

    /**
     * Checks if the the main script was already loaded
     * @returns {boolean} status
     */
    mix_isMainScriptLoaded() {
      return false;
    },

    /**
     * Manually opens the chat, if the main script is loaded.
     * 
     * @param {string} eventName 
     */
    mix_openChat(eventName) {
      if (this.mix_isMainScriptLoaded()) {
        this.mix_doOpenChat(eventName);
      }
    },

    /**
     * Placeholder for function that do opens chat manually.
     * 
     * @param {string} eventName 
     */
    // eslint-disable-next-line no-unused-vars
    mix_doOpenChat(eventName) {
    }
  },
};