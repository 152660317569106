<template lang='pug'>
.party(:class='{ "transparent-background": transparentBackground }')
  .absolute-wrap
    canvas(ref='partyCanvas')
  .absolute-wrap
    slot
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'party',
  props: {
    birthday: Boolean,
    urlParam: String,
    limitCookie: String,
    transparentBackground: Boolean,
  },

  computed: {
    ...mapState('customer', ['cdata']),
    ...mapState('global', ['isVueSSRApp']),
    ...mapGetters('global', ['urlParams']),
  },

  mounted() {
    var shouldParty = false;
    var month = new Date().getMonth();
    var hasCookie = this.limitCookie && this.$cookies.get(this.limitCookie);

    if (this.$route.query[this.urlParam]) {
      shouldParty = true;
    }

    if (this.birthday && !hasCookie && !!this.cdata.birthday && new Date(this.cdata.birthday).getMonth() == month) {
      shouldParty = true;
    }

    if (shouldParty) {
      this.party();

      if (this.limitCookie) {
        this.$cookies.set(this.limitCookie, 'partied', '7d');
      }
    }
  },

  methods: {
    confetti(callback) {
      var canvas = this.$refs.partyCanvas;
      var context = canvas.getContext('2d');
      var width = (canvas.width = canvas.parentElement.clientWidth);
      var height = (canvas.height = canvas.parentElement.clientHeight);

      var particle = [];
      var particleCount = 600;
      var gravity = 10;
      var timeInitial = +new Date();
      var timePassed = 0;
      var timeTotal = 0;

      var colors = [
        '#53284F',
        '#e91e63',
        '#9c27b0',
        '#53284F',
        '#3f51b5',
        '#2196f3',
        '#03a9f4',
        '#53284F',
        '#009688',
        '#4CAF50',
        '#8BC34A',
        '#CDDC39',
        '#FFEB3B',
        '#FFC107',
        '#FF9800',
        '#FF5722',
        '#53284F',
      ];

      for (var i = 0; i < particleCount; i++) {
        particle.push({
          x: (width / 2) * randomRange(0.8, 1.2),
          y: (height / 2) * randomRange(0.8, 1.6),
          boxW: randomRange(5, 20),
          boxH: randomRange(5, 20),
          size: randomRange(2, 8),
          velX: randomRange(-12, 12),
          velY: randomRange(-30, -10),
          friction: randomRange(0.4, 0.6),
          angle: convertToRadians(randomRange(0, 360)),
          color: colors[Math.floor(Math.random() * colors.length)],
          anglespin: randomRange(-0.2, 0.2),
          inframe: true,

          draw: function () {
            if (this.inframe) {
              context.save();
              context.translate(this.x, this.y);
              context.rotate(this.angle);
              context.fillStyle = this.color;
              context.beginPath();
              context.fillRect((this.boxW / 2) * -1, (this.boxH / 2) * -1, this.boxW, this.boxH);
              context.fill();
              context.closePath();
              context.restore();

              this.angle += this.anglespin;
              var secondsPassed = timePassed / 1000;
              var gravityVector = gravity * secondsPassed;
              var frictionVectorY = this.friction * this.velY * secondsPassed;
              var changeInVelocity = gravityVector - frictionVectorY;

              this.velY += changeInVelocity;

              this.x += this.velX;
              this.y += this.velY;

              if (this.x + this.boxW < 0 || this.x - this.boxW > width || this.y - this.boxH > height) {
                this.inframe = false;
              }
            }
          },
        });
      }

      function randomRange(min, max) {
        return min + Math.random() * (max - min);
      }

      function convertToRadians(degree) {
        return degree * (Math.PI / 180);
      }

      function drawScreen() {
        for (var i = 0; i < particle.length; i++) {
          particle[i].draw();
        }
      }

      function update() {
        context.clearRect(0, 0, width, height);
        var newTimeTotal = +new Date() - timeInitial;
        timePassed = newTimeTotal - timeTotal;
        timeTotal = newTimeTotal;
        drawScreen();

        if (timeTotal < 5000) {
          requestAnimationFrame(update);
        } else {
          context.clearRect(0, 0, width, height);
          if (callback) {
            callback();
          }
        }
      }

      update();
    },

    fadeIn(duration, callback) {
      let self = this;
      let start;
      let easing = function (t) {
        return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
      };
      self.$el.style.display = 'block';
      self.$el.style.opacity = 0;

      window.requestAnimationFrame(function step(timestamp) {
        if (!start) {
          start = timestamp;
        }
        let time = timestamp - start;
        let percent = Math.min(time / duration, 1);
        percent = easing(percent);
        self.$el.style.opacity = 1 * percent;

        if (time < duration) {
          window.requestAnimationFrame(step);
        } else {
          callback();
        }
      });
    },

    fadeOut(duration) {
      let self = this;
      let start;
      let easing = function (t) {
        return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
      };

      window.requestAnimationFrame(function step(timestamp) {
        if (!start) {
          start = timestamp;
        }
        
        let time = timestamp - start;
        let percent = Math.min(time / duration, 1);
        percent = easing(percent);
        self.$el.style.opacity = 1 * (1 - percent);

        if (time < duration) {
          window.requestAnimationFrame(step);
        } else {
          self.$el.style.opacity = 0;
          self.$el.style.display = 'none';
        }
      });
    },

    party() {
      this.fadeIn(600, () => {
        this.confetti(() => {
          this.fadeOut(600);
        });
      });
    },
  },
};
</script>

<style lang='stylus'>
.party {
  position: fixed;
  z-index: 250;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(256, 256, 256, 0.95);
  display: none;

  &.transparent-background {
    background-color: transparent;
  }
}
</style>
