<template lang="pug">
  .cms-partial(v-if="html")
    component(:is="htmlComponent" v-on="$listeners" v-bind="$props" :client-config="config")
    component(is="style" v-if="css") {{css}}

</template>

<script>
  import vueCmsSvc from "@services/vueCmsSvc";

  export default {
    name: "CMSPartial",

    props: {
      mixinKey: {
        type: String,
        default: '',
      },

      initHtml: {
        type: String,
        default: '',
      },

      initCss: {
        type: String,
        default: '',
      },

      config: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        html: null,
        css: null,
      };
    },

    computed: {
      htmlComponent() {
        return {
          template: this.html,
          props: {
            clientConfig: {
              type: Object,
              default: () => ({})
            }
          }
        };
      },
    },

    watch: {
      mixinKey: {
        handler: 'update',
      }
    },

    created() {
      if (this.initHtml && this.initCss) {
        this.html = this.initHtml;
        this.css = this.initCss;
      }

      this.update();
    },

    methods: {
      update() {
        if (this.mixinKey) {
          vueCmsSvc.getPartial({ mixinKey: this.mixinKey }).then(res => {
            this.html = res.data.html;
            this.css = res.data.css;
          });
        }
      }
    },
  };
</script>

<style scoped lang="stylus">
</style>
