<template lang="pug">
  .phone-capture-form
    form(@submit.prevent="submitPhoneNumber")
      mr-phone-input(label="Phone number" input-type="tel" v-model="smsNumber" :v="$v.smsNumber" autocomplete='tel' autofocus dataMrAss="phone")
      button.mr-btn.btn-secondary.submit-button(:class="{ disabled: disableSubmit }" :disabled="disableSubmit" type="submit" data-mr-ass="phone-submit-button") Submit

</template>

<script>
import MrPhoneInput from "../../mrPhoneInput";
import {mrPhoneValidator} from "@utilities/customValidators";
export default {
  name: "PhoneCaptureForm",
  components: {
    MrPhoneInput
  },

  props: {
    btnDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      smsNumber: null,
    };
  },

  validations: {
    smsNumber: {
      mrPhoneValidator,
    }
  },

  computed: {
    disableSubmit() {
      return this.$v.smsNumber.$invalid || this.btnDisabled || !this.smsNumber;
    }
  },

  methods: {
    submitPhoneNumber() {
      this.$emit('submit-phone', this.smsNumber);
    }
  }
};
</script>

<style scoped lang="stylus">
  .phone-capture-form
    form
      display flex

      .mr-input
        flex-basis 100%

      .mr-btn
        height 45px
        padding 0.5em 1em
        margin 0.55em 0 0 -0.3em
</style>