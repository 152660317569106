import { getObjProperty } from "@utilities/mrVueUtils";
import vueProductSvc from "@services/vueProductSvc";
import segmentTracking from "@utilities/segmentTracking";
const state = {
  product: {},
  title: null,
  displayPrice: null,
  discountPrice: null,
  cartType: null,
  pdpLink: false,
  onSuccessCallback: null,
  returnFocusElement: null,
};


export const getters = {
  strikePrice(state) {
    return getObjProperty(state.product, 'oneTimeStrikePrice');
  }
};

export const actions = {
  setQuickLookModalData({ commit, dispatch }, params) {
    dispatch('clearData');
    let { title, product, cartType, pdpLink, displayPrice, discountPrice, onSuccessCallback, returnFocusElement } = params;
    commit('setTitle', title);
    commit('setPdpLink', pdpLink);
    commit('setProduct', product);
    commit('setDisplayPrice', displayPrice);
    commit('setDiscountPrice', discountPrice);
    commit('setCartType', cartType);
    commit('setReturnFocusElement', returnFocusElement);

    if (onSuccessCallback) {
      commit('setOnSuccessCallback', onSuccessCallback);
    }
  },

  showQuickLookModal({ state, dispatch }, params) {
    dispatch('setQuickLookModalData', params);
    dispatch(
      'modal/showModal',
      {
        component: 'ProductQuickLookModal',
        theme: 'app-modal-medium',
        returnFocusElement: state.returnFocusElement,
      },
      { root: true }
    );
  },

  clearData({ commit }) {
    commit('setTitle', null);
    commit('setPdpLink', false);
    commit('setProduct', {});
    commit('setDisplayPrice', null);
    commit('setDiscountPrice', null);
    commit('setCartType', null);
    commit('setOnSuccessCallback', null);
    commit('setReturnFocusElement', null);
  },

  openQuickLookModalWithProductId({ dispatch }, productId) {
    if (!productId) {
      return;
    }
    // get product data
    vueProductSvc.getProductById({ id: productId }).then(res => {
      let product = res.data;
      if (!product) {
        return;
      }

      let payload = {
        product: product,
        displayPrice: product.price,
        cartType: 'activeCart',
        onSuccessCallback: () => {
          dispatch('notifySuccess', 'Item has been added to your cart', { root: true });
        }
      };

      segmentTracking.trackMREvent('Quick View Opened', {
        ctaSource: 'Dynamic Yield',
        productId: product.id,
        productName: product.name
      });

      // open modal
      dispatch('showQuickLookModal', payload);
    });
  },
};

export const mutations = {
  setTitle(state, val) {
    state.title = val;
  },

  setProduct(state, val) {
    state.product = val;
  },

  setCartType(state, val) {
    state.cartType = val;
  },

  setPdpLink(state, val) {
    state.pdpLink = Boolean(val);
  },

  setDisplayPrice(state, val) {
    state.displayPrice = val;
  },

  setDiscountPrice(state, val) {
    state.discountPrice = val;
  },

  setOnSuccessCallback(state, val) {
    state.onSuccessCallback = val;
  },

  setReturnFocusElement(state, val) {
    state.returnFocusElement = val;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};