import Cookies from 'js-cookie';
import segmentTracking from '../../utilities/segmentTracking';
import {
  EMAIL_CAPTURE_COOKIE,
  EMAIL_SOURCE,
  EMAIL_SOURCE_HCB,
  EMAIL_CAPTURE_CAMPAIGN,
  PHONE_CAPTURE_COOKIE
} from './constants';

export function setEmailCaptureModalCookie() {
  Cookies.set(EMAIL_CAPTURE_COOKIE, 'true', { expires: 365, path: '/' });
}

export function setPhoneCaptureModalCookie() {
  Cookies.set(PHONE_CAPTURE_COOKIE, 'true', { expires: 365, path: '/' });
}

export function getEmailCaptureModalCookieExists() {
  return !!Cookies.get(EMAIL_CAPTURE_COOKIE);
}

export function getPhoneCaptureModalCookieExists() {
  return !!Cookies.get(PHONE_CAPTURE_COOKIE);
}

/**
 * Tracks the A/B tested version on Segment
 * @param {string} eventName
 * @param {string} version
 */
export function trackEvent(eventName, experiments = {}) {

  const experimentVariants = {};
  Object.entries(experiments).forEach(([name, { variant }]) => {
    experimentVariants[name] = variant;
  });

  const properties = {
    ...experimentVariants,
    campaign: EMAIL_CAPTURE_CAMPAIGN,
  };

  segmentTracking.trackSegmentEvent(eventName, properties);
}

/**
 * Campaign Rules - function that takes a context and returns boolean indicating if
 * the rule has passed for the given context ('cdata' object in this case.)
 */
export const CAMPAIGN_RULES = {
  isThirdParty(context) {
    return !!context.thirdParty;
  },

  isFirstTimeVisitor(context) {
    return context.visitorType === 'Net New';
  },

  isReturnVisitor(context) {
    return context.visitorType === 'Return Visitor';
  },

  hasCapturedEmail(context) {
    return !!(context.email && [EMAIL_SOURCE, EMAIL_SOURCE_HCB].includes(context.emailSource));
  },

  hasEmail(context) {
    return !!context.email;
  },

  hasDismissedEmailModal() {
    return getEmailCaptureModalCookieExists();
  },

  hasDismissedPhoneModal() {
    return getPhoneCaptureModalCookieExists();
  },

  hasPurchaseHistory(context) {
    return !!context.isCustomer;
  },

  hasCustomerId(context) {
    return !!context.cid;
  },
};

export function validateViewForEmailCapture(params = {}) {
  const masterHCB = params.configs?.MASTER_TOGGLE?.show_hcb_modal;
  const masterDIRECT = params.configs?.MASTER_TOGGLE?.show_direct_modal;
  const isHCB = params.isHCB;

  if (isHCB && !masterHCB) {
    return false;
  }

  if (!isHCB && !masterDIRECT) {
    return false;
  }

  let { configs, rules, allowed_paths, banned_paths, cdata } = params;
  if (!rules) {
    rules = configs.EMAIL_CAPTURE_RULES;
  }

  if (!allowed_paths) {
    allowed_paths = configs.EMAIL_CAPTURE_ALLOWED_PATHS;
  }

  if (!banned_paths) {
    banned_paths = configs.EMAIL_CAPTURE_BANNED_PATHS;
  }

  const areRulesValid = Object.entries(rules).every(([name, value]) => {
    return CAMPAIGN_RULES[name](cdata) === value;
  });

  if (!areRulesValid) {
    return false;
  }

  const currentPathName = window.location.pathname;

  if (allowed_paths && allowed_paths.length) {
    const isRouteAllowed = allowed_paths.some(path => currentPathName.indexOf(path) === 0);
    if (!isRouteAllowed) {
      return false;
    }
  }

  if (banned_paths && banned_paths.length) {
    const isRouteBanned = banned_paths.some(path => currentPathName.indexOf(path) === 0);
    if (isRouteBanned) {
      return false;
    }
  }

  if (window.contentData && window.contentData.hideFreeShippingBanner) {
    return false;
  }

  return true;
}