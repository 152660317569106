<template lang="pug">
  VueSlickCarousel(v-bind="settings" ref="slick")
    slot
</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel';

  export default {
    name: 'SlickCarouselSsr',
    
    components: { 
      VueSlickCarousel,
    },

    data: () => ({
      isMounted: false,
    }),

    computed: {
      settings() {
        const settings = {
          ...this.$attrs,
        };
        
        // The responsive config must be loaded only on client side to avoid issues on ssr
        if (!this.isMounted) {
          settings.responsive = undefined;
        }

        return settings;
      }
    },

    mounted() {
      this.isMounted = true;
    },

    methods: {
      prev() {
        this.$refs.slick.prev();
      },

      next() {
        this.$refs.slick.next();
      },
    },
  };
</script>