<template lang="pug">
  transition(name="fade")
    .mr-spinner-veil(:class="{ 'full-screen': fullScreen }")
      .veil
      .spinner-wrap
        MrSpinner
</template>

<script>

  import MrSpinner from "../MrSpinner/MrSpinner.vue";

  export default {
    name: "MrSpinnerVeil",
    components: { MrSpinner },
    props: {
      fullScreen: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  };
</script>

<style scoped lang="stylus">
.mr-spinner-veil
  &.full-screen
    position fixed
    display block
    width 100%
    height 100%
    top 0
    left 0
    z-index 5000000000!important
  .veil
    top 0
    bottom 0
    right 0
    left 0
    position absolute
    background-color white
    opacity 0.7
    z-index 1000

  .spinner-wrap
    top 50%
    left 50%
    position absolute
    transform translate(-50%, -50%)
    z-index 1000

</style>