import { render, staticRenderFns } from "./StickyPromoDrawer.vue?vue&type=template&id=08a4501a&scoped=true&lang=pug"
import script from "./StickyPromoDrawer.vue?vue&type=script&lang=js"
export * from "./StickyPromoDrawer.vue?vue&type=script&lang=js"
import style0 from "./StickyPromoDrawer.vue?vue&type=style&index=0&id=08a4501a&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08a4501a",
  null
  
)

export default component.exports