import mrApi from './mrApi';

export default {
  getCartDataForView,
  addToCart,
  addCustomGiftCardToCart,
  addMultipleItemsToCart,
  addExtraTube,
  updateItemQty,
  updateItemSubscription,
  removeFromCart,
  setShippingMethod,
  loadPromoById,
  applyCoupon,
  removeCoupon,
  applyPromo,
  passesPromoCustomerRestrictions,
  passesPromoCartConditions,
  passesPromoCustomerRestrictionsAndCartConditions,
  getPromotionalMessagesByProductInfo,
  getPromoMessagesForProductInfosByProductCode,
  validatePromoById,
  getPreOrderId,
  cancelPreOrder,
  moveToOtherCart,
  shipRefillWithCart,
  createTestCart,
  setLocationId,
  setToSameDayDelivery,
  setToShipToHome,
  setShippingAddress,
  setUseGiftCards,
  getAvailabilityByRegion,
  setDoorDashTip,
  setDoorDashLocationId,
  upgradeOrderToSubscription,
  setUseCredits,
};

export function getCartDataForView(params) {
  return mrApi.get('/api/cart/getCartDataForView', { params });
}

export function addToCart(params) {
  return mrApi.post('/api/cart/addToCart', params);
}

export function addCustomGiftCardToCart(params) {
  return mrApi.post('/api/cart/addCustomGiftCardToCart', params);
}

export function addMultipleItemsToCart(params) {
  return mrApi.post('/api/cart/addMultipleItemsToCart', params);
}

export function addExtraTube(params) {
  return mrApi.post('/api/cart/addExtraTube', params);
}

export function updateItemQty(params) {
  return mrApi.post('/api/cart/updateItemQty', params);
}

export function updateItemSubscription(params) {
  return mrApi.post('/api/cart/updateItemSubscription', params);
}

export function removeFromCart(params) {
  return mrApi.post('/api/cart/removeItemFromCart', params);
}

export function setShippingMethod(params) {
  return mrApi.post('/api/cart/setShippingMethod', params);
}

export function loadPromoById(params) {
  return mrApi.get('/api/promo/loadById', { params });
}

export function applyCoupon(params) {
  return mrApi.post('/api/cart/applyCoupon', params);
}

export function removeCoupon(params) {
  return mrApi.post('/api/cart/removeCoupon', params);
}

export function applyPromo(params) {
  return mrApi.post('/api/cart/applyPromo', params);
}

export function passesPromoCustomerRestrictions(params) {
  return mrApi.get('/api/promo/passesPromoCustomerRestrictions', { params });
}

export function passesPromoCartConditions(params) {
  return mrApi.get('/api/promo/passesPromoCartConditions', { params });
}

export function passesPromoCustomerRestrictionsAndCartConditions(params) {
  return mrApi.get('/api/promo/passesPromoCustomerRestrictionsAndCartConditions', { params });
}

export function getPromotionalMessagesByProductInfo(params) {
  return mrApi.get('/api/promo/getPromotionalMessagesByProductInfo', { params });
}

export function getPromoMessagesForProductInfosByProductCode(params) {
  return mrApi.get('/api/promo/getPromoMessagesForProductInfosByProductCode', { params });
}

export function validatePromoById(params) {
  return mrApi.get('/api/cart/validatePromoById', { params });
}

export function getPreOrderId(params) {
  return mrApi.get('/api/preOrder/getPreOrderId', { params });
}

export function cancelPreOrder(params) {
  return mrApi.post('/api/preOrder/cancelPreOrder', params);
}

export function moveToOtherCart(params) {
  return mrApi.post('/api/cart/moveToOtherCart', params);
}

export function shipRefillWithCart(params) {
  return mrApi.post('/api/cart/shipRefillWithCart', params);
}

export function getPerfectPairPrices(params) {
  return mrApi.get('/api/cart/getPerfectPairPrices', { params });
}

export function createTestCart(params) {
  return mrApi.post('/api/cart/createTestCart', params);
}

export function setLocationId(params) {
  return mrApi.post('/api/cart/setLocationId', params);
}

export function setToShipToHome(params) {
  return mrApi.post('/api/cart/setToShipToHome', params);
}

export function setToSameDayDelivery(params) {
  return mrApi.post('/api/cart/setToSameDayDelivery', params);
}

export function setShippingAddress(params) {
  return mrApi.post('/api/cart/setShippingAddress', params);
}

export function setUseGiftCards(params) {
  return mrApi.post('/api/cart/setUseGiftCards', params);
}

export function getAvailabilityByRegion(params) {
  return mrApi.post('/api/cart/getAvailabilityByRegion', params);
}

export function setDoorDashTip(params) {
  return mrApi.post('/api/cart/setDoorDashTip', params);
}

export function setDoorDashLocationId(params) {
  return mrApi.post('/api/cart/setDoorDashLocationId', params);
}

export function upgradeOrderToSubscription(params) {
  return mrApi.post('/api/cart/upgradeOrderToSubscription', params);
}

export function setUseCredits(params) {
  return mrApi.post('/api/cart/setUseCredits', params);
}