import dayjs from '@dayjs';
import { isNum } from "./utilities/mrVueUtils";

export function currency(value) {
  if (value < 0) {
    return '-$' + parseFloat(value * -1).toFixed(2);
  } else {
    return '$' + parseFloat(value).toFixed(2);
  }
}

export function currencyShort(value, noFreeText = false) {
  if (value === 0 && !noFreeText) {
    return 'Free';
  }
  let prefix = '$';
  let decimalPlace = 2;
  let valueCopy = value;

  if (value < 0) {
    prefix = '-$';
    valueCopy = valueCopy * -1;
  }

  // 60.00001 => $60
  if (value % 1 < 0.01) {
    decimalPlace = 0;
  }

  return prefix + parseFloat(valueCopy).toFixed(decimalPlace);
}

export function freeCurrency(value) {
  if (value == 0) {
    return 'FREE';
  } else if (value < 0) {
    return '-$' + parseFloat(value * -1).toFixed(2);
  } else {
    return '$' + parseFloat(value).toFixed(2);
  }
}

export function customCurrency(value) {
  if (value < 0) {
    return '-$' + parseFloat(value * -1).toFixed(2);
  } else {
    return '$' + parseFloat(value).toFixed(2);
  }
}

export function lowercase(value) {
  return '' + value.toLowerCase();
}

export function uppercase(value) {
  return '' + value.toUpperCase();
}

// [nextDate, "YYYY-MM-DD"] | formatDate("DD")
// nextDate | formatDate('sadadas')
export function formatDate(input, format) {
  if (!input) {
    return;
  }

  let inputDate = input;
  let inputFormat;
  if (Array.isArray(input)) {
    [inputDate, inputFormat] = input;
  }

  if (!inputDate) {
    return;
  }

  const date = dayjs(inputDate, inputFormat);

  if (!format) {
    return date;
  }

  return date.format(format);
}

export function number(value) {
  if (!isNum(value)) {
    return;
  }

  return Number(value).toLocaleString();
}

export default {
  currency,
  freeCurrency,
  currencyShort,
  customCurrency,
  lowercase,
  uppercase,
  formatDate,
  number
};
