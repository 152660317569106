<template lang="pug">
  .site-nav-desktop-shop-menu
    .menu-section(v-for="section in shopNavSections" :key="section.title" :class="sectionWidthClass(section)")
      .section-title.text-left.xs-mr-100m.xs-ml-100m.xs-pb-50m(v-if="section.title")
        NavTitle {{ section.title }}

      .section-content.xs-mb-125m
        template(v-if="section.subCategories")
          .sub-category.xs-mb-150m.text-left.category-padding(v-for="category in subCategoriesFiltered(section.subCategories)" :class="subSectionWidthClass(section)")
            NavSubHeader(:stretch="true") {{ category.title }}

            ul(role="menu").category-padding
              li.sub-menu-item(v-for="item in navItemsForContainer(category)" :key="item.title" role="none")
                SiteNavSubMenuItem(:item="item" :hide-sub-title="true")

        template(v-else-if="section.navItems")
          ul(role="menu").category-padding
            li.sub-menu-item.text-left(v-for="item in navItemsForContainer(section)" :key="item.title" role="none")
              template(v-if="item.standaloneUrl")
                StandaloneLink(:href="item.url") {{ item.title }}

              template(v-else)
                SiteNavSubMenuItem(:item="item" :hide-sub-title="true")

</template>

<script>
  import constants from '@components/SiteNav/constants';
  import NavTitle from '@components/SiteNav/NavTitle';
  import NavSubHeader from '@components/SiteNav/NavSubHeader';
  import SiteNavSubMenuItem from '@components/SiteNav/SiteNavSubMenuItem';
  import StandaloneLink from '@components/SiteNav/StandaloneLink';

  import menuMixin from "@mixins/menuMixin";

  export default {
    name: "SiteNavDesktopShopMenu",

    components: {
      NavTitle,
      NavSubHeader,
      SiteNavSubMenuItem,
      StandaloneLink
    },

    mixins: [menuMixin],

    inject: [ 'getObjProperty' ],

    computed: {
      shopNavSections() {
        return constants.SHOP_NAV.navItems.map(item => constants[item.subNav]);
      }
    },

    methods: {
      subCategoriesFiltered(subCategories) {
        return this.mix_copyOnlyElementsToShow(subCategories);
      },

      sectionWidthClass(section) {
        const baseColWidth = 4;
        let multiplier = 1;
        if (section.subCategories && section.subCategories.length) {
          multiplier = section.subCategories.length;
        }

        return `xs-${baseColWidth * multiplier}`;
      },

      subSectionWidthClass(section) {
        return `xs-${12 / section.subCategories.length}`;
      },

      navItemsForContainer(container) {
        return this.mix_copyOnlyElementsToShow(this.getObjProperty(container, 'navItems') || []);
      },
    }
  };
</script>

<style scoped lang="stylus">
  .site-nav-desktop-shop-menu
    .section-title
      border-bottom 1px solid ui-color-5

    .category-padding
      padding-left 1em
      padding-right 1em

    li.sub-menu-item
      padding 0.5em 0

</style>