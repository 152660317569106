<template lang='pug'>
  label
    span.ada-tooltip captcha response
    div(v-bind:id="'captcha-div_' + id")
</template>

<script>
  export default {
    name: 'Captcha',
    data: function() {
      return {
        id: null,
        captchaResponse: null
      };
    },

    created() {
      this.id = this._uid;
    },

    mounted() {
      var self = this;

      window.allCaptchaIds = window.allCaptchaIds || {};
      window.allCaptchaIds[self.id] = {
        div: 'captcha-div_' + self.id,
        widgetId: null,
        callback: res => {
          if (res && res.length) {
            self.$emit('captcha-response', res);
          }
        },
        expireCallback: () => {
          self.$emit('captcha-response', null);
        }
      };

      window.captchaLoaded = window.captchaLoaded || function() {
        Object.keys(window.allCaptchaIds).forEach(function(id) {
          var data = window.allCaptchaIds[id];
          if (data.widgetId === null && window.grecaptcha && window.grecaptcha.enterprise) {
            data.widgetId = window.grecaptcha.enterprise.render(data.div, {
              sitekey: '6LeMfZomAAAAAIAyrN0yqzVXJyiEonRzJ5nVS4I7',
              callback: data.callback,
              "expired-callback": data.expireCallback
            });
          }
        });
      };

      setTimeout(function() {
        var existing = document.getElementById('captcha-script');
        if (existing) {
          window.captchaLoaded();
          return;
        }

        var script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/enterprise.js?onload=captchaLoaded&render=explicit';
        script.async = true;
        script.defer = true;
        script.id = 'captcha-script';
        document.body.appendChild(script);
      }, 50);
    },

    beforeDestroy() {
      var self = this;
      if (window.grecaptcha && window.grecaptcha.enterprise && window.grecaptcha.reset) {
        let id = window.allCaptchaIds[self.id].widgetId;
        if (id !== undefined && id !== null) {
          window.grecaptcha.enterprise.reset(id);
        }
      }
    }
  };
</script>
