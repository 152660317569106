<script>
  import mrInput from './mrInput';

  export default {
    name: "mrPhoneInput",
    extends: mrInput,

    data() {
      return {
        phoneSize: 0,
        oldSize: 0
      };
    },

    watch: {
      value(newValue) {
        if (newValue) {
          this.update(newValue);
        }
      }
    },

    mounted() {
      this.update(this.value);
    },

    methods: {
      update(val) {

        if (!val) {
          return;
        }

        const el = this.$refs.input;
        const pos = el.selectionEnd;
        const atTheEnd = pos == val.length;

        var hasWhiteSpace = /\s/g.test(val);
        var digits = val.replace(/\D/g, '');
        if (digits.length != this.phoneSize || val.length > this.oldSize || !hasWhiteSpace) {
          this.phoneSize = digits.length;
          val = this.formatPhoneNum(val);
          const dif = val.length - this.oldSize;
          this.oldSize = val.length;
          this.delayTouch(this.v);
          this.$emit('input', val);

          if (dif != 0 && !atTheEnd) {
            this.$nextTick(() => {
              el.setSelectionRange(pos, pos);
            });
          }

        }
      },

      formatPhoneNum(input) {
        var digits = input.replace(/\D/g, '');
        var format = '(???) ???-????';
        var international = false;

        var digitLen = Math.min(digits.length, 15);
        if (digits[0] == 1) {
          digitLen--;
        }

        if (digitLen > 10) {
          international = true;
          var countryCodeLen = Math.min(digitLen - 10, 3);
          var extraLen = 15 - digitLen;
          var countryCode = '';
          var i = 0;
          for (i = 0; i < countryCodeLen; i++) {
            countryCode += '?';
          }

          format = countryCode + ' ??? ??? ?? ??';
          for (i = 0; i < extraLen; i++) {
            format += '?';
          }
        }

        if (digits[0] == 1) {
          format = '? ' + format;
        }

        if (international) {
          format = '+' + format;
        }

        digits.split('').forEach(function(digit) {
          format = format.replace('?', digit);
        });

        return format.split('?')[0];
      }

    }
  };
</script>
