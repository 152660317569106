<template lang="pug">
  a(:href="item.url" role="menuitem" @click="mix_trackItemClick(item.title)" :aria-label="item.ariaLabel || item.title").site-nav-sub-menu-item
    .main-title.text-color-1.xs-f-medium.lg-f-small.max-at-tweak {{ item.title }}
    .sub-title.text-color-3.xs-f-xsmall.max-at-tweak(v-if="!hideSubTitle") {{ item.subTitle }}

</template>

<script>
import SiteNavTrackMixin from "../SiteNavTrackMixin/SiteNavTrackMixin";

export default {
  name: "SiteNavSubMenuItem",

  mixins: [SiteNavTrackMixin],

  props: {
    item: {
      required: true,
      type: Object
    },

    hideSubTitle: {
      required: false,
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="stylus">

</style>