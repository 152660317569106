/**
 Can use this component as cms-expose-settings(:cms-settings=`${JSON.stringify(settings)}`)
 this component then will assign this data in to cms store module.

 When necessary consider filtering out certain data on the cms template from these settings to not bloat the frontend:

 - var cleanSettings = {};
 - var excludedSettings = ['componentList', '_lockedFields'];
 - Object.keys(settings || {}).forEach(function(key) { if (!~excludedSettings.indexOf(key)) { cleanSettings[key] = settings[key] } });

 cms-expose-settings(:cms-settings=`${JSON.stringify(cleanSettings)}`)

 */

import { mapMutations } from 'vuex';

export default {
  name: 'CmsExposeSettings',
  props: {
    cmsSettings: {
      type: Object,
      required: true
    }
  },

  render() {
    return;
  },

  methods: {
    ...mapMutations('cms', [
      'setCmsSettings'
    ])
  },

  mounted() {
    this.setCmsSettings(this.cmsSettings);
  }
};