import vueColorbarSvc from '@services/vueColorbarSvc';
import {
  addUnlimitedMembershipDiscounts,
  applyServicePromos,
  extractDiscounts,
  getComments,
  getNotesFromComments,
  getDateFromCalDate,
  matchItemsWithDiscounts
} from '@components/HairColorBarBooking/utils';
import { getObjProperty, formatAppointmentTime } from '@utilities/mrVueUtils';
import vueUrmSvc from '../../services/vueUrmSvc';
import dataToolSvc from '@services/dataToolSvc';

const LIGHTWORKS_SERVICES = [
  'cbs_lightworks',
  'cbs_accent_lightworks',
  'cbs_roots_lightworks',
  'cbs_roots_accent_lightworks',
  'cbs_full',
  'cbs_full_roots',
  'cbs_all_over_accent_lightworks',
  'cbs_all_over_accent_lightworks_blowout',
  'cbs_roots_gloss_accent_lightworks',
  'cbs_roots_gloss_accent_lightworks_blowout',
];

const SELF_DRY_ADDON_DEFAULT = {
  name: 'Self dry',
  isFree: true,
  description: 'Blow dry your hair at our complimentary self-dry station',
  image: 'https://d3ewrnwdcmri66.cloudfront.net/content/images/2020/8/keuf3wu1-all-over-color-1x/all-over-color-1x.jpeg',
  totalTimeRequired: 0,
};

const POTENTIAL_STAND_IN_SERVICES = [
  {
    code: 'stand_in_highlights',
    standInFor: ['cbs_full', 'cbs_lightworks', 'cbs_accent_lightworks'],
    isStandIn: true,
    modalTitle: 'Choose A Highlights Service',
    name: 'highlights',
    description: 'Traditional foil highlights or hand-painted balayage',
    image: 'https://ddxs94deh28np.cloudfront.net/content/svgs/2022/4/service-full-highlights/service-full-highlights.svg'
  },
  {
    code: 'stand_in_roots_highlights',
    standInFor: ['cbs_full_roots', 'cbs_roots_lightworks', 'cbs_roots_accent_lightworks'],
    isStandIn: true,
    modalTitle: 'Choose A Roots + Highlights Service',
    name: 'roots + highlights',
    description: 'Permanent color applied to roots, plus foil highlights or hand-painted balayage',
    image: 'https://ddxs94deh28np.cloudfront.net/content/svgs/2022/4/service-roots-highlights/service-roots-highlights.svg'
  }
];

const state = {
  progressStep: null,
  backButtonInfo: null,
  cmsSettings: {},
  location: null,
  discounts: null,
  selectedService: null,
  selectedAddon: null,
  selectedAddOnTreatments: [],
  selectedDate: null,
  selectedTime: null,
  hold: null,
  allPromos: [],
  appliedPromos: null,
  displaySubtotal: null,
  preDiscountSubTotal: null,
  applyingPromos: false,
  promoErrors: [],
  upcomingAppointments: null,
  unsavedAppointments: null,
  hasLateCancelation: false,
  longHair: false,
  notes: null,
  editAppointment: null,
  editingService: false,
  sensitivityWaiverSigned: false,
  gclid: null,
  freeRootsWindowData: {},
  standInSelected: null,
  addOnTreatments: [],
  bookingFlowConfig: {},
  loadingBookingFlowConfig: false,
};

const mutations = {
  setProgressStep(state, val) {
    state.progressStep = val;
  },

  setBackButtonInfo(state, val) {
    state.backButtonInfo = val;
  },

  setEditAppointment(state, val) {
    state.editAppointment = val;
  },

  setEditingService(state, val) {
    state.editingService = Boolean(val);
  },

  setCmsSettings(state, val) {
    state.cmsSettings = val;
  },

  setUpcomingAppointments(state, val) {
    state.upcomingAppointments = val;
  },

  setUnsavedAppointments(state, val) {
    state.unsavedAppointments = val;
  },

  setAllPromos(state, val) {
    state.allPromos = val;
  },

  setAppliedPromos(state, val) {
    state.appliedPromos = val;
  },

  setDisplaySubtotal(state, val) {
    state.displaySubtotal = val;
  },

  setPreDiscountSubtotal(state, val) {
    state.preDiscountSubTotal = val;
  },

  setApplyingPromos(state, val) {
    state.applyingPromos = val;
  },

  setPromoErrors(state, val) {
    state.promoErrors = val;
  },

  setBookingFlowConfig(state, val = {}) {
    state.bookingFlowConfig = val;
  },

  setLoadingBookingFlowConfig(state, val) {
    state.loadingBookingFlowConfig = val;
  },

  setLocation(state, val) {
    state.location = val;
  },

  setHasLateCancelation(state, val) {
    state.hasLateCancelation = val;
  },

  setFreeRootsWindowData(state, val) {
    state.freeRootsWindowData = val;
  },

  setDiscounts(state, val) {
    state.discounts = val;
  },

  setSelectedService(state, val) {
    state.selectedService = val;

    this._vm.$cookies.set('includesColorWonder', val?.includesColorWonder ? 1 : 0);
  },

  setSelectedAddon(state, val) {
    state.selectedAddon = val;
  },

  setSelectedAddOnTreatments(state, val) {
    if (Array.isArray(val)) {
      state.selectedAddOnTreatments = val;
    } else {
      state.selectedAddOnTreatments.push(val);
    }
  },

  removeSelectedAddOnTreatment(state, val) {
    state.selectedAddOnTreatments = state.selectedAddOnTreatments.filter(s => s._id != val._id);
  },

  setSelectedDate(state, val) {
    state.selectedDate = val;
  },

  setSelectedTime(state, val) {
    state.selectedTime = val;
  },

  setHold(state, val) {
    state.hold = val;
  },

  setLongHair(state, val) {
    state.longHair = !!val;
  },

  setNotes(state, val) {
    state.notes = val;
  },

  setSensitivityWaiverSigned(state, val) {
    state.sensitivityWaiverSigned = Boolean(val);
  },

  setGclid(state, val) {
    state.gclid = val;
  },

  setStandInSelected(state, val) {
    state.standInSelected = val;
  },

  setAddOnTreatments(state, val) {
    state.addOnTreatments = val;
  }
};

const getters = {
  selectedAddOnTreatmentsProductIds(state) {
    return state.selectedAddOnTreatments.map(treatment => {
      return treatment.productAppliedId ? treatment.productAppliedId : treatment.id;
    });
  },
  colorbarMemberOrInCart(state, getters, rootState, rootGetters) {
    const limitlessPro = rootGetters['customer/limitlessPro'];
    const limitlessProPlus = rootGetters['customer/limitlessProPlus'];
    const cartContainsLimitlessPro = rootGetters['cart/cartContainsLimitlessPro'];
    const cartContainsLimitlessProPlus = rootGetters['cart/cartContainsLimitlessProPlus'];

    return limitlessPro || limitlessProPlus || cartContainsLimitlessProPlus || cartContainsLimitlessPro;
  },

  allWaiversSigned(state, getters) {
    const { sensitivityWaiverSigned } = state;
    const { showSensitivityWaiver } = getters;
    return !showSensitivityWaiver || !!sensitivityWaiverSigned;
  },

  showSensitivityWaiver(state, getters, rootState) {
    const { customer } = rootState;
    return !!getObjProperty(customer, 'cdata.beautyProfile.patch_applied');
  },

  serviceIdToBook(state) {
    const { selectedService, selectedAddon } = state;
    let id = null;
    if (selectedService && selectedService._id) {
      id = selectedService._id;
    }
    if (selectedAddon && selectedAddon._id) {
      id = selectedAddon._id;
    }
    return id;
  },

  preDiscountSubTotal(state) {
    const { preDiscountSubTotal, subtotal } = state;
    if (!preDiscountSubTotal) {
      return;
    }

    const preDiscounts = parseFloat(preDiscountSubTotal);

    if (preDiscounts === parseFloat(subtotal)) {
      return;
    }

    return preDiscounts.toFixed(2);
  },

  subtotal(state) {
    const { selectedService, selectedAddon, selectedAddOnTreatments, displaySubtotal } = state;
    // WITH COUPON DISCOUNTS
    if (typeof displaySubtotal === 'number') {
      return displaySubtotal.toFixed(2);
    }

    let subtotal = 0;
    if (selectedService && !selectedService.isFree) {
      subtotal += selectedService.discountPrice || selectedService.price;
    }

    if (selectedAddon && !selectedAddon.isFree) {
      subtotal += parseFloat(selectedAddon.discountPrice || selectedAddon.price) || 0;
    }

    if (selectedAddOnTreatments && selectedAddOnTreatments.length) {
      selectedAddOnTreatments.forEach((s) => {
        subtotal = + parseFloat(s.discountPrice || s.price) || 0;
      });
    }

    return subtotal.toFixed(2);
  },

  appointmentTime(state) {
    const { selectedService, selectedAddon, selectedAddOnTreatments } = state;
    let totalTimeRequired = 0;

    if (selectedService) {
      totalTimeRequired += selectedService.totalTimeRequired;
    }

    if (selectedAddon) {
      totalTimeRequired += selectedAddon.totalTimeRequired;
    }

    if (selectedAddOnTreatments) {
      selectedAddOnTreatments.forEach((s) => {
        totalTimeRequired += s.totalTimeRequired;
      });
    }

    return formatAppointmentTime(totalTimeRequired);
  },

  hasFreeRootsOnDate(state, getters, rootState, rootGetters) {
    const limitlessPro = rootGetters['customer/limitlessPro'];
    const windowData = state.freeRootsWindowData;
    let lastUsed = getObjProperty(state.freeRootsWindowData, 'lastUsed');
    if (limitlessPro && windowData && lastUsed && state.selectedDate) {
      const selected = getDateFromCalDate(state.selectedDate.calDate).getTime();
      lastUsed = new Date(lastUsed);

      const currentWindow = state.freeRootsWindowData.windows.find(w => new Date(w.end).getTime() > selected);
      const lastUsedWindow = state.freeRootsWindowData.windows.find(w => new Date(w.end).getTime() >= lastUsed);
      return currentWindow != lastUsedWindow;
    } else {
      return true;
    }
  },

  servicesOfferedDictionary(state, getters, rootState, rootGetters) {
    const { location } = state;

    const limitlessPro = rootGetters['customer/limitlessPro'];
    const limitlessProPlus = rootGetters['customer/limitlessProPlus'];
    const cartContainsLimitlessProPlus = rootGetters['cart/cartContainsLimitlessProPlus'];

    const servicesDictionary = {};
    location.servicesOffered.forEach(service => {
      servicesDictionary[service.code] = {
        ...service,
        ...addUnlimitedMembershipDiscounts(service, { limitlessPro, limitlessProPlus, cartContainsLimitlessProPlus }),
      };
    });
    return servicesDictionary;
  },

  services(state, getters, rootState, rootGetters) {
    const { location } = state;

    if (!location || !location.servicesOffered) {
      return [];
    }

    const limitlessPro = rootGetters['customer/limitlessPro'];
    const limitlessProPlus = rootGetters['customer/limitlessProPlus'];
    const cartContainsLimitlessProPlus = rootGetters['cart/cartContainsLimitlessProPlus'];

    const services = [...location.servicesOffered]
      .filter(({ isAddonOnly, hidden, bookableOnline }) => !isAddonOnly && !hidden && bookableOnline)
      .map(service => {
        return {
          ...service,
          ...addUnlimitedMembershipDiscounts(service, { limitlessPro, limitlessProPlus, cartContainsLimitlessProPlus }),
          isLightworks: LIGHTWORKS_SERVICES.includes(service.code),
        };
      });

    const servicesOrder = [
      'cbs_roots_only',
      'cbs_roots_gloss',
      'cbs_gloss',
      'cbs_all_over',
      'cbs_full',
      'cbs_lightworks',
      'cbs_accent_lightworks',
      'cbs_full_roots',
      'cbs_roots_lightworks',
      'cbs_roots_accent_lightworks',
      'cbs_gloss_blowout',
      'cbs_toning_glaze_blowout',
      'cbs_all_over_accent_lightworks',
      'cbs_roots_gloss_accent_lightworks'
    ];

    services.sort((a, b) => servicesOrder.indexOf(a.code) - servicesOrder.indexOf(b.code));

    return services;
  },

  servicesIncludingAddons(state, getters, rootState, rootGetters) {
    const { location } = state;

    if (!location) {
      return [];
    }

    const limitlessPro = rootGetters['customer/limitlessPro'];
    const limitlessProPlus = rootGetters['customer/limitlessProPlus'];
    const cartContainsLimitlessProPlus = rootGetters['cart/cartContainsLimitlessProPlus'];

    return [...location.servicesOffered]
      .filter(({ phoneOnly, hidden }) => !phoneOnly && !hidden)
      .map(service => {
        let sortedTags = [...(service.tags || [])].sort();

        return {
          ...service,
          ...addUnlimitedMembershipDiscounts(service,  { limitlessPro, limitlessProPlus, cartContainsLimitlessProPlus }),
          isLightworks: LIGHTWORKS_SERVICES.includes(service.code),
          tagKey: sortedTags.join('-'),
        };
      });
  },

  serviceTagMap(state, getters) {
    const { servicesIncludingAddons } = getters;
    const tagMap = {};

    servicesIncludingAddons.forEach(service => {
      if (service.tagKey) {
        tagMap[service.tagKey] = service;
      }
    });

    return tagMap;
  },

  locationAddOnTreatments(state, getters, rootState, rootGetters) {
    const { location, addOnTreatments } = state;
    const limitlessPro = rootGetters['customer/limitlessPro'];
    const limitlessProPlus = rootGetters['customer/limitlessProPlus'];
    const cartContainsLimitlessProPlus = rootGetters['cart/cartContainsLimitlessProPlus'];

    let addonTreatmentsAdjusted = [];

    if (addOnTreatments && addOnTreatments.length > 0) {
      addOnTreatments.forEach((a) => {
        // filter out boosts for Airstream locations
        if (location.isMobileHCB && a.code === 'boosts') {
          return;
        }

        if (!limitlessPro && !limitlessProPlus) {
          a.forMembersPrice = a.proPlusMemberPrice;

        }

        if (limitlessPro) {
          a.discountPrice = a.proMemberPrice;
        }

        if (limitlessProPlus || cartContainsLimitlessProPlus) {
          a.discountPrice = a.proPlusMemberPrice;
        }

        let configuredAddon = { ...a };

        configuredAddon.image = a?.image?.url;

        addonTreatmentsAdjusted.push(configuredAddon);
      });
    }

    return addonTreatmentsAdjusted;
  },

  consultationsOn(state, getters, rootState, rootGetters) {
    const locationToggleOn = !!getObjProperty(state, 'location.schedulingOpts.consultations');
    const customerHasHadAnAppointment = rootGetters['customer/customerHasHadAnAppointment'];

    return locationToggleOn && !customerHasHadAnAppointment;
  },

  // return only services that don't have a consultation treatment
  nonConsultationServices(state, getters) {
    const { services } = getters;
    return services.filter(service => {
      const serviceHasConsultation = service.treatmentsIncluded.some(treatment => {
        return treatment.isConsultation;
      });
      return !serviceHasConsultation;
    });
  },

  nonConsultationServicesIncludingAddons(state, getters) {
    const { servicesIncludingAddons } = getters;
    return servicesIncludingAddons.filter(service => {
      const serviceHasConsultation = service.treatmentsIncluded.some(treatment => {
        return treatment.isConsultation;
      });
      return !serviceHasConsultation;
    });
  },

  nonConsultationServicesByTreatmentKey(state, getters) {
    const { nonConsultationServices } = getters;
    const nonConsultationServicesByTreatmentKey = {};

    nonConsultationServices.forEach(nonConsultationService => {
      let treatmentKey = nonConsultationService.treatmentsIncluded
        .map(treatment => '' + treatment.id + treatment.applicationTime)
        .sort()
        .join(',');

      nonConsultationServicesByTreatmentKey[treatmentKey] = nonConsultationService;
    });

    return nonConsultationServicesByTreatmentKey;
  },

  nonConsultationServicesIncludingAddonsByTreatmentKey(state, getters) {
    const { nonConsultationServicesIncludingAddons } = getters;
    const nonConsultationServicesIncludingAddonsByTreatmentKey = {};

    nonConsultationServicesIncludingAddons.forEach(nonConsultationService => {
      let treatmentKey = nonConsultationService.treatmentsIncluded
        .map(treatment => '' + treatment.id + treatment.applicationTime)
        .sort()
        .join(',');

      nonConsultationServicesIncludingAddonsByTreatmentKey[treatmentKey] = nonConsultationService;
    });

    return nonConsultationServicesIncludingAddonsByTreatmentKey;
  },

  // return only services that have a consultation treatment
  consultationServices(state, getters) {
    const { services } = getters;

    return services.filter(service => {
      let serviceHasConsultation = service.treatmentsIncluded.some(treatment => {
        return treatment.isConsultation;
      });

      return serviceHasConsultation;
    });
  },

  consultationServicesByTreatmentKey(state, getters) {
    const { consultationServices } = getters;
    const consultationServicesByTreatmentKey = {};

    consultationServices.forEach(consultationService => {
      let treatmentKey = consultationService.treatmentsIncluded
        .filter(treatment => !treatment.isConsultation)
        .map(treatment => '' + treatment.id + treatment.applicationTime)
        .sort()
        .join(',');

      consultationServicesByTreatmentKey[treatmentKey] = consultationService;
    });

    return consultationServicesByTreatmentKey;
  },

  // replace services with their consultation equivalents when consultation are on
  servicesAfterConsultationLogic(state, getters) {
    const { consultationsOn, nonConsultationServices, nonConsultationServicesByTreatmentKey, consultationServicesByTreatmentKey } = getters;

    if (consultationsOn) {
      let newServices = [];

      Object.keys(nonConsultationServicesByTreatmentKey).forEach(treatmentKey => {
        if (consultationServicesByTreatmentKey[treatmentKey]) {
          newServices.push(consultationServicesByTreatmentKey[treatmentKey]);
        } else {
          newServices.push(nonConsultationServicesByTreatmentKey[treatmentKey]);
        }
      });

      return newServices;
    } else {
      return nonConsultationServices;
    }
  },

  packageDealsList(state, getters, rootState) {
    let packageDeals = (getObjProperty(rootState, 'customer.cdata.packageDealsList') || []).filter(deal => {
      return deal && deal.uses_remaining != 0;
    }) || [];

    packageDeals.sort((a, b) => {
      return new Date(a.expires_at) - new Date(b.expires_at);
    });

    return packageDeals;
  },

  lastServiceBooked(state, getters, rootState) {
    let lastAppointmentServiceId = getObjProperty(rootState, 'customer.cdata.lastAppointment.serviceId');
    let addOnTreatments = rootState?.customer?.cdata?.lastAppointment?.addOnTreatments;
    if (!lastAppointmentServiceId) {
      return null;
    }

    const { servicesIncludingAddons, nonConsultationServicesIncludingAddonsByTreatmentKey } = getters;

    let lastServiceBooked = servicesIncludingAddons.find((service) => {
      return service._id == lastAppointmentServiceId;
    });

    if (!lastServiceBooked) {
      return null;
    }

    if (addOnTreatments) {
      lastServiceBooked.addOnTreatments = addOnTreatments;
    }

    let lastServiceTreatmentKey = lastServiceBooked.treatmentsIncluded
      .filter(treatment => !treatment.isConsultation)
      .map(treatment => '' + treatment.id + treatment.applicationTime)
      .sort()
      .join(',');
    lastServiceBooked = nonConsultationServicesIncludingAddonsByTreatmentKey[lastServiceTreatmentKey];

    return lastServiceBooked;
  },

  standInServices(state, getters) {
    const { servicesAfterConsultationLogic } = getters;
    let standInServices = [];

    POTENTIAL_STAND_IN_SERVICES.forEach(standInService => {
      let codes = [];

      codes = standInService.standInFor.filter(code => {
        return servicesAfterConsultationLogic.some(service => code == service.code);
      });

      // only use stand in services when more than one service can be replaced
      if (codes.length > 1) {
        standInServices.push(standInService);
      }
    });

    return standInServices;
  },

  servicesWithStandIns(state, getters) {
    const { servicesAfterConsultationLogic, standInServices } = getters;

    if (!servicesAfterConsultationLogic) {
      return;
    }

    let servicesWithStandIns = [];
    let standInsAdded = {};

    servicesAfterConsultationLogic.forEach(service => {
      let serviceHasStandIn = false;

      standInServices.forEach(standInService => {
        if (standInService.standInFor.includes(service.code)) {
          serviceHasStandIn = true;

          if (!standInsAdded[standInService.code]) {
            standInsAdded[standInService.code] = true;
            servicesWithStandIns.push(standInService);
          }
        }
      });

      if (!serviceHasStandIn) {
        servicesWithStandIns.push(service);
      }
    });

    return servicesWithStandIns;
  },

  addons(state, getters, rootState, rootGetters) {
    const { location, selectedService, addOnTreatments } = state;

    if (!location || !selectedService) {
      return;
    }

    const servicesDictionary = {};
    location.servicesOffered.forEach(service => {
      servicesDictionary[service._id] = { ...service };
    });

    if (selectedService && selectedService.isStandIn) {
      return [];
    }

    const limitlessPro = rootGetters['customer/limitlessPro'];
    const limitlessProPlus = rootGetters['customer/limitlessProPlus'];
    const cartContainsLimitlessProPlus = rootGetters['cart/cartContainsLimitlessProPlus'];

    let addons = selectedService.addonServices.map(addonServices => {
      const addon = {
        ...servicesDictionary[addonServices.serviceId],
        ...addonServices,
      };
      if (addon.displayName) {
        addon.displayName = addon.name;
      }
      addon.totalTimeRequired = addon.totalTimeRequired - selectedService.totalTimeRequired;
      addon.price = addon.price - selectedService.price;
      addon.isBlowOut = /_blowout/.test(addon.code) || addon.code === 'cbs_aocbo';

      if (!addon.isBlowOut) {
        return addon;
      }

      if (!limitlessPro && !limitlessProPlus) {
        addon.forMembersPrice = addon.proPlusMemberPrice - selectedService.proPlusMemberPrice;

      }

      if (limitlessPro) {
        addon.discountPrice = addon.proMemberPrice - selectedService.proMemberPrice;
      }

      if (limitlessProPlus || cartContainsLimitlessProPlus) {
        addon.discountPrice = addon.proPlusMemberPrice - selectedService.proPlusMemberPrice;
      }
      return addon;
    });

    let selfDryAddon = SELF_DRY_ADDON_DEFAULT;
    const cmsSettingsSelfDryAddon = getObjProperty(state, 'cmsSettings.selfDryAddon');
    if (cmsSettingsSelfDryAddon) {
      selfDryAddon = {
        ...selfDryAddon,
        name: cmsSettingsSelfDryAddon.name || selfDryAddon.name,
        description: cmsSettingsSelfDryAddon.description || selfDryAddon.description,
        image: getObjProperty(cmsSettingsSelfDryAddon, 'image.url') || selfDryAddon.image,
      };
    }

    if (addOnTreatments && addOnTreatments.length > 0) {
      let addonTreatmentsAdjusted = [];

      addOnTreatments.forEach((a) => {
        // filter out boosts for Airstream locations
        if (location.isMobileHCB && a.code === 'boosts') {
          return;
        }

        if (a.dontShowFor && a.dontShowFor.length && a.dontShowFor.includes(selectedService.code)) {
          return;
        }

        if (!limitlessPro && !limitlessProPlus) {
          a.forMembersPrice = a.proPlusMemberPrice;

        }

        if (limitlessPro) {
          a.discountPrice = a.proMemberPrice;
        }

        if (limitlessProPlus || cartContainsLimitlessProPlus) {
          a.discountPrice = a.proPlusMemberPrice;
        }

        let configuredAddon = { ...a };

        configuredAddon.image = a?.image?.url;

        addonTreatmentsAdjusted.push(configuredAddon);
      });

      addons = addons.concat(addonTreatmentsAdjusted);
    }

    addons.push(selfDryAddon);

    return addons;
  },

  allAddons(state, getters) {
    const { location } = state;
    const { services } = getters;
    const servicesDictionary = {};
    location.servicesOffered.forEach(service => {
      servicesDictionary[service._id] = { ...service };
    });
    let allAddons = [];

    services.forEach(service => {
      if (service.addonServices) {
        allAddons = allAddons.concat(service.addonServices);
      }
    });

    return allAddons.map(addon => {
      return servicesDictionary[addon.serviceId];
    });
  },

  nonConsultationAddons(state, getters) {
    const { allAddons } = getters;

    return allAddons.filter(addon => {
      if (addon && !addon.treatmentsIncluded) {
        return false;
      }
      const addonHasConsultation = addon.treatmentsIncluded.some(treatment => {
        return treatment.isConsultation;
      });
      return !addonHasConsultation;
    });
  },

  nonConsultationAddonsByTreatmentKey(state, getters) {
    const { nonConsultationAddons } = getters;
    const nonConsultationAddonsByTreatmentKey = {};

    nonConsultationAddons.forEach(nonConsultationAddon => {
      let treatmentKey = nonConsultationAddon.treatmentsIncluded
        .map(treatment => '' + treatment.id + treatment.applicationTime)
        .sort()
        .join(',');

      nonConsultationAddonsByTreatmentKey[treatmentKey] = nonConsultationAddon;
    });

    return nonConsultationAddonsByTreatmentKey;
  },

  consultationAddons(state, getters) {
    const { allAddons } = getters;

    return allAddons.filter(addon => {
      if (!addon.treatmentsIncluded) {
        return false;
      }

      let addonHasConsultation = addon.treatmentsIncluded.some(treatment => {
        return treatment.isConsultation;
      });

      return addonHasConsultation;
    });
  },

  consultationAddonsByTreatmentKey(state, getters) {
    const { consultationAddons } = getters;
    const consultationAddonsByTreatmentKey = {};

    consultationAddons.forEach(consultationAddon => {
      let treatmentKey = consultationAddon.treatmentsIncluded
        .filter(treatment => !treatment.isConsultation)
        .map(treatment => '' + treatment.id + treatment.applicationTime)
        .sort()
        .join(',');

      consultationAddonsByTreatmentKey[treatmentKey] = consultationAddon;
    });

    return consultationAddonsByTreatmentKey;
  },

  servicesDictionary(state, getters) {
    const { services } = getters;

    if (!services) {
      return;
    }

    let servicesDictionary = {};
    services.forEach((service) => {
      servicesDictionary[service.code] = service;
    });

    return servicesDictionary;
  },

  addonsDictionary(state, getters) {
    const { addons } = getters;

    if (!addons) {
      return;
    }

    let addonsDictionary = {};
    addons.forEach((addon) => {
      addonsDictionary[addon.code] = addon;
    });

    return addonsDictionary;
  },

  paymentRequired(state, getters, rootState, rootGetters) {
    const {
      selectedService,
      cmsSettings,
      hasLateCancelation,
    } = state;

    const cartContainsUrm = rootGetters['cart/cartContainsUrm'];

    if (selectedService && selectedService.isLightworks) {
      return true;
    }

    if (cmsSettings && cmsSettings.paymentRequired) {
      return true;
    }

    if (cartContainsUrm) {
      return true;
    }

    return !!hasLateCancelation;
  },

  getSelfDryAddon(state) {
    let selfDryAddon = SELF_DRY_ADDON_DEFAULT;
    const cmsSettingsSelfDryAddon = getObjProperty(state, 'cmsSettings.selfDryAddon');
    if (cmsSettingsSelfDryAddon) {
      selfDryAddon = {
        ...selfDryAddon,
        name: cmsSettingsSelfDryAddon.name || selfDryAddon.name,
        description: cmsSettingsSelfDryAddon.description || selfDryAddon.description,
        image: getObjProperty(cmsSettingsSelfDryAddon, 'image.url') || selfDryAddon.image,
      };
    }
    return selfDryAddon;
  },

  hasFounderOffer(state) {
    if (!state.location || !state.location.openingDate) {
      return false;
    }
    let now = +new Date();
    let openingDate = +new Date(state.location.openingDate);

    return (now - openingDate) < (1000 * 60 * 60 * 24 * 30);
  },

  serviceMembershipPrices: (state, getters, rootState, rootGetters) => (service) => {
    if (rootGetters['customer/limitlessProPlus']) {
      return service?.proPlusMemberPrice;
    } else if (rootGetters['customer/limitlessPro']) {
      return service?.proMemberPrice;
    } else {
      return service?.discountPrice || service?.forMembersPrice;
    }
  }
};

const actions = {
  getBookingFlowConfig({ state, commit }) {
    if (state.loadingBookingFlowConfig) {
      return;
    }

    commit('setLoadingBookingFlowConfig', true);
    dataToolSvc.getData({ mixinKey: 'booking_flow_config' }).then((res) => {
      commit('setBookingFlowConfig', res?.data);
      commit('setLoadingBookingFlowConfig', false);
    }, () => {
      commit('setLoadingBookingFlowConfig', false);
    });
  },

  async getLocation({ commit }, code) {
    const { data: location } = await vueColorbarSvc.getLocation({ code });
    commit('setLocation', location);
    commit('setAddOnTreatments', location.addOnTreatments);
  },

  async getHasLateCancelation({ commit }) {
    const { data } = await vueColorbarSvc.customerHasLateCancelation();
    commit('setHasLateCancelation', data.hasLateCancelation);
  },

  async addEditAppointmentData({ commit, dispatch, getters, state }, appointment) {
    commit('setEditAppointment', appointment);
    const appointmentServiceId = appointment.service._id;

    const addonsDictionary = {};
    state.location.servicesOffered.forEach(({ _id, addonServices }) => {
      addonServices.forEach(({ serviceId }) => {
        addonsDictionary[serviceId] = _id;
      });
    });

    if (!appointment?.editService) { // do not preselect service when user is attempting to edit appointment service
      const serviceId = addonsDictionary[appointmentServiceId] || appointmentServiceId;
      const selectedService = getters.services.find(({_id}) => _id === serviceId);
      commit('setSelectedService', selectedService);

      if (getters.addons) {
        const addon = (getObjProperty(getters, 'addons') || []).find(({_id}) => {
          if (serviceId !== appointmentServiceId) {
            return _id === appointmentServiceId;
          }
          return !_id; // self dry
        });
        commit('setSelectedAddon', addon);
      }
    }

    if (appointment?.reservation?.appointment?.addOnTreatments?.length) { // Don't forget the addOnTreatments
      commit('setSelectedAddOnTreatments', appointment?.reservation?.appointment?.addOnTreatments);
    }
    if (appointment?.editService) {
      commit('setSelectedDate', appointment.date);
      commit('setSelectedTime', appointment.time);
    } else {
      commit('setSelectedDate', null);
      commit('setSelectedTime', null);
    }

    const { notes, longHair } = getNotesFromComments(appointment.comments);
    commit('setNotes', notes);
    commit('setLongHair', longHair);

    const codes = appointment.promos.all.map(({ code }) => code);
    await dispatch('refreshPromos', codes);

    if (getters.colorbarMemberOrInCart) {
      await dispatch('refreshPromos'); // For unlimited membership
    }
  },

  async setSelectedService({ state, commit, dispatch }, service) {
    const { applyingPromos } = state;
    if (applyingPromos) {
      return;
    }
    if (service && service.isStandIn) {
      commit('setSelectedService', service);
      return;
    }
    let addOnTreatments = [];
    if (service?.addOnTreatments?.length && state.addOnTreatments?.length) {
      state.addOnTreatments.forEach((a) => {
        if (service.addOnTreatments.some((s) => s.code === a.code)) {
          addOnTreatments.push(a);
        }
      });
    } else if (state.selectedAddOnTreatments) {
      addOnTreatments = state.selectedAddOnTreatments;
    }
    // when user is editing service do not reset date and time here so that we can attempt to keep the same date and time
    if (!state.editingService) {
      commit('setSelectedDate', null);
      commit('setSelectedTime', null);
    }
    commit('setSelectedAddon', null);
    commit('setSelectedService', service);
    commit('setSelectedAddOnTreatments', addOnTreatments);
    await dispatch('refreshPromos');
  },

  async setSelectedAddon({ state, commit, dispatch }, addon) {
    const { applyingPromos } = state;
    if (applyingPromos) {
      return;
    }
    commit('setSelectedDate', null);
    commit('setSelectedTime', null);
    commit('setSelectedAddon', addon);
    await dispatch('refreshPromos');
  },

  async makeSelectedAddOnTreatments({ state, commit, dispatch }, addOnTreatments) {
    const { applyingPromos } = state;
    if (applyingPromos) {
      return;
    }
    commit('setSelectedDate', null);
    commit('setSelectedTime', null);
    commit('setSelectedAddOnTreatments', addOnTreatments);
    await dispatch('refreshPromos');
  },

  async refreshPromos({ commit, state, rootState }, codes) {
    commit('setApplyingPromos', true);
    const {
      allPromos,
      location,
      selectedAddon,
      selectedAddOnTreatments,
      selectedService,
      selectedDate,
      editAppointment,
      selectedTime,
    } = state;
    const promoCodes = codes || allPromos.map(({ code }) => code);

    let appointmentDate = null;
    if (selectedDate) {
      appointmentDate = getDateFromCalDate(selectedDate.calDate).toISOString();
    }

    let serviceId = getObjProperty(selectedService, '_id');
    if (selectedAddon && selectedAddon._id) {
      serviceId = selectedAddon._id;
    }

    if (!serviceId) {
      commit('setApplyingPromos', false);
      return;
    }

    let startTime, endTime;
    if (selectedTime) {
      startTime = selectedTime.time;
      endTime = selectedTime.endTime;
    }

    const addOnTreatmentIds = selectedAddOnTreatments?.map(s => s._id);

    const payload = {
      locationId: location._id,
      promoCodes,
      customerId: getObjProperty(rootState, 'customer.cdata.id'),
      serviceId,
      addOnTreatments: addOnTreatmentIds,
      appointmentDate,
      startTime,
      endTime,
      reservationId: editAppointment ? editAppointment._id : null,
      cart: getObjProperty(rootState, 'cart.cart')
    };

    try {
      const { data } = await vueColorbarSvc.applyServicePromo(payload);
      const {
        promoErrors = [],
        items,
        totals: {
          appliedPromos,
          totalBeforeTax,
          preDiscountSubTotal,
          promos: {
            itemDiscounts
          } = {}
        } = {} } = data;

      commit('setAppliedPromos', appliedPromos || []);
      commit('setAllPromos', appliedPromos.filter(({ code, type }) => code || type === 'sitewidePromo'));
      commit('setDisplaySubtotal', totalBeforeTax);
      commit('setPreDiscountSubtotal', preDiscountSubTotal);
      commit('setDiscounts', matchItemsWithDiscounts(items, itemDiscounts));
      commit('setApplyingPromos', false);
      commit('setPromoErrors', promoErrors.map(({ message }) => message));
    } catch (error) {
      commit('setApplyingPromos', false);
      commit('setPromoErrors', ['The promo code is invalid.']);
    }
  },

  async cancelAppointment({ commit, state }, { appointment, reason }) {
    const payload = {
      id: appointment._id,
      reason: reason,
    };

    await vueColorbarSvc.cancelAppointment(payload);
    const { upcomingAppointments } = state;
    const remainingUpcomingAppointments = upcomingAppointments.filter(({ _id }) => _id !== appointment._id);

    commit('setUpcomingAppointments', remainingUpcomingAppointments);
  },

  async autoCompleteServiceAndAddon({ state, dispatch, getters, commit }, params) {
    const serviceId = parseInt(params.serviceId, 10);
    const addOnTreatmentCodes = params.addOnTreatments?.split(',') || null;
    const { servicesWithStandIns, servicesDictionary, consultationServices } = getters;

    // If nothing to compare to return
    if (!servicesWithStandIns && !consultationServices) {
      return;
    }

    let servicesWithStandInsAndConsultations = [];
    servicesWithStandInsAndConsultations = servicesWithStandInsAndConsultations.concat(servicesWithStandIns, consultationServices);

    if (!servicesWithStandInsAndConsultations) {
      return;
    }

    let selectedService;
    for (const service of servicesWithStandInsAndConsultations) {
      // BASIC SERVICE WITHOUT ADDON (SELF DRY)
      if (service._id === serviceId) {
        selectedService = service;
        break;
      }

      // BASIC SERVICE WITH ADDON (BLOW OUT)
      if (service.addonServices) {
        const isAddonForThisService = service.addonServices.some((addon) => addon.serviceId === serviceId);
        if (isAddonForThisService) {
          selectedService = service;
          break;
        }
      }

      // SERVICE WITH STAND IN
      if (service.standInFor) {
        const standInBaseService = service.standInFor
          .filter((code) => !!servicesDictionary[code])
          .map((code) => servicesDictionary[code])
          .find(({ _id, addonServices }) => {
            return _id === serviceId || addonServices.some((addon) => addon.serviceId === serviceId);
          });
        if (standInBaseService) {
          selectedService = standInBaseService;
          break;
        }
      }
    }

    if (!selectedService || !servicesWithStandIns.some(s => s._id == selectedService.id)) {
      throw new Error("Please select a service to continue.");
    }
    await dispatch('setSelectedService', selectedService);
    let selectedAddOnTreatments = [];
    if (addOnTreatmentCodes?.length) {
      if (state.addOnTreatments.length) {
        state.addOnTreatments.map((t) => {
          if (addOnTreatmentCodes.includes(t.code)) {
            selectedAddOnTreatments.push(t);
          }
        });
      }
    }

    const { addons } = getters;

    if (!addons) {
      return;
    }

    const selectedAddon = addons.find((addon) => {
      // self dry
      if (selectedService._id === serviceId) {
        return !addon._id;
      }

      return addon._id === serviceId;
    });

    if (!selectedAddon) {
      return;
    }
    commit('setSelectedAddOnTreatments', selectedAddOnTreatments);
    await dispatch('setSelectedAddon', selectedAddon);
    await dispatch('refreshPromos');
  },

  async preSaveBookingInfo({ state, rootState }) {
    const {
      location,
      selectedService,
      selectedAddOnTreatments,
      selectedDate,
      editAppointment,
      selectedTime,
      notes,
      longHair,
    } = state;

    let comments = getComments(notes, longHair);
    if (editAppointment) {
      comments = getObjProperty(editAppointment, 'reservation.notes');
    }

    const payload = {
      customerId: getObjProperty(rootState, 'customer.cdata.id'),
      email: getObjProperty(rootState, 'customer.cdata.email'),
      locationId: location._id,
      date: selectedDate.calDate,
      time: selectedTime,
      comments,
      bookingServiceId: selectedService._id,
      selectedAddOnTreatments: selectedAddOnTreatments,
      extraInfo: {
        notes,
        longHair,
      },
      location,
      codes: { code: null }, // this is always null
    };

    await vueColorbarSvc.preSaveBookingInfo(payload);
  },

  async createAppointment({ state, getters, rootState }) {
    const { paymentRequired, consultationServicesByTreatmentKey, nonConsultationServicesByTreatmentKey, consultationAddonsByTreatmentKey, nonConsultationAddonsByTreatmentKey } = getters;
    const {
      location,
      selectedService,
      selectedAddon,
      selectedAddOnTreatments,
      selectedDate,
      selectedTime,
      hold,
      notes,
      longHair,
      allPromos,
      appliedPromos,
      displaySubtotal,
      editAppointment,
      gclid,
    } = state;
    const includesColorWonder = this?._vm.$cookies.get('includesColorWonder') == 1;

    const promosDetail = {
      all: [],
      extracted: [],
    };
    if (appliedPromos) {
      promosDetail.displaySubtotal = displaySubtotal;
      promosDetail.extracted = extractDiscounts(appliedPromos);
      promosDetail.all = allPromos;
    }

    let paymentProfileId = null;
    if (paymentRequired) {
      paymentProfileId = getObjProperty(rootState, 'payments.selectedProfileId');
    }

    let serviceId = (selectedAddon && selectedAddon._id) || selectedService._id;
    let service = location.servicesOffered.find(service => service._id === serviceId); // raw service information
    let treatmentKey = service.treatmentsIncluded
      .filter(treatment => !treatment.isConsultation)
      .map(treatment => '' + treatment.id + treatment.applicationTime)
      .sort()
      .join(',');
    let serviceHasConsultation = !!(consultationServicesByTreatmentKey[treatmentKey] || consultationAddonsByTreatmentKey[treatmentKey]);
    let lastAppointment = getObjProperty(rootState, 'customer.cdata.lastAppointment');
    let customerHasHadAnAppointment = lastAppointment && lastAppointment.locationCode;

    // ensure customer is booking a non consultation service if they have already had an appointment
    if (customerHasHadAnAppointment && serviceHasConsultation) {
      if (nonConsultationServicesByTreatmentKey[treatmentKey]) {
        service = nonConsultationServicesByTreatmentKey[treatmentKey];
      } else if (nonConsultationAddonsByTreatmentKey[treatmentKey]) {
        service = nonConsultationAddonsByTreatmentKey[treatmentKey];
      }
    }

    const customer = {
      id: getObjProperty(rootState, 'customer.cdata.id'),
      first_name: getObjProperty(rootState, 'customer.cdata.firstName'),
      last_name: getObjProperty(rootState, 'customer.cdata.lastName'),
      phone_mobile: getObjProperty(rootState, 'customer.cdata.phoneMobile'),
      email: getObjProperty(rootState, 'customer.cdata.email'),
    };

    selectedDate.jsDate = getDateFromCalDate(selectedDate.calDate).toISOString();
    const addOnTreatmentsById = selectedAddOnTreatments?.map(s => s.code === 'boosts' ? 41 : s._id);
    const apptData = {
      location,
      service,
      addOnTreatments: addOnTreatmentsById,
      bookingServiceId: selectedService._id,
      date: selectedDate,
      time: selectedTime,
      customer,
      comments: getComments(notes, longHair, includesColorWonder),
      hold,
      promos: allPromos.map(({ code }) => code),
      promosDetail,
      paymentProfileId,
      phone_mobile: getObjProperty(rootState, 'customer.cdata.phoneMobile'),
      gclid,
      isMinor: getObjProperty(rootState, 'customer.isMinor'),
    };

    if (editAppointment) {
      apptData._id = getObjProperty(editAppointment, 'reservation._id');
      apptData.created_at = getObjProperty(editAppointment, 'reservation.created_at');
      apptData.created_by = getObjProperty(editAppointment, 'reservation.created_by');
    }

    let res = await vueColorbarSvc.createAppointment({ apptData, useAlternateService: true });
    return res;
  },

  async createHoldIncludeAlternates({ state, dispatch, commit }, payload) {
    const {
      location,
      selectedService,
      selectedAddon,
      selectedAddOnTreatments,
    } = state;

    const {
      cdata,
      selectedDate,
      selectedTime,
    } = payload;

    let serviceId = selectedService._id;
    if (selectedAddon && selectedAddon._id) {
      serviceId = selectedAddon._id;
    }

    const { data: hold } = await vueColorbarSvc.createHoldIncludeAlternates({
      locationId: location._id,
      resourceId: selectedTime.resourceId,
      splitResourceId: selectedTime.splitResourceId,
      serviceId,
      selectedAddOnTreatments,
      date: selectedDate.calDate,
      time: selectedTime.time,
      ownerId: cdata.dug,
    });

    if (!hold.result) {
      throw new Error('Slot not available');
    }

    commit('setSelectedDate', selectedDate);
    commit('setSelectedTime', selectedTime);
    commit('setHold', hold);
    await dispatch('refreshPromos');
  },

  async cleanAppointmentProgressFromSession({ state }) {
    const {
      longHair,
      notes,
    } = state;
    const appointmentProgress = {
      locationCode: null,
      selectedServiceCode: null,
      selectedAddonCode: null,
      selectedAddOnTreatments: [],
      selectedTime: null,
      selectedDate: null,
      hold: null,
      codes: null,
      notes,
      longHair,
      selfDry: null,
    };
    await vueColorbarSvc.saveAppointmentProgressToSession({ appointmentProgress });
  },

  async saveAppointmentProgressToSession({ state }) {
    const {
      location,
      selectedService,
      selectedAddon,
      selectedAddOnTreatments,
      selectedTime,
      selectedDate,
      hold,
      allPromos,
      notes,
      longHair,
    } = state;

    const appointmentProgress = {
      locationId: location._id,
      locationCode: location.code,
      selectedServiceCode: selectedService ? selectedService.code : null,
      selectedAddonCode: selectedAddon ? selectedAddon.code : null,
      selectedAddOnTreatments: selectedAddOnTreatments,
      selectedTime,
      selectedDate,
      hold,
      codes: allPromos.map(({ code }) => code),
      notes,
      longHair,
      selfDry: selectedAddon && !selectedAddon.code,
    };

    await vueColorbarSvc.saveAppointmentProgressToSession({ appointmentProgress });
  },

  async loadAppointmentProgressFromSession({ dispatch, commit, state, getters, rootState }) {
    const { data: appointmentProgress } = await vueColorbarSvc.loadAppointmentProgressFromSession();

    if (!appointmentProgress) {
      return;
    }

    const { location } = state;

    const {
      selectedServiceCode,
      selectedAddonCode,
      selectedAddOnTreatments,
      selectedTime,
      selectedDate,
      hold,
      codes,
      longHair,
      notes,
      selfDry,
    } = appointmentProgress;

    let selectedService;
    let selectedAddon;

    if (selectedServiceCode && getters.servicesDictionary) {
      selectedService = getters.servicesDictionary[selectedServiceCode];
      // no bookeable service
      if (selectedService && selectedService.callToBook) {
        return;
      }
      commit('setSelectedService', selectedService);
    }

    if (selfDry && getters.addonsDictionary) {
      selectedAddon = getters.addonsDictionary[undefined];
      commit('setSelectedAddon', selectedAddon);
    }

    if (selectedAddonCode && getters.addonsDictionary) {
      selectedAddon = getters.addonsDictionary[selectedAddonCode];
      commit('setSelectedAddon', selectedAddon);
    }

    if (selectedAddOnTreatments) {
      commit('setSelectedAddOnTreatments', selectedAddOnTreatments);
    }

    const ownerId = getObjProperty(rootState, 'customer.cdata.dug');
    if (hold && ownerId) {
      try {
        let serviceId;
        if (selectedService && selectedService._id) {
          serviceId = selectedService._id;
        }
        if (selectedAddon && selectedAddon._id) {
          serviceId = selectedAddon._id;
        }
        const { data } = await vueColorbarSvc.getIfHoldIsValid({
          locationId: location._id,
          resourceId: hold?.resourceId,
          splitResourceId: hold?.splitResourceId,
          serviceId,
          selectedAddOnTreatments,
          date: selectedDate.calDate,
          ownerId,
        });

        if (data.valid) {
          commit('setSelectedDate', selectedDate);
          commit('setSelectedTime', selectedTime);
          commit('setHold', hold);
        }
      } catch (error) {
        // empty
      }
    }

    if (selectedServiceCode && selectedService) {
      if (codes) {
        await dispatch('refreshPromos', codes);
      }

      if (getters.colorbarMemberOrInCart) {
        await dispatch('refreshPromos'); // For unlimited membership
      }
    }

    commit('setLongHair', longHair);
    commit('setNotes', notes);
  },

  async getServiceDiscountForPromos({ commit, state }) {
    const { selectedService, selectedAddon, selectedAddOnTreatments, discounts } = state;
    const { service, addon, addOnTreatments } = await applyServicePromos(selectedService, selectedAddon, selectedAddOnTreatments, discounts);
    commit('setSelectedAddon', addon);
    commit('setSelectedService', service);
    commit('setSelectedAddOnTreatments', addOnTreatments);
  },

  async getClientUsageWindow({ commit }) {
    const { data: windowData } = await vueUrmSvc.getClientUsageWindow();

    commit('setFreeRootsWindowData', windowData);
  },

  openServiceMoreDetailsModal({ dispatch, rootState }, params) {
    let payload = {
      component: 'ServiceMoreDetailsModal',
      props: {
        service: params.service,
        onSelect: params.onSelect,
      },
      theme: 'app-modal-xxsmall xs-f-small max-at-tweak',
    };

    if (rootState.global.experiments["Booking flow services redesign 2024"] === 'B') {
      payload.component = 'ServiceMoreDetailsV2Modal';
      payload.theme = 'booking-learn-more reset-padding app-modal-small';
    }

    dispatch('modal/showModal', payload, { root: true });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};