export function getBrowserTrackingContext() {
  let path, pageTitle, pageName;
  path = window.location.pathname.split('/').slice(1);
  pageTitle = path.pop() || 'Home Page';
  path.push(pageTitle);
  pageName = path.join(' ');
  path = path.join('/');

  return {
    pageName,
    title: pageTitle,
    path: path,
    url: window.location.href,
    search: window.location.search,
    referrer: document.referrer
  };
}