import isEqual from 'lodash.isequal';
import dayjs from '@dayjs';
import InApp from 'detect-inapp';

const WEEKDAYS = dayjs.weekdays();

// this function returns true if the event has a key code
// for tab, shift, or an arrow key
export function specialKey($event) {
  var keyCode = $event.keyCode;
  if (keyCode == 9 || keyCode == 16 || keyCode == 19 || keyCode == 37 || keyCode == 38 || keyCode == 39 || keyCode == 40) {
    return true;
  }
}

export function isSameAddress(addressOne, addressTwo, extraFields) {
  if (!addressOne || !addressTwo || !isObject(addressOne) || !isObject(addressTwo)) {
    return false;
  }
  // If addressOne fields different from addressTwo set flag false
  var keys = ['first_name', 'last_name', 'street_1', 'street_2', 'post_code', 'city', 'region'];
  if (extraFields && Array.isArray(extraFields)) {
    keys = [...keys, ...extraFields];
  }

  for (var i = 0; i < keys.length; i++) {
    var key = keys[i];
    if ((addressOne[key] || '').toLowerCase() != (addressTwo[key] || '').toLowerCase()) {
      return false;
    }
  }

  return true;
}

export function paymentProfileClass(paymentGateway, ccType) {
  let pClass = 'card-logo';

  function createClass(string) {
    return `${string.toLowerCase().replace(/\s/g,'')}-logo-svg`;
  }

  if (paymentGateway == 'braintree' && ccType && ccType.length) {
    pClass = createClass(ccType);
  } else if (paymentGateway) {
    pClass = createClass(paymentGateway);
  }

  return pClass;
}

export function getShortDateString(date) {
  date = new Date(date);
  var m = ('0' + (date.getMonth() + 1)).substr(-2);
  var d = ('0' + date.getDate()).substr(-2);

  return date.getFullYear() + '-' + m + '-' + d;
}

export function shuffleArray(array) {
  if (array && Array.isArray(array)) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
}

export function isNum(value) {
  return (typeof value === 'number' || (typeof value === 'string' && /^[0-9,.]+$/.test(value)) || value instanceof Number);
}

/**
 Simple copy of an object
 **/
export function deepCopyObj(obj) {
  var theCopy = {};
  var val;

  Object.keys(obj).forEach(function(key) {
    val = obj[key];
    if (isObject(val)) {
      theCopy[key] = deepCopyObj(val);
    } else if (Array.isArray(val)) {
      theCopy[key] = copyArray(val);
    } else {
      theCopy[key] = val;
    }
  });

  return theCopy;
}

export function isObject(theThing) {
  return Object.prototype.toString.call(theThing) === '[object Object]';
}

/**
 * Copies an array, not deep
 * @param  {Array} arr The array to copy
 * @return {Array}     The copied array
 */
export function copyArray(arr) {
  var i = 0;
  var l = arr.length;
  var newArr = [];

  for (; i < l; i++) {
    newArr[i] = Array.isArray(arr[i]) ? copyArray(arr[i]) :
      toString.call(arr[i]) === '[object Object]' ? deepCopyObj(arr[i]) :
        arr[i];
  }

  return newArr;
}

export function getObjProperty() {
  var obj = Array.prototype.shift.call(arguments);
  const value = safeGetProperty.apply(obj, arguments);

  return value;
}

export function getObjPropertyCatchUndefined() {
  var obj = Array.prototype.shift.call(arguments);
  const value = safeGetProperty.apply(obj, arguments);

  if (value === undefined && process.env.NODE_ENV !== 'test') {
    console.error(`Accessed undefined for key ${Array.prototype.join.call(arguments, '.')}`); // eslint-disable-line no-console
  }

  return value;
}

function safeGetProperty() {
  /* jshint validthis:true */
  var args = Array.prototype.map.call(arguments, function(arg) {
    return ('' + arg).split('.');
  });

  var all = Array.prototype.concat.apply([], args);

  var key = all.shift();
  let remainingKeys = [...all];
  var prop = (this || {})[key];

  // If the property is undefined but we have more keys, maybe there's a . in those keys...
  // keep trying until we run out of keys or find a property that matches
  while (remainingKeys.length && prop === undefined) {
    key += '.' + remainingKeys.shift();
    prop = (this || {})[key];
  }
  all = remainingKeys;

  if (prop === undefined || !all.length) {
    return prop;
  }

  return safeGetProperty.apply(prop, all);
}

export function elementInView(element) {
  var bounding = element.getBoundingClientRect();
  return bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth);
}

/**
 * Concatenates str with special characters to create a search string that can be fed into RegExp builder
 *
 * e.g.
 *
 * ("hello", "exact match") returns "^hello$"
 * @param {string} str
 * @param {string} matchType
 * @return {string} str
 */
export function applyMatchTypeSpecialCharacters(str, matchType) {
  switch (matchType) {
    case 'partial match':
      str = '.*' + str + '.*';
      break;
    case 'exact match':
      str = '^' + str + '$';
      break;
  }

  return str;
}

export function formatAppointmentTime(totalTimeRequired) {
  const totalTime = parseInt(totalTimeRequired);
  const parts = [];
  const hours = Math.floor(totalTime / 60);
  const minutes = totalTime % 60;

  if (hours) {
    parts.push(`${hours}hr${hours > 1 ? 's' : ''}`);
  }

  if (minutes) {
    parts.push(`${minutes}min`);
  }

  return parts.length ? parts.join(`\u00A0`) : null;
}

export function dateFromCalDate(calDate) {
  var dArr = calDate.toString().replace(/([0-9]{4})([0-9]{2})([0-9]{2})/, '$1-$2-$3').split('-');
  return new Date(parseInt(dArr[0]), parseInt(dArr[1]) - 1, parseInt(dArr[2]), 0, 0, 0);
}

export function getCollapsedLocationOpenHours(hours) {
  return hours.reduce((acum, current) => {
    const last = acum.slice(-1)[0];
    if (!last) {
      acum.push({
        weekdays: [current.weekday],
        open: current.open,
        hours: current.hours,
      });
      return acum;
    }
    if (!last.open && !current.open) {
      last.weekdays.push(current.weekday);
      return acum;
    }
    if (last.open !== current.open || !isEqual(last.hours, current.hours)) {
      acum.push({
        weekdays: [current.weekday],
        open: current.open,
        hours: current.hours,
      });
      return acum;
    }
    last.weekdays.push(current.weekday);
    return acum;
  }, []).map(block => {
    const firstDay = WEEKDAYS[block.weekdays.slice(0, 1)];
    const lastDay = WEEKDAYS[block.weekdays.slice(-1)];
    let text = firstDay;
    if (firstDay !== lastDay) {
      text += `-${lastDay}`;
    }
    return {
      text,
      open: block.open,
      hours: block.hours.map((block) => ({
        ...block,
        startTimeFormatted: block.startTime.replace(':00 ', '').toUpperCase(),
        endTimeFormatted: block.endTime.replace(':00 ', '').toUpperCase(),
      })),
    };
  });
}

export function applyOnScrollEvent(callback) {
  let passiveIfSupported = false;
  try {
    window.addEventListener("test", null,
      Object.defineProperty(
        {},
        "passive",
        {
          // eslint-disable-next-line
          get: function() {
            passiveIfSupported = { passive: true };
          }
        }
      )
    );
  } catch (err) {
    // empty
  }

  let options = false;
  if (passiveIfSupported) {
    options = { passive: true };
  }
  window.addEventListener('scroll', callback, options);
  return callback;
}

export function isInAppBrowser() {
  if (!navigator || !window) {
    return false;
  }
  const inApp = new InApp(navigator.userAgent || navigator.vendor || window.opera);
  return !!inApp.isInApp;
}

export function getUrlParams() {
  var search = window.location.search;
  if (!search) {
    //- need to support some old angular redirect urls that contain hash (for now)
    let hash = window.location.hash;

    if (!hash) {
      return {};
    }

    search = hash.replace(/^#/, '');
  }

  var params = {};
  search
    .replace(/^\?/, '')
    .split('&')
    .forEach(function (paramStr) {
      var part = paramStr.split('=');
      params[part[0]] = part[1];
    });
  return params;
}

/**
 * Checks if the env is client/server
 * @return {boolean}
 */
export function isClient() {
  return typeof window !== 'undefined';
}

export function getFirstAndLastName(name) {
  let firstname, lastname;

  if (name) {
    const chunks = name.split(' ');

    if (chunks.length === 1) {
      firstname = chunks[0];
    } else {
      lastname = chunks.pop();
      firstname = chunks.join(' ');
    }
  }

  return { firstname, lastname };
}

/**
 * Converts a number to a fixed decimal
 * @param {number} num - The number to convert
 * @param {number} places - The number of decimal places desired
 * @returns {number}
 */
export function fixNum(num, places = 2) {
  return +(+num).toFixed(places);
}

/**
 * Returns whether two objects are deeply equal, will check every property for equality against the comparison object
 * @param {Object} obj The first object to compare
 * @param {Object} cmp The second object to compare
 * @return {Boolean} whether or not the objects are deep equal
 */
export function objIsDeepEqual(obj, cmp) {
  if (!isObject(obj) || !isObject(cmp)) {
    return false;
  }

  function toString(obj) {
    return Object.prototype.toString.call(obj);
  }

  if (Object.keys(obj).length != Object.keys(cmp).length) {
    return false;
  }

  return !Object.keys(obj).some(key => {
    if (toString(obj[key]) != toString(cmp[key])) {
      return true;
    }

    if (toString(obj[key]) == '[object Object]' || toString(obj[key]) == '[object Array]') {
      return !objIsDeepEqual(obj[key], cmp[key]);
    }

    return cmp[key] !== obj[key];
  });
}
