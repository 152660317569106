<template lang="pug">
  .site-message-banner-carousel.container(v-if="hasMessages"  :class="currentThemes")
    .row.color-white.xs-f-small.lg-f-medium.max-at-tweak
      .fixed-width-tweak-upper
        slick.site-message-carousel.xs-12(ref="carousel" v-bind="carouselOptions" @beforeChange="handleBeforeChange")
          .site-message-wrap(v-for="(message, index) in sortedMessages"  :key="index"  :ref="'message-' + index")
            .site-message-wrap-inner(v-if="message.use_cms_partial && message.html && message.css")
              CMSPartial(:init-html="message.html"  :init-css="message.css")

            .site-message-wrap-inner(v-else)
              component(:is="messageComponents[index]"  :class="messageThemes(message)")

              .color-therapy-image(v-if="topicsPostfixesForMessage(message).includes('colortherapy')"  :class="messageThemes(message)")
                .a-box-xs-1x1
                  .a-box-content
                    img-box(media-id="7212")

              .allure-image(v-if="topicsPostfixesForMessage(message).includes('allure')"  :class="messageThemes(message)")
                .a-box-xs-12x5
                  .a-box-content
                    img-box(media-id="7406")

              .mobileapp-image(v-if="topicsPostfixesForMessage(message).includes('mobileapp')"  :class="messageThemes(message)")
                .a-box-xs-4x3
                  .a-box-content
                    img-box(media-id="7761")

              .great-cover-up-image(v-if="topicsPostfixesForMessage(message).includes('tgcu')"  :class="messageThemes(message)")
                .a-box-xs-1x1
                  .a-box-content
                    img-box(media-id="8096")

              .message-image(v-if="topicsPostfixesForMessage(message).includes('videoconsultation')"  :class="messageThemes(message)")
                .a-box-xs-3x4
                  .a-box-content
                    img-box(media-id="8152")

              .pura-image(v-if="topicsPostfixesForMessage(message).includes('pura')"  :class="messageThemes(message)")
                .a-box-xs-3x4
                  .a-box-content
                    img-box(media-id="8475")

        button.left-nav.btn-reset.text-right.clickable(type="button" @click="prev" v-if="mutlipleMessages")
          span.icon-caret-left.xs-f-large.sm-f-xlarge.max-at-tweak
            span.ada-tooltip previous

        button.right-nav.btn-reset.text-left.clickable(type="button" @click="next" v-if="mutlipleMessages")
          span.icon-caret-right.xs-f-large.sm-f-xlarge.max-at-tweak
            span.ada-tooltip next

</template>

<script>
  import vueSiteMessageSvc from '@services/vueSiteMessageSvc';
  import CMSPartial from '@components/CMSPartial';
  import { getObjProperty } from "@utilities/mrVueUtils";

  export default {
    name: 'SiteMessageBannerCarousel',

    components: {
      CMSPartial
    },

    props: {
      topics: {
        type: String,
        default: undefined,
      },
      useUtmTopic: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        messages: null,
        currentIndex: 0,
        carouselOptions: {
          autoplay: true,
          autoplaySpeed: 4000,
          speed: 1500,
          cssEase: 'ease-in-out',
          infinite: true,
          dots: false,
          prevArrow: false,
          nextArrow: false,
          slidesToShow: 1,
          arrows: false,
        },
      };
    },

    computed: {
      utmTopic() {
        if (!this.useUtmTopic) {
          return null;
        }

        if (this.$route.query.utm_carousel_banner_topic) {
          return this.$route.query.utm_carousel_banner_topic;
        } else if (this.$cookies.get('utm_carousel_banner_topic')) {
          return this.$cookies.get('utm_carousel_banner_topic');
        }

        return null;
      },

      allTopics() {
        let topics = [];

        if (this.utmTopic) {
          topics.push(this.utmTopic);
        }

        if (this.topics) {
          let topicsArray = this.topics.split(',');

          topicsArray.forEach(t => {
            t = t.toLowerCase();
            topics.push(t);
          });
        }

        return topics.join(',');
      },

      hasMessages() {
        return !!(this.messages && this.messages.length && Array.isArray(this.messages));
      },

      mutlipleMessages() {
        return this.hasMessages && this.messages.length > 1;
      },

      sortedMessages() {
        let messages = [];

        if (this.hasMessages) {
          let importanceMap = {
            high: 1,
            medium: 2,
            low: 3,
          };

          messages = [...this.messages].sort((a, b) => {
            if (this.utmTopic) {
              if (a.topics.includes(this.utmTopic)) {
                return -1;
              }
              if (b.topics.includes(this.utmTopic)){
                return 1;
              }
            }
            if (a.importance != b.importance) {
              return importanceMap[a.importance] - importanceMap[b.importance];
            }

            let aStart = new Date(a.start_date);
            let bStart = new Date(b.start_date);

            return +bStart - +aStart;
          });
        }

        return messages;
      },

      messageComponents() {
        let components = this.sortedMessages.map(message => {
          return {
            template: `<div class='site-message'>${(message.message || '').replace(new RegExp(String.fromCharCode(160), "g"),'\xa0')}</div>`,
          };
        });
        return components;
      },

      currentMessage() {
        let current = null;

        if (this.hasMessages && this.sortedMessages[this.currentIndex]) {
          current = this.sortedMessages[this.currentIndex];
        }

        return current;
      },

      currentThemes() {
        if (this.currentMessage) {
          return this.messageThemes(this.currentMessage);
        } else {
          return {};
        }
      },
    },

    mounted() {
      if (!this.allTopics) {
        return;
      }

      let utmTopic = getObjProperty(this, '$route.query.utm_carousel_banner_topic');
      if (utmTopic) {
        this.$cookies.set('utm_carousel_banner_topic', utmTopic, '2h');
      }

      vueSiteMessageSvc.getByTopics({ topics: this.allTopics }).then(res => {
        this.messages = res.data;
      });
    },

    methods: {
      handleBeforeChange(currentIndex, nextIndex) {
        this.currentIndex = nextIndex;
      },

      next() {
        this.$refs.carousel.next();
      },

      prev() {
        this.$refs.carousel.prev();
      },

      messageThemes(message) {
        if (message.use_cms_partial && message.html) {
          return {'cms-partial': true};
        }
        let themes = {};
        let topics = message.topics.split(',');

        topics.forEach(topic => {
          let topicParts = topic.split('-');
          themes[topicParts[topicParts.length - 1]] = true;
        });

        return themes;
      },

      topicsPostfixesForMessage(message) {
        return ((message || {}).topics + '').split(',').map(topic => {
          let topicParts = topic.split('-');
          return topicParts[topicParts.length - 1];
        });
      },
    },
  };
</script>

<style scoped lang="stylus">
  .site-message-banner-carousel
    background-color color-mr-purple
    transition(background-color 1500ms ease-in-out\, opacity 1500ms ease-in-out)
    color color-white
    text-align center

    //- this will vertically center site messages,
    //- usefull when one banner is taller then others
    >>>.slick-track
      display flex
      align-items center

    .slick-slider
      margin 0

      *
        vertical-align bottom

    .site-message-carousel
      position relative
      overflow hidden
      margin-bottom 0
      min-height 3.3em

      .site-message-wrap
        overflow hidden

      .site-message-wrap-inner
        position relative
        width 100%
        line-height 1.4em

    .site-message
      display inline-block
      padding 0em 5%

      &.men
        text-align center

      &.allure
        padding-left 1em
        padding-right 40%
        color color-mr-black
        text-align left

      &.colortherapy
        padding-left 3em
        padding-right 33%
        color color-mr-black
        text-align left

      &.mobileapp
        padding-left 3em
        padding-right 33%
        color color-mr-black
        text-align left

      &.videoconsultation
        padding-left 3em
        padding-right 39%
        color color-mr-black
        text-align left

      &.tgcu
        padding-left 2em
        padding-right 33%
        color color-mr-black
        text-align left

      &.pura
        padding-left 0

      &.holidays
        width 120%
        margin-left -10%
        background-image url('https://mr-cdn.imgix.net/content/images/2021/9/kvbake87-rcd-stars-dsk/rcd-stars-dsk.png?w=800')
        background-repeat no-repeat
        background-size 100%
        background-position-x center

      &.holidaystars
        width 160%
        margin-top 1.5em
        margin-left -29%
        background-image url('https://mr-cdn.imgix.net/content/images/2021/10/kvjv9s5n-homepage-pencil-banner-stars-dsk/homepage-pencil-banner-stars-dsk.png?w=900')
        background-repeat no-repeat
        background-size 100%
        background-position-x center

      &.allure
      &.colortherapy
      &.mobileapp
      &.videoconsultation
      &.tgcu
      &.holidays
      &.holidaystars
      &.pura
        min-height 7em

      &.allure
      &.colortherapy
      &.mobileapp
      &.videoconsultation
      &.tgcu
      &.pura
        padding-top 1em
        padding-bottom 1em

      >>> br
        display none

    .color-therapy-image
    .allure-image
    .mobileapp-image
    .message-image
    .great-cover-up-image
    .pura-image
      display none
      opacity 0
      transition(opacity 1500ms ease-in-out)

    .left-nav
    .right-nav
      position absolute
      top 0
      bottom 0
      width 4em

      .icon-caret-left
      .icon-caret-right
        position absolute
        left 0
        right 0
        width 100%
        display inline-block
        top 50%
        margin-top -0.75em
        color color-white

    .left-nav
      left 0
      text-align left

    .right-nav
      right 0
      text-align right

    .color-therapy-image
      &.colortherapy
        position absolute
        display block
        opacity 100
        top -1em
        right -5em
        width 13em

    .mobileapp-image
      &.mobileapp
        position absolute
        display block
        opacity 100
        top 0em
        right -3em
        width 10em

    .great-cover-up-image
      &.tgcu
        position absolute
        display block
        opacity 100
        top -1em
        right -2em
        width 8em

    .message-image
      &.videoconsultation
        position absolute
        display block
        opacity 100
        top 0em
        right -3em
        width 10em

    .allure-image
      &.allure
        position absolute
        display block
        opacity 100
        z-index -1
        top 0
        right -5em
        width 20em

    .pura-image
      position absolute
      display block
      opacity 100
      z-index -1
      top 0.2em
      right 0
      width 4.5em

    &.men
      background-color color-mister-dark-gray

    &.colortherapy, &.mobileapp, &.videoconsultation, &.tgcu, &.holidays, &.pura
      background-color #f5f5f5

      .left-nav
      .right-nav
        .icon-caret-left
        .icon-caret-right
          color #a9a9a9

    &.allure
      background-color #f7f6f9
      .left-nav
      .right-nav
        .icon-caret-left
        .icon-caret-right
          color #a9a9a9

    @media mq-mobile
      .site-message-carousel
      .site-message-wrap
        overflow hidden

      .site-message
        &.allure
          padding-left 2em
          padding-right 26%

        &.holidays
          width 90%
          margin-left 5%
          margin-right 5%
          padding-left 2em
          padding-right 2em

        &.holidaystars
          background-image url('https://mr-cdn.imgix.net/content/images/2021/10/kvjs7ird-homepage-pencil-banner-stars-mob-2x/homepage-pencil-banner-stars-mob-2x.png')
          width 90%
          margin-left 5%
          margin-right 5%
          padding-left 2em
          padding-right 2em

        &.pura
          padding-right 7em
          padding-left 2.2em

      .allure-image
        &.allure
          right -8em

      .mobileapp-image
        &.mobileapp
          right -3em
          width 12em

      .great-cover-up-image
        &.tgcu
          right -1em
          width 9em

      .message-image
        &.videoconsultation
          right 2.3em
          width 7em
          top -0.3em

      .pura-image
        right 1.7em
        top 0.6em

    @media mq-tablet
      .site-message-carousel
      .site-message-wrap
        padding 0 10%

      .message-image
        &.videoconsultation
          top -1em
          right -1em
          width 8em

      .site-message
        &.colortherapy, &.mobileapp, &.tgcu
          padding-left 0
          padding-right 20%

        &.videoconsultation
          padding-left 0
          padding-right 39%

        &.holidaystars
          background-image url('https://mr-cdn.imgix.net/content/images/2021/10/kvjs7ird-homepage-pencil-banner-stars-mob-2x/homepage-pencil-banner-stars-mob-2x.png')
          width 90%
          margin-left 5%
          margin-right 5%
          padding-left 2em
          padding-right 2em

    @media mq-desktop-md
      .site-message-wrap
        padding 0 20%

      .left-nav
        left 7%

      .right-nav
        right 7%

      .site-message
        &.colortherapy, &.mobileapp, &.tgcu
          padding-left 0
          padding-right 20%

        &.videoconsultation
          padding-left 0
          padding-right 39%

        &.holidaystars
          padding-top 1.5em
          width 137%
          margin-left -17%
          margin-right 5%
          padding-left 2em
          padding-right 2em

      .message-image
        &.videoconsultation
          top -1em

    @media mq-desktop-plus
      .site-message-wrap
        padding 0 25%

      .left-nav
        left 12.5%

      .right-nav
        right 12.5%

      .site-message
        &.colortherapy, &.mobileapp, &.tgcu
          padding-left 0
          padding-right 20%

        &.videoconsultation
          padding-left 0
          padding-right 39%

    &.cms-partial
      background-color transparent

      .site-message-wrap
        padding 0
        vertical-align top

</style>
