<script>
  export default {
    name: `TransitionExpand`,
    render(createElement) {
      const data = {
        props: {
          name: `expand`,
        },
        on: {
          afterEnter(element) {
            element.style.height = `auto`;
          },
          enter(element) {
            const { width } = getComputedStyle(element);
            element.style.width = width;
            element.style.position = `absolute`;
            element.style.visibility = `hidden`;
            element.style.height = `auto`;

            const { height } = getComputedStyle(element);
            element.style.width = null;
            element.style.position = null;
            element.style.visibility = null;
            element.style.height = 0;
            // Force repaint to make sure the
            // animation is triggered correctly.
            getComputedStyle(element).height;
            setTimeout(() => {
              element.style.height = height;
            });
          },
          leave(element) {
            const { height } = getComputedStyle(element);
            element.style.height = height;
            // Force repaint to make sure the
            // animation is triggered correctly.
            getComputedStyle(element).height;
            setTimeout(() => {
              element.style.height = 0;
            });
          },
        },
      };

      let renderEl = this.group ? `transition-group` : `transition`;

      //- IE does not support this type of transitions so we just ignore it
      if (/Trident\/|MSIE/.test(window.navigator.userAgent)) {
        renderEl = 'div';
      }
      return createElement(renderEl, data, this.$slots.default);
    },
  };
</script>

<style scoped lang="stylus">
  *
    will-change height
    transform translateZ(0)
    backface-visibility hidden
    perspective 1000px

</style>

<style lang="stylus">
  .expand-enter-active
  .expand-leave-active
    transition height 600ms ease-in-out
    overflow hidden

  .expand-enter,
  .expand-leave-to
    height 0

</style>
