export default {
  methods: {
    mix_trackNav(navName) {
      if (!navName || !navName.length) {
        return;
      }

      const eventName = `${navName.toLowerCase()}_viewed`;
      this.trackMREvent(eventName);
    },

    mix_trackItemClick(title) {
      const eventName = `Site Nav Click - ${title}`;
      this.trackMREvent(eventName);
    },

    mix_trackFTVNavViewed(title) {
      if (!title || !title.length) {
        return;
      }

      const eventName = `FTV Nav Viewed - ${title}`;
      this.trackMREvent(eventName);
    },

    mix_trackFTVNavClick(category, title) {
      if (!title || !title.length || !category || !category.length) {
        return;
      }
      const eventName = `FTV Nav ${category} - ${title} Clicked`;
      this.trackMREvent(eventName);
    }
  }
};
