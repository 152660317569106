const state = {
  cmsSettings: {}
};

const getters = {
  componentSettings: (state) => (key) => {
    return state.cmsSettings[key];
  }
};

const actions = {};

const mutations = {
  setCmsSettings(state, val = {}) {
    state.cmsSettings = val;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
