<template lang='pug'>
  .interval
    slot(v-bind:val="val" v-bind:pause="pause")
</template>

<script>
  export default {
    props: {
      values: Number,
      delay: {
        default: 2000,
        type: Number
      }
    },

    data() {
      return {
        val: 1,
        paused: false,
        interval: null
      }
    },

    methods: {
      pause(newVal) {
        this.val = newVal || this.val;
        this.paused = true;
      }
    },

    mounted() {
      var self = this;
      self.interval = window.setInterval(() => {
        if (!self.paused && self.val < self.values) {
          self.val += 1;
        } else if (!self.paused) {
          self.val = 1;
        }
      }, self.delay);
    },

    beforeDestroy() {
      window.clearInterval(this.interval);
    }
  };
</script>
