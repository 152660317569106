<template>
  <div ref="focusLockContainer" tabIndex="-1">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'FocusLock',
    props: {
      disabled: {
        type: Boolean,
        default: false,
      }
    },
    watch: {
      disabled(newValue) {
        if (newValue) {
          this.removeEventListeners();
        } else {
          this.addEventListeners();
          this.setInitialFocus();
        }
      }
    },
    mounted() {
      if (!this.disabled) {
        this.addEventListeners();
        this.setInitialFocus();
      }
    },
    beforeDestroy() {
      this.removeEventListeners();
    },
    methods: {
      handleKeydown(event) {
        if (event.key !== 'Tab' || this.disabled) {
          return;
        }

        const focusableElements = this.findFocusableElements();
        if (focusableElements.length === 0) {
          return;
        }
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];

        if (event.shiftKey && document.activeElement === firstElement) {
          event.preventDefault();
          lastElement.focus();
        } else if (!event.shiftKey && document.activeElement === lastElement) {
          event.preventDefault();
          firstElement.focus();
        }
      },
      setInitialFocus() {
        if (this.disabled) {return;}
        const focusableElements = this.findFocusableElements();
        if (focusableElements.length > 0) {
          focusableElements[0].focus();
        }
      },
      findFocusableElements() {
        const elements = this.$refs.focusLockContainer.querySelectorAll(`
        input,
        select,
        textarea,
        a[href],
        button,
        [tabindex]:not([tabindex="-1"]),
        audio[controls],
        video[controls],
        [contenteditable]:not([contenteditable="false"])
      `);
        return Array.from(elements).filter(el => !el.hasAttribute('disabled') && !el.hasAttribute('tabindex', '-1'));
      },
      addEventListeners() {
        this.$refs.focusLockContainer.addEventListener('keydown', this.handleKeydown);
      },
      removeEventListeners() {
        this.$refs.focusLockContainer.removeEventListener('keydown', this.handleKeydown);
      }
    }
  };
</script>
