import { logout } from '@services/vueCustomerSvc';

export default {
  methods: {
    writeReview(url, rating) {
      var href = `/product/${url}/review`;
      if (rating !== undefined) {
        href += `?stars=${rating}`;
      }

      window.location.href = href;
    },

    mix_logOut(destinationPath) {
      this.$cookies.remove('amazon_Login_state_cache');
      this.$cookies.remove('amazon_Login_accessToken');
      this.$cookies.remove('amazon_Address_consentToken');

      localStorage.removeItem('ajs_group_properties');
      localStorage.removeItem('ajs_user_traits');

      logout().then(() => {
        this.mix_trackEvent('Logged Out');
        window.analytics.reset();
        window.location = destinationPath || '/';
      });
    }
  }
};