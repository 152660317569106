import { helpers } from 'vuelidate/lib/validators';
import { TOP_LEVEL_DOMAINS } from './tlds.js';

export function mrPhoneValidator(value) {
  if (!value) {
    return true;
  }

  var digits = value.replace(/\D/g, '');
  var length = value[0] == 1 ? 11 : 10;

  return !helpers.req(value) || !(digits.length < length);
}

export function mrCardValidator(requiredSize) {
  return function (value) {
    if (value === undefined || value === null) {
      return true;
    }
    return value.toString().replace(/\D/g, '').length == requiredSize && mod10Check(value);
  };
}

export function isEmptyObject(obj) {
  if (!obj || obj.constructor !== Object) {
    return false;
  }
  return Object.keys(obj).length === 0;
}

export function mrCardExpirationValidator(selectedMonth) {
  const selectedYear = this.$v.editProfileForm.cc_exp_year.$model;
  if (!selectedYear || (!selectedMonth && selectedMonth !== 0)) {
    return false;
  }
  const date = new Date();
  const currentMonth = date.getMonth() + 1;
  const currentYear = date.getFullYear();
  return parseInt(selectedYear, 10) > currentYear || parseInt(selectedMonth, 10) >= currentMonth;
}

export function mrCardExpirationMMYYValidator(mmyy) {
  // format: 01/21
  if (!mmyy || mmyy.length !== 5) {
    return false;
  }

  const now = new Date();
  const currentMonth = now.getMonth() + 1;
  const currentYear = parseInt(now.getFullYear().toString().slice(-2), 10);

  const month = parseInt(mmyy.slice(0, 2), 10);
  const year = parseInt(mmyy.slice(-2), 10);

  if (month < 1 || month > 12) {
    return false;
  }

  return year > currentYear || (year === currentYear && month >= currentMonth);
}

//- What is mod10Check? Look no further: https://en.wikipedia.org/wiki/Luhn_algorithm
function mod10Check(num) {
  var parts = ('' + num).replace(/\D/g, '').split('').reverse();

  var sum = 0;
  for (var i = 0; i < parts.length; i++) {
    var part = +parts[i];
    if (i % 2) {
      part = +part * 2;
      if (part > 9) {
        part = 1 + (part % 10);
      }
    }
    sum += part;
  }

  return sum % 10 === 0;
}

export const TLDValidator = helpers.withParams(
  { type: 'TLDValidator' },
  (email) => {
    if (!email) {
      return false;
    }
    
    let parts = email.split('.');
    
    if (parts.length <= 1) {
      return false;
    }

    let tld = parts[parts.length - 1];
    
    if (typeof tld != 'string') {
      return false;
    }

    return TOP_LEVEL_DOMAINS.includes(tld.toUpperCase());
  }
);
