<template lang="pug">
  .nav-title.bold.upper.xs-f-medium.lg-f-small.max-at-tweak.brand-color-1
    slot

</template>

<script>
  export default {
    name: "NavTitle"
  };
</script>

<style scoped lang="stylus">

</style>