import { render, staticRenderFns } from "./PhoneCaptureForm.vue?vue&type=template&id=b8b0b5dc&scoped=true&lang=pug"
import script from "./PhoneCaptureForm.vue?vue&type=script&lang=js"
export * from "./PhoneCaptureForm.vue?vue&type=script&lang=js"
import style0 from "./PhoneCaptureForm.vue?vue&type=style&index=0&id=b8b0b5dc&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8b0b5dc",
  null
  
)

export default component.exports