<template lang='pug'>
  .toggle
    slot(v-bind:val="val" v-bind:set-val="setVal" v-bind:toggle-val="toggleVal")
</template>

<script>
  export default {
    data() {
      return {
        val: 0
      }
    },

    methods: {
      setVal(newVal) {
        this.val = newVal;
      },

      toggleVal(newVal) {
        if (this.val == newVal) {
          this.val = 0;
        } else {
          this.val = newVal;
        }
      }
    }
  }
</script>