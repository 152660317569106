<template lang="pug">
  picture.responsive-image
    source(v-if="srcsets[0]" media="only screen and (min-width: 0px) and (max-width: 559px)" :srcset="srcsets[0]")
    source(v-if="srcsets[1]" media="only screen and (min-width: 560px) and (max-width: 759px)" :srcset="srcsets[1]")
    source(v-if="srcsets[2]" media="only screen and (min-width: 760px) and (max-width: 959px)" :srcset="srcsets[2]")
    source(v-if="srcsets[3]" media="only screen and (min-width: 960px)" :srcset="srcsets[3]")
    img(:src="defaultSrc" :alt="mediaObj.alt_text" :tabindex="tabindex" :loading="loadingType")
</template>

<script>
  export default {
    name: "ResponsiveImage",

    props: {
      mediaObj: {
        type: Object,
        required: true,
        default() {
          return ({
            url: '',
            alt_text: '',
          });
        }
      },

      /**
       * SSR only: this will make the image URLs/srcsets be preloaded in the <head> section. Takes no effect on non-SSR views
       * See htmlRenderer.js > serverSideRenderVueContent / getAdditionalSsrContextSettings functions
       */
      preloadImage: {
        type: Boolean,
        default: false
      },

      xsAspectKey: {
        type: String,
        default() {
          return '';
        }
      },

      smAspectKey: {
        type: String,
        default() {
          return '';
        }
      },

      mdAspectKey: {
        type: String,
        default() {
          return '';
        }
      },

      lgAspectKey: {
        type: String,
        default() {
          return '';
        },
      },

      defaultAspectKey: {
        type: String,
        default() {
          return 'lg';
        },
      },

      tabindex: {
        type: Number,
        default: -1,
      },

      loadingType: {
        type: String,
        default: "lazy",
      },
    },

    computed: {
      baseSrc() {
        let newUrl = this.mediaObj.url;
        let imgixHostMap = {'d3ewrnwdcmri66.cloudfront.net': 'mr-cdn.imgix.net', 'ddxs94deh28np.cloudfront.net': 'mr-cdn-staging.imgix.net'};
        let hostRE = /https?:\/\/([^/]+)\/.*$/;
        let awsHost = newUrl.replace(hostRE, '$1');
        let imgxHost = imgixHostMap[awsHost];
        newUrl = newUrl.replace(awsHost, imgxHost);

        return newUrl;
      },

      defaultSrc() {
        let src = this.baseSrc;
        let defaultAspectKey = this[this.defaultAspectKey] || this.lg;
        let defaultAspect = this.mediaObj.aspects.find(a => a.key === defaultAspectKey);

        if (defaultAspect && defaultAspect.cropQuery) {
          src += '?' + defaultAspect.cropQuery + '&auto=format';
          if (this.mediaObj.quality) {
            src += '&q=' + this.mediaObj.quality;
          }
        }

        return src;
      },

      srcsets() {
        let widths = [100, 200, 320, 400, 600, 768, 800, 1000, 1024, 1200, 1280, 1366, 1400, 1440, 1600, 1680, 1800, 1920, 2048];
        let srcsets = [];

        this.aspectKeys.forEach(key => {
          let aspect = this.mediaObj.aspects.find(a => a.key === key);
          let srcset = '';

          widths.forEach(width => {
            if (aspect && aspect.cropQuery) {
              srcset += this.baseSrc + '?' + aspect.cropQuery + '&auto=format' + '&w=' + width + '&h=' + Math.round(width/aspect.m);

              if (this.mediaObj.quality) {
                srcset += '&q=' + this.mediaObj.quality;
              }

              srcset += ' ' + width + 'w,';
            }
          });

          srcsets.push(srcset);
        });

        return srcsets;
      },

      aspectKeys() {
        const xs = this.xsAspectKey;
        let sm = this.smAspectKey || xs;
        let md = this.mdAspectKey || sm;
        let lg = this.lgAspectKey || md;

        let aspects = [];
        aspects.push(xs);
        aspects.push(sm);
        aspects.push(md);
        aspects.push(lg);
        return aspects.filter(v => v);
      },
    },

    created() {
      this.emitPreloadedSrcSets();
    },

    methods: {
      /**
       * Emits the image srcsets for being preloaded
       * See htmlRenderer.js > serverSideRenderVueContent / getAdditionalSsrContextSettings functions
       */
      emitPreloadedSrcSets() {
        if (process.env.IS_SSR_APP && !process.env.IS_BROWSER && this.preloadImage) {
          if (!this.$ssrContext.preloadImagesSrcSets) {
            this.$ssrContext.preloadImagesSrcSets = [];
          }

          this.$ssrContext.preloadImagesSrcSets.push(this.srcsets);
        }
      },
    }
  };
</script>

<style lang="stylus">
.responsive-image
  width 100%
  img
    width 100%
</style>