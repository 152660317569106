import mrApi from './mrApi';

export default {
  getAllColorMatches,
  getCustomerAnswers,
  getReadableCustomerAnswersForQuiz,
  getMoreColorOptions,
  getMoreColorOptionsV2,
  getLightWorksRecommendations,
  getBoostRecommendations,
  getColorSolution,
  shouldSeeColorSolutions,
  persistCustomerAnswers,
  getHowsItAnswersBySubscriptionId,
  onQQuizCompleted,
  onQPickSelected,
  sendOnQPickEmail,
  getRootPerfectionRecommendation,
  getCompareShadesData,
};

function getCompareShadesData() {
  return mrApi.get('/api/advisor/getCompareShadesData');
}

function getAllColorMatches() {
  return mrApi.get('/api/advisor/getAllColorMatches');
}

function getCustomerAnswers() {
  return mrApi.get('/api/quiz/getCustomerAnswers');
}

function getReadableCustomerAnswersForQuiz(params) {
  return mrApi.get('/api/quiz/getReadableCustomerAnswersForQuiz', { params });
}

function getMoreColorOptions() {
  return mrApi.get('/api/advisor/getMoreColorOptions');
}

function getMoreColorOptionsV2() {
  return mrApi.get('/api/advisor/getMoreColorOptionsV2');
}

function getLightWorksRecommendations() {
  return mrApi.get('/api/advisor/getLightWorksRecommendations');
}

function getBoostRecommendations(params) {
  return mrApi.get('/api/advisor/getBoostRecommendations', { params });
}

function getRootPerfectionRecommendation(params) {
  return mrApi.get('/api/advisor/getRootPerfectionRecommendation', { params });
}

function getColorSolution() {
  return mrApi.get('/api/advisor/getColorSolution');
}

/**
 * returns an object with shouldSeeColorSolutions set to true or false depending on whether
 *  or not the customer should be taken to the /color-solutions page after the color satisfaction survey
 *  and problem set to 'coverage' or 'dark' based on what problems the customer had with their color
 *
 * @param {object} params
 * @returns {Promise} promise
 */
function shouldSeeColorSolutions(params) {
  return mrApi.get('/api/advisor/shouldSeeColorSolutions', { params });
}

function persistCustomerAnswers(params) {
  return mrApi.post('/api/quiz/persistCustomerAnswers', params);
}

function getHowsItAnswersBySubscriptionId(params) {
  return mrApi.get('/api/advisor/getHowsItAnswersBySubscriptionId', { params });
}

export function onQQuizCompleted(params) {
  return mrApi.post('/api/advisor/onQQuizCompleted', params);
}

export function onQPickSelected(params) {
  return mrApi.post('/api/advisor/onQPickSelected', params);
}

export function sendOnQPickEmail(params) {
  return mrApi.post('/api/advisor/sendOnQPickEmail', params);
}