<template lang="pug">
  .nav-sub-header.upper.xs-f-small.lg-f-xsmall.max-at-tweak.text-color-2(:class="{ 'add-inline-block': !stretch }")
    slot

</template>

<script>
export default {
  name: "NavSubHeader",
  props: {
    stretch: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="stylus">
  .nav-sub-header
    background-color ui-color-3
    padding 0.5em 1em 0.3em
    margin-bottom 0.4em

    &.add-inline-block
      display inline-block

</style>