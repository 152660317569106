//- For this to work with SSR we have to define this outside of the directive but assign it inside.
//- IntersectionObserver is part of browser API and is not available on the server.
let animatedScrollObserver = null;

export default {
  inserted(el) {
    if (!animatedScrollObserver) {
      animatedScrollObserver = new IntersectionObserver((entries, animatedScrollObserver) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('mr-scroll-enter');
            animatedScrollObserver.unobserve(entry.target);
          }
        });
      });
    }

    el.classList.add('mr-scroll-before-enter');
    if (animatedScrollObserver) {
      animatedScrollObserver.observe(el);
    }
  }
};
