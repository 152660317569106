import mrApi from './mrApi';

export default {
  getAllUpcomingRefillCartDataForView,
  addToCart,
  addMultipleItemsToCart,
  updateItemQty,
  removeItemFromCart,
  setUseCredits
};

export function getAllUpcomingRefillCartDataForView() {
  return mrApi.get('/api/refillCart/getAllUpcomingRefillCartDataForView');
}

export function addToCart(params) {
  return mrApi.post('/api/refillCart/addToCart', params);
}

export function addMultipleItemsToCart(params) {
  return mrApi.post('/api/refillCart/addMultipleItemsToCart', params);
}

export function updateItemQty(params) {
  return mrApi.post('/api/refillCart/updateItemQty', params);
}

export function removeItemFromCart(params) {
  return mrApi.post('/api/refillCart/removeItemFromCart', params);
}

export function setUseCredits(params) {
  return mrApi.post('/api/refillCart/setUseCredits', params);
}
