import vueRefillSvc from "@services/vueRefillSvc";
import { getObjProperty } from "@utilities/mrVueUtils";

const state = {
  refillCart: {},
  refillCartUpdated: 0,
  refillCartLoaded: false,
  loadingRefillCart: false,
  modifyingRefillProduct: null, //- set it to product id so that we can reference which product is getting added/removed/updated
  loadingRefillCartUseCredit: false
};

export const getters = {
  productIdInRefillCart: (state, getters) => (id) => {
    let inRefillCart = false;

    if (id) {
      //- in refillCart id of each item represents product.id
      inRefillCart = Boolean(getters.refillCartItems.find(item => item.id == id));
    }

    return inRefillCart;
  },

  productMaxQtyInRefillCart: (state, getters, rootState) => (id) => {
    return getters.refillCartItems.some(item => item.id == id ? item.qty >= rootState.constants.maxQuantity : false);
  },

  refillCartItems(state) {
    let items = [];
    let cart = state.refillCart;

    return items.concat((cart.subscriptionsInCart || []), (cart.addonsInCart || []));
  },

  refillCartCreditBalance(state) {
    return getObjProperty(state, 'refillCart.totals.creditsBalance');
  },

  qtyByProductTypeInRefillCart: (state, getters) => (productType) => {
    let totalQty = 0;
    let items = getters.refillCartItems;

    if (!items || !items.length) {
      return totalQty;
    }

    items.forEach(item => {
      if (item.product_type == productType && Object.hasOwnProperty.call(item, 'qty')) {
        totalQty += item.qty;
      }
    });

    return totalQty;
  },

  baseSubscription(state) {
    //- take the first sub, subscriptionsInCart should always have main sub at index 0
    return getObjProperty(state, 'refillCart.subscriptionsInCart.0') || {};
  },

  getCreditsBalance(state) {
    let creditsBalance = getObjProperty(state, 'refillCart.totals.creditsBalance');
    return Boolean(creditsBalance && creditsBalance > 0);
  },

  refillCartIsApplePay(state) {
    return getObjProperty(state, 'refillCart.nextShipment.paymentInfo.payment_gateway') === 'apple pay';
  }
};

export const actions = {
  initRefillCart({ state, dispatch }) {
    if (state.loadingRefillCart || Object.keys(state.refillCart).length) {
      return;
    }

    dispatch('loadRefillCart');
  },

  loadRefillCart({ state, commit }) {
    if (state.loadingRefillCart) {
      return;
    }

    commit('setLoadingRefillCart', true);
    //- refillCart really is a next upcoming subscription with all the items in the refillCart. refillCart by itself is not it.
    return vueRefillSvc.getAllUpcomingRefillCartDataForView().then(res => {
      commit('setRefillCart', res.data);
      commit('setLoadingRefillCart', false);
      commit('setRefillCartLoaded');
    }).catch(err => {
      commit('setLoadingRefillCart', false);
      throw new Error(err);
    });
  },

  async addToRefillCart({ state, commit, dispatch }, params) {
    ensureSubIdInParams(state, params);
    const { subscriptionId, product_id, qty = 1, ctaSource, membershipAddon } = params;
    commit('setModifyingRefillProduct', product_id);

    const payload = {
      subscriptionId, //:number
      product_id, //:number
      qty, //:number
      ctaSource, //:object
      membershipAddon //:boolean
    };

    try {
      await vueRefillSvc.addToCart(payload);
      await dispatch('loadRefillCart');
      commit('setModifyingRefillProduct', null);
      commit('setRefillCartUpdated');
      dispatch('notifySuccess', 'Item has been added to your next shipment', { root: true });
    } catch (err) {
      commit('setModifyingRefillProduct', null);
      dispatch('notifyError', null, { root: true });
    }
  },

  addMultipleItemsToRefillCart({state, commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      commit('setModifyingRefillProduct', true);

      ensureSubIdInParams(state, params);

      vueRefillSvc.addMultipleItemsToCart(params).then(() => {
        dispatch('loadRefillCart');
        commit('setModifyingRefillProduct', false);
        commit('setRefillCartUpdated');
        dispatch('notifySuccess', 'Items have been added to your next shipment', { root: true });
        resolve();
      }).catch(err => {
        commit('setModifyingRefillProduct', false);
        dispatch('notifyError', null, { root: true });
        reject(err);
      });
    });
  },

  updateRefillItemQty({ state, commit, dispatch }, params) {
    commit('setModifyingRefillProduct', params.product_id);
    ensureSubIdInParams(state, params);

    return vueRefillSvc.updateItemQty(params).then(() => {
      dispatch('loadRefillCart');
      commit('setModifyingRefillProduct', null);
      commit('setRefillCartUpdated');
    }).catch(err => {
      commit('setModifyingRefillProduct', null);
      dispatch('notifyError', null, { root: true });
      throw new Error(err);
    });
  },

  removeRefillItem({ state, commit, dispatch }, params) {
    if (state.modifyingRefillProduct) {
      return;
    }

    commit('setModifyingRefillProduct', params.product_id);
    ensureSubIdInParams(state, params);

    return vueRefillSvc.removeItemFromCart(params).then(() => {
      dispatch('loadRefillCart');
      commit('setModifyingRefillProduct', null);
      commit('setRefillCartUpdated');
      dispatch('notifySuccess', 'Item has been removed from your next shipment', { root: true });
    }).catch(() => {
      commit('setModifyingRefillProduct', null);
      dispatch('notifyError', null, { root: true });
    });
  },

  async setCreditsOnRefillCart({ state, commit, dispatch }, params) {
    ensureSubIdInParams(state, params);
    commit('setLoadingRefillCartUseCredit', true);
    try {
      let res = await vueRefillSvc.setUseCredits(params);
      await commit('setRefillCart', res.data);
      commit('setRefillCartUpdated');
      commit('setLoadingRefillCartUseCredit', false);
      dispatch('notifySuccess', 'Your cart rewards settings has been updated', { root: true });
    } catch {
      commit('setLoadingRefillCartUseCredit', false);
      dispatch('notifyError', null, { root: true });
    }
  }
};

export const mutations = {
  setRefillCart(state, val) {
    state.refillCart = val || {};
  },

  setRefillCartUpdated(state) {
    state.refillCartUpdated += 1;
  },

  setModifyingRefillProduct(state, val) {
    state.modifyingRefillProduct = val;
  },

  setLoadingRefillCart(state, val) {
    state.loadingRefillCart = val;
  },

  setUpdatingProduct(state, val) {
    state.updatingProduct = val;
  },

  setRefillCartLoaded(state) {
    //- once its loaded it stays true until complete state refresh
    state.refillCartLoaded = true;
  },

  setLoadingRefillCartUseCredit(state, val) {
    state.loadingRefillCartUseCredit = val;
  }
};

function ensureSubIdInParams(state, params) {
  //- by default assign sub_id from the current refill cart
  if (params.subscriptionId) {
    return;
  }

  params.subscriptionId = state.refillCart.nextShipment.sub_id;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
