<template lang="pug">
  .email-capture-modal
    CaptureModal(:modal-shown="isEmailModalVisible" :close-modal-method="closeEmailModal")
      template(#header="")
        img.banner-img(v-if="showImgBanner" height="65" :src="imgTitle.url" :alt="imgTitle.alt")
        .title.upper.brand-color-1.bold.xs-f-xmedium.max-at-tweak(v-else) {{ title }}

      template(#content="")
        p.description.mb-20(v-if="subscribed" v-html="description")
        p.description.xs-mb-100m(v-else v-html="description")

        transition(name="fade")
          EmailCaptureForm(v-if="!subscribed" type="modal" :code="config.code" :email-source="config.emailSource" @email-submitted="onEmailSubmitted")

        p.conditions.xs-f-xsmall.sm-f-xxsmall.max-at-tweak.xs-mt-75m(v-if="!subscribed" v-html="config.conditions")

</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex';
  import CaptureModal from "../CaptureModal/CaptureModal";
  import EmailCaptureForm from "../EmailCaptureForm/EmailCaptureForm";

  export default {
    name: 'EmailCaptureModal',

    components: {
      EmailCaptureForm,
      CaptureModal,
    },

    data() {
      return {
        config: {}
      };
    },

    computed: {
      ...mapState('emailCapture', [
        'isEmailModalVisible',
        'subscribed',
        'configsLoaded',
      ]),

      ...mapGetters('emailCapture', [
        'getModalConfig',
      ]),

      ...mapState('customer', [
        'cdata',
      ]),

      showImgBanner() {
        return this.imgTitle && !this.subscribed;
      },

      imgTitle() {
        if (!this.config.bannerImg) {
          return null;
        }
        return { url: this.config.bannerImg.url, alt: this.config.bannerImg.alt_text };
      },

      title() {
        if (!this.config.title) {
          return;
        }

        return this.subscribed ? this.config.title.success : this.config.title.default;
      },

      description() {
        if (!this.config.description) {
          return;
        }

        return this.subscribed ? this.config.description.success : this.config.description.default;
      },
    },

    watch: {
      configsLoaded() {
        this.config = this.getModalConfig();
      }
    },

    methods: {
      ...mapActions('emailCapture', [
        'closeEmailModal',
        'processOnSuccessAfterClose',
      ]),

      onEmailSubmitted() {
        this.closeEmailModal();
        if (this.config.onSuccessAfterClose) {
          this.processOnSuccessAfterClose(this.config.onSuccessAfterClose);
        }
      },
    },
  };
</script>

<style lang="stylus">
  .email-capture-modal > .capture-modal
    padding-bottom 60px
  .banner-img
    margin-top 1rem
</style>
