<template lang="pug">
  .site-nav-desktop-sub-menu.text-left
    NavTitle {{ subNavData.title }}

    ul(role="menu")
      li.sub-menu-item(v-for='(item, index) in navItems' :key="index" role="none")
        SiteNavSubMenuItem(:item="item" :hideSubTitle="true")

</template>

<script>

import NavTitle from '../../NavTitle';
import SiteNavSubMenuItem from '../../SiteNavSubMenuItem';
import menuMixin from "@mixins/menuMixin";

export default {
  name: "SiteNavDesktopSubMenu",

  components: {
    NavTitle,
    SiteNavSubMenuItem
  },

  mixins: [menuMixin],

  props: {
    subNavData: {
      required: true,
      type: Object
    }
  },

  computed: {
    navItems() {
      return this.mix_copyOnlyElementsToShow(this.subNavData.navItems || []);
    }
  }
};
</script>

<style scoped lang="stylus">
  .site-nav-desktop-sub-menu
    padding 1em 0

    li.sub-menu-item
      padding 0.5em 0

</style>