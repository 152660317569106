import { mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState('cart', ['cartUpdated']),
    ...mapState('refillCart', ['refillCart']),
    ...mapGetters('refillCart', ['productIdInRefillCart']),

    buyingSubscription() {
      var buyingSub = false;

      if (this.cart && this.cart.items) {
        this.cart.items.forEach(item => {
          if (item.subscription > 0) {
            buyingSub = true;
          }
        });
      }

      return buyingSub;
    },

    firstColorInCart() {
      var color = {};
      if (this.cart && this.cart.items) {
        this.cart.items.forEach(item => {
          if (!color.id && item.product_type == 'color_kit') {
            color = item;
          }
        });
      }
      return color;
    },

    hasColorInCart() {
      return !!this.firstColorInCart.id;
    },
  },

  asyncComputed: {
    cic: {
      lazy: true,
      get() {
        return this.$cookies.get('cic') || 0;
      },
      watch() {
        this.cartUpdated;
      }
    }
  }
};