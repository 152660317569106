import {createApp} from '../ssrApp.js';
import notifications from "@store/modules/notifications";
import emailCapture from "@store/modules/emailCapture";
import signIn from "@store/modules/signIn";

createApp().then(({app, store, router}) => {
// Replace the state with SSR'd one
  if (window.__INITIAL_STATE__) {
    store.replaceState(window.__INITIAL_STATE__);
  }

// Client only stores
  store.registerModule('notifications', notifications);
  store.registerModule('emailCapture', emailCapture);
  store.registerModule('signIn', signIn);

// Use the original CMS code as Vue template for the "SsrApp.vue" "created" life-cycle hook.
  store.commit('global/setAppHtml', window.__SSR_APP_HTML__);

// Mount the app
  app.$mount('#mrVueApp');
});
