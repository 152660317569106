import throttle from 'lodash.throttle';
import {mapMutations, mapState} from "vuex";

export default {
  computed: {
    ...mapState('global', ['windowData']),
    ...mapState('customer', ['cdata']),
  },

  methods: {
    ...mapMutations('global', ['setWindowData']),

    checkAuth() {
      if (this.info.authed) {
        this.initSubscriptions();
      }
    },

    onResizeEventCallback() {
      const previousWidth = this.windowData.width;
      const previousHeight = this.windowData.height;
      const previousBreakpoint = this.windowData.breakpoint;

      this.setWindowData(['width', window.innerWidth]);
      this.setWindowData(['height', window.innerHeight]);
      this.setWindowData(['scrollBottom', window.pageYOffset + window.innerHeight]);

      this.updateBreakpoint();

      if (previousWidth != this.windowData.width) {
        this.$emit('width-change', this.windowData);
      }
      if (previousHeight != this.windowData.height) {
        this.$emit('height-change', this.windowData);
      }
      if (previousBreakpoint != this.windowData.breakpoint) {
        this.$emit('breakpoint-change', this.windowData);
      }
    },

    onScrollEventCallback() {
      if (this.windowData.scrollTop != window.pageYOffset) {
        const oldScrollTop = this.windowData.scrollTop;
        this.setWindowData(['scrollTop', window.pageYOffset]);
        this.setWindowData(['scrollBottom', window.pageYOffset + window.innerHeight]);
        this.setWindowData(['scrollDirection', oldScrollTop > this.windowData.scrollTop ? -1 : 1]);
        this.setWindowData(['scrollDepth', (this.windowData.scrollBottom / this.$el.clientHeight) * 100]);
        this.setWindowData(['scrolled', this.windowData.scrolled || this.windowData.scrollTop > 0]);
        this.$emit('scroll-change', this.windowData);
        // browser hidden nav detection
        this.setWindowData(['heightOnScroll', window.innerHeight]);

        if (this.windowData.heightOnScroll != this.windowData.oldHeightOnScroll) {
          if (this.windowData.heightOnScroll - 25 > this.windowData.oldHeightOnScroll) {
            // innerHeight increased indicating mobile nav is hidden
            this.setWindowData(['mobileNavHidden', true]);
          } else if (this.windowData.heightOnScroll + 25 < this.windowData.oldHeightOnScroll) {
            // innerHeight decreased indicating mobile nav is visible
            this.setWindowData(['mobileNavHidden', false]);
          }

          this.setWindowData(['oldHeightOnScroll', this.windowData.heightOnScroll]);
        }

        // scrolled depth tracking
        if (this.contentData.depthTracking) {
          const trackingData = {
            url: window.location.href,
            breakpoint: this.windowData.breakpoint,
            uri: this.contentData.uri,
            variationId: this.contentData.variationId,
            targetPlatform: this.contentData.targetPlatform,
            variationKey: this.contentData.variationKey,
            variationName: this.contentData.variationName,
            experimentId: this.contentData.experimentId,
            experimentName: this.contentData.experimentName,
          };

          if (this.windowData.scrollDepth > 25 && this.windowData.scrolledDepth < 25) {
            trackingData.percent = 25;
            this.trackEvent('Scroll Depth', trackingData);
          }
          if (this.windowData.scrollDepth > 50 && this.windowData.scrolledDepth < 50) {
            trackingData.percent = 50;
            this.trackEvent('Scroll Depth', trackingData);
          }
          if (this.windowData.scrollDepth > 75 && this.windowData.scrolledDepth < 75) {
            trackingData.percent = 75;
            this.trackEvent('Scroll Depth', trackingData);
          }
          if (this.windowData.scrollDepth >= 100 && this.windowData.scrolledDepth < 100) {
            trackingData.percent = 100;
            this.trackEvent('Scroll Depth', trackingData);
          }
        }

        // scrolledDepth should updated after depth tracking
        this.setWindowData([
          'scrolledDepth',
          this.windowData.scrollDepth > this.windowData.scrolledDepth ? this.windowData.scrollDepth : this.windowData.scrolledDepth,
        ]);
      }

      if (!this.accessibilityViewed) {
        this.accessibilityViewed = !!document.getElementById('ada_help_container');
        if (this.accessibilityViewed) {
          // should only trigger one time when the ada_help_container gets added to the page
          this.trackEvent('Accessiblity Widget Viewed');
        }
      }
    },

    checkBrowsers() {
      var ua = navigator.userAgent || navigator.vendor || window.opera;
      if (ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1) {
        this.setWindowData(['facebookBrowser', true]);
      }
      if (ua.indexOf('Instagram') > -1) {
        this.setWindowData(['instagramBrowser', true]);
      }
      if (ua.indexOf('Pinterest') > -1) {
        this.setWindowData(['pinterestBrowser', true]);
      }
      if (ua.includes('iPhone')) {
        this.setWindowData(['isIphone', true]);
      }
      if (ua.includes('iPod')) {
        this.setWindowData(['isIphone', true]);
      }
      if (ua.includes('iPad')) {
        this.setWindowData(['isIpod', true]);
      }
      if (this.windowData.isIphone || this.windowData.isIpod || this.windowData.isIpad) {
        this.setWindowData(['isIosDevice', true]);
      }
    },

    updateBreakpoint() {
      let breakpoint;

      if (this.windowData.width <= 559) {
        breakpoint = 'mobile';
      } else if (this.windowData.width <= 759) {
        breakpoint = 'tablet';
      } else if (this.windowData.width <= 959) {
        breakpoint = 'desktop-md';
      } else {
        breakpoint = 'desktop';
        this.setTopNavMobileNavOpen(false);
      }

      this.setWindowData(['breakpoint', breakpoint]);
    },

    setDeviceSizeSettings() {
      // set windowData
      this.checkBrowsers();
      this.setWindowData(['width', window.innerWidth]);
      this.setWindowData(['height', window.innerHeight]);
      this.setWindowData(['oldHeightOnScroll', window.innerHeight]);
      this.setWindowData(['heightOnScroll', window.innerHeight]);
      this.setWindowData(['scrollTop', window.pageYOffset]);
      this.setWindowData(['scrollBottom', window.pageYOffset + window.innerHeight]);
      this.setWindowData(['scrollDepth', this.windowData.scrollBottom / this.$el.clientHeight * 100]);
      this.setWindowData(['scrolledDepth', 0]);
      this.setWindowData(['scrolled', this.windowData.scrolled || this.windowData.scrollTop > 0]);

      if (window.isIOS) {
        this.setWindowData(['isIOSApp', true]);
      }

      this.updateBreakpoint();

      const throttledOnResizeEvent = throttle(this.onResizeEventCallback, 200);
      window.addEventListener('resize', throttledOnResizeEvent, false);

      const throttledOnScrollEvent = throttle(this.onScrollEventCallback, 200);
      window.addEventListener('scroll', throttledOnScrollEvent, false);
    },

    contentData() {
      let data = {};

      if (window.contentData) {
        data = window.contentData;
      }

      return data;
    }
  }
};