const ShopProductCategory = () => import(/* webpackChunkName: "ShopProductCategory" */ './ShopProductCategory');

export default [{
  path: '/shop/:keyA/:keyB?/:keyC?/:keyD?/:keyE?',
  name: 'ShopProductCategory',
  component: ShopProductCategory,
  meta: {
    noScroll: true
  },
  props: true,
  scrollBehavior(to) {
    // allow scroll to anchor
    if (to.hash) {
      return {
        selector: to.hash
      };
    }
  }
}];
