<template lang="pug">
  a.standalone.xs-f-small.max-at-tweak.text-left(:href="href" role="menuitem")
    slot
</template>

<script>
export default {
  name: "StandaloneLink",
  props: {
    href: {
      required: true,
      type: String
    }
  }
};
</script>

<style scoped lang="stylus">

</style>