<template lang="pug">
  .chat-widget-container(v-if="!hideChatWidget")
    GladlyWidget
</template>

<script>
  import GladlyWidget from "@components/GladlyWidget";
  import { mapState } from 'vuex';

  export default {
    name: "ChatWidget",

    components: {
      GladlyWidget,
    },
    computed: {
      ...mapState('chatWidget', ['overrideHideChat']),
      hideChatWidget() {
        return Boolean(
          (window && window.contentData && window.contentData.hideChatWidget) ||
            this.overrideHideChat
        );
      }
    }
  };
</script>