import vueSegmentSvc from '../services/vueSegmentSvc';
import { getBrowserTrackingContext } from "./eventTrackingUtils";

export function trackSegmentEvent(eventName, properties = {}) {
  if (!properties) {
    properties = {};
  }

  const currentTimestamp = (new Date()).toISOString();
  properties.isFrontEndEvent = true;

  return vueSegmentSvc.trackSegmentEvent({
    eventName,
    properties,
    timestamp: currentTimestamp,
    context: getBrowserContext()
  });
}

export function trackMREvent(eventName, properties = {}) {
  properties.eventName = eventName;
  trackSegmentEvent('MREvent', properties);
}

export function trackSegmentPage(pageName, properties = {}) {
  if (!properties) {
    properties = {};
  }

  const currentTimestamp = (new Date()).toISOString();
  properties.isFrontEndEvent = true;

  return vueSegmentSvc.trackSegmentPage({
    pageName,
    properties,
    timestamp: currentTimestamp,
    context: getBrowserContext()
  });
}

/**
 * This is necessary metadata since Segment's front-end lib does this automatically and now we need to do it manually
 * after the front-end > back-end Segment migration
 */
function getBrowserContext() {
  const context =  {
    locale: window.navigator.language,
    userAgent: window.navigator.userAgent,
    page: {
      ...getBrowserTrackingContext()
    }
  };

  delete context.pageName;

  // This is how Segment does it, even though there may not be any "utm_" params, Segment creates the empty campaign object anyways
  if (window.location.search) {
    context.campaign = getQueryParamsCampaignContext();
  }

  return context;
}

/**
 * This function follows Segment's front-end lib behavior for creating the "campaign" object based on "utm" query params
 */
function getQueryParamsCampaignContext() {
  const urlSearchParams = new URLSearchParams(window.location.search);

  let campaign = {};

  // Segment will add every parameter starting with "utm_" to the "campaign" object
  const utmPrefix = 'utm_';

  urlSearchParams.forEach((value, key) => {
    if (key.startsWith(utmPrefix)) {
      // Remove the "utm_" prefix for the key since that's how Segment does it
      const campaignKey = key.slice(4);
      campaign[campaignKey] = value;
    }
  });

  return campaign;
}

export default {
  trackSegmentEvent,
  trackMREvent,
  trackSegmentPage
};
