import * as filters from '../filters.js';

const filtersPlugin = {
  install(Vue) {
    // Check if it's Vue 3
    const isVue3 = Vue.version && Vue.version.startsWith('3');

    Object.keys(filters).forEach(key => {
      if (isVue3) {
        // For Vue 3, use globalProperties
        Vue.config.globalProperties[`$${key}`] = filters[key];
      } else {
        // For Vue 2, use Vue.prototype
        Vue.prototype[`$${key}`] = filters[key];
      }
    });
  }
};

export default filtersPlugin;
