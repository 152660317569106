import { render, staticRenderFns } from "./PromoTagCounter.vue?vue&type=template&id=6295e649&scoped=true&lang=pug"
import script from "./PromoTagCounter.vue?vue&type=script&lang=js"
export * from "./PromoTagCounter.vue?vue&type=script&lang=js"
import style0 from "./PromoTagCounter.vue?vue&type=style&index=0&id=6295e649&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6295e649",
  null
  
)

export default component.exports