import mrApi from './mrApi';

export default {
  getPartial,
  getPartials,
  searchBlogContent,
};

export function getPartial(params) {
  return mrApi.get('/api/cmsSvc/getPartial', { params });
}

export function getPartials(params) {
  return mrApi.get('/api/cmsSvc/getPartials', { params });
}

export function getPromoDrawerPartials(params) {
  return mrApi.get('/api/cmsSvc/getPromoDrawerPartials', { params });
}

export function searchBlogContent(params) {
  return mrApi.get('/api/cmsSvc/searchBlogContent', { params });
}
