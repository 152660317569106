<template lang="pug">
  .cms-partial-template
    component(:is="htmlComponent")
    component(is="style" v-if="css") {{css}}
</template>

<script>
export default {
  name: "CMSPartialTemplate",

  props: {
    html: {
      type: String,
      required: true,
    },

    css: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    htmlComponent() {
      return {
        template: this.html,
      };
    }
  }
};
</script>

<style scoped lang="stylus">

</style>