<template lang='pug'>
  .mr-site-search(:class="{open: searchOpen}" :aria-hidden="!searchOpen")
    .close-search(@click="closeSearch")
    .fixed-width-tweak-upper
      .search-wrap
        form.search-form(@submit.prevent="performSearch")
          label.search-text.xs-hide.sm-hide.md-hide.lg-f-large.max-at-tweak(@click="performSearch" for="site-search")
            span.icon-magnify
            span.xs-f-xmedium.md-f-large.lg-f-xmedium.max-at-tweak Search anytime by typing and hit enter
          .input-wrap
            input#site-search.search-input(ref="searchInput" v-model="searchTerm" placeholder="search" required :tabindex="searchOpen ? 0 : -1")

          button.search-button.bg-purple.lg-hide(type="submit" :tabindex="searchOpen ? 0 : -1")
            span.icon-magnify.color-white
              span.ada-tooltip search

        button.icon-x.btn-reset.clickable.xs-f-xlarge.md-f-xxlarge.lg-f-xlarge.max-at-tweak(@click="closeSearch" :tabindex="searchOpen ? 0 : -1")
          span.ada-tooltip close

    MrSpinnerVeil(v-if="searching")

</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import MrSpinnerVeil from '@components/MrSpinnerVeil';

  export default {
    name: 'MrSiteSearch',

    components: {
      MrSpinnerVeil
    },

    data: function() {
      return {
        searchTerm: '',
        searching: false
      };
    },

    computed: {
      ...mapState('siteNav', [
        'searchOpen'
      ])
    },

    watch: {
      'searchOpen': function(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            this.$refs.searchInput.focus();
          });

        }
      }
    },

    methods: {
      ...mapMutations('siteNav', [
        'setSearchOpen'
      ]),

      performSearch() {
        this.searching = true;
        window.location = '/search/' + encodeURIComponent(this.searchTerm);
      },

      closeSearch() {
        this.setSearchOpen(false);
      }
    }
  };
</script>

<style lang='stylus'>
.mr-site-search
  max-height 0
  overflow hidden

  .search-wrap
    display none

  &.open
    max-height 30em
    box-shadow 0px 2px 6px ui-color-4

    .search-wrap
      display block

      .input-wrap
        padding 0
        border none

        input#site-search
          padding-left 0.5em
          border 1px solid #ccc
          font-size 16px

        .search-input
          font-size 1em
          height 44px

</style>