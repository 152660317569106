import { getUrlParams, getObjProperty } from "@utilities/mrVueUtils";
import Vue from 'vue';

const state = {
  windowData: {
    breakpoint: '',
    width: 0,
    height: 0,
    oldHeightOnScroll: 0,
    heightOnScroll: 0,
    scrollTop: 0,
    scrollBottom: 0,
    scrollDirection: 0,
    scrollDepth: 0,
    scrolled: false,
    noScroll: false,
    facebookBrowser: false,
    instagramBrowser: false,
    pinterestBrowser: false,
    isIOSApp: false,
    isIphone: false,
    isIpod: false,
    isIpad: false,
    isIosDevice: false,
    mobileNavHidden: false
  },

  MRConfig: null,
  isVueSSRApp: false,
  simpleHtml: false,
  urlParams: null,
  appHtml: '',
  isApp: false,
  headerClass: null,
  showHamburgerMenu: false,
  hideLogin: false,
  showNavs: true,
  removeFaqsAndTerms: false,
  changeFaqsAndTerms: false,
  simpleFooter: false,
  breadcrumbs: null,
  experiments: {},
  overrideHideStickyPromoDrawer: false
};

const getters = {
  urlParams() {
    return getUrlParams();
  },

  isMobile(state) {
    return getObjProperty(state, 'windowData.breakpoint') === 'mobile';
  },

  isTablet(state) {
    return getObjProperty(state, 'windowData.breakpoint') === 'tablet';
  },

  isDesktopMd(state) {
    return getObjProperty(state, 'windowData.breakpoint') === 'desktop-md';
  },

  isDesktop(state) {
    return getObjProperty(state, 'windowData.breakpoint') === 'desktop';
  },

  breakpoint(state) {
    return getObjProperty(state, 'windowData.breakpoint');
  },

  showLimitlessPlusFooter(state, getters, rootState) {
    // show footer for people in experiment and return buyers that are not subscribers
    let customerType = getObjProperty(rootState, 'customer.cdata.visitorType');
    let customerIsRcc = getObjProperty(rootState, 'customer.cdata.activeSubTypes.color_kit');

    return Boolean(!customerIsRcc && customerType == "Return Buyer");
  }
};

const actions = {

};

const mutations = {

  setWindowData(state, [name, data]) {
    state.windowData[name] = data;
  },

  setMRConfig(state, val) {
    state.MRConfig = val;
  },

  setIsVueSSRApp(state, val) {
    state.isVueSSRApp = val;
  },

  setSimpleHtml(state, val) {
    state.simpleHtml = !!val;
  },

  setUrlParams(state, val) {
    state.urlParams = val;
  },


  setBrowserContext(state, newBrowserContext) {
    // https://stackoverflow.com/questions/61596158/vuex-mutation-best-practices-merging-state-with-payload
    Object.keys(newBrowserContext).forEach((key) => {
      Vue.set(state, key, newBrowserContext[key]);
    });
  },

  setAppHtml(state, appHtml) {
    state.appHtml = appHtml;
  },

  setBreadcrumbs(state, val) {
    state.breadcrumbs = val;
  },

  resetBreadcrumbs(state) {
    state.breadcrumbs = null;
  },

  setShowNavs(state, val) {
    state.showNavs = val;
  },

  setExperiments(state, val) {
    state.experiments = val;
  },

  setOverrideHideStickyPromoDrawer(state, val) {
    state.overrideHideStickyPromoDrawer = val;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};