import vueAdvisorSvc from "@services/vueAdvisorSvc";
import { getObjProperty } from "@utilities/mrVueUtils";
import { isQuizComplete } from "@services/vueQuizSvc";

const state = {
  answers: {},
  surveys: {},
  advisorLoading: false,
  advisorLoadingPromise: null,
  advisorLoaded: false,
  advisorCompleted: false,
  colorMatches: {
    data: {}
  },
  colorMatchesLoaded: false,
  lightWorksRecommendations: [],
  lightWorksQuizCompleted: false,
  rootPerfectionRecommendation: null,
};

export const getters = {
  colorSatisfactionSurvey(state) {
    return getObjProperty(state, 'surveys.color_satisfaction_survey_v4');
  },

  advisorFrequency(state) {
    return getObjProperty(state, 'answers.frequency');
  }
};

export const actions = {
  initColorAdvisor({ dispatch, state }) {
    if (!state.advisorLoaded) {
      dispatch('getAdvisorData');
    }
  },

  getAdvisorData({ state, commit }) {
    if (state.advisorLoading) {
      return state.advisorLoadingPromise;
    }
    commit('setAdvisorLoading', true);
    let advisorLoadingPromise = new Promise((resolve, reject) => {
      vueAdvisorSvc.getCustomerAnswers().then(({data = {}}) => {
        commit('setAnswers', data.answers);
        commit('setSurveys', data.surveys);
        commit('setAdvisorLoading', false);
        commit('setAdvisorLoaded', true);
        resolve(data);
      }).catch(() => {
        reject();
      });
    });

    return advisorLoadingPromise;
  },

  updateColorMatches({ commit }) {
    vueAdvisorSvc.getAllColorMatches().then(res => {
      commit('setColorMatches', res.data);
    });
  },

  isAdvisorCompleted({ commit }) {
    return new Promise((resolve, reject) => {
      isQuizComplete({ name: 'advisor_v2' }).then(res => {
        commit('setAdvisorCompleted', res.data.isComplete);
        resolve();
      }, err => {
        reject(err);
      });
    });
  },

  isLightWorksQuizCompleted({commit}) {
    return new Promise((resolve, reject) => {
      isQuizComplete({ name: 'highlights_only' }).then(res => {
        commit('setLightWorksQuizCompleted', res.data.isComplete);
        resolve();
      }, err => {
        reject(err);
      });
    });
  },

  //- not how store should be used
  persistCustomerAnswers({ dispatch }, params) {
    return vueAdvisorSvc.persistCustomerAnswers({ answers: params })
      .then(() => dispatch('getAdvisorAnswers'));
  },

  getLightWorksRecommendations({ commit }) {
    return vueAdvisorSvc.getLightWorksRecommendations().then(res => {
      commit('setLightWorksRecommendations', res.data);
    }).catch(() => {});
  },

  getRootPerfectionRecommendation({ commit }) {
    return vueAdvisorSvc.getRootPerfectionRecommendation().then(res => {
      commit('setRootPerfectionRecommendation', res.data);
    }).catch(() => {});
  },
};

export const mutations = {
  setAdvisorLoading(state, val) {
    state.advisorLoading = val;
  },

  setAdvisorPromise(state, val) {
    state.advisorPromise = val;
  },

  setAdvisorLoaded(state, val) {
    state.advisorLoaded = val;
  },

  setAdvisorCompleted(state, val) {
    state.advisorCompleted = Boolean(val);
  },

  setAnswers(state, val) {
    state.answers = val;
  },

  setSurveys(state, val) {
    state.surveys = val;
  },

  setColorMatches(state, val) {
    state.colorMatches.data = val;
    state.colorMatchesLoaded = true;
  },

  setLightWorksRecommendations(state, val) {
    state.lightWorksRecommendations = val;
  },

  setRootPerfectionRecommendation(state, val) {
    state.rootPerfectionRecommendation = val;
  },

  setLightWorksQuizCompleted(state, val) {
    state.lightWorksQuizCompleted = Boolean(val);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
