<template lang="pug">
  .custom-notify-box
    .notifications(aria-live="polite")
      transition-group(name="fade" tag="div")
        .message(v-for="msg in notifications" :key="msg.id" :class="[msg.class]" v-cloak)
          .msg-txt(:class="[iconClass(msg)]" data-mr-ass="notification-msg") {{ msg.message }}
          button.transparent-btn.close-notification(type='button' @click="$emit('remove-notification', msg.id)" aria-label="Close notification" data-mr-ass="close-notification") &times;

</template>
<script>
  export default {
    name: "CustomNotifications",

    props: {
      notifications: {
        type: Array,
        required: true
      }
    },

    methods: {
      iconClass(msg) {
        let cl = '';
        switch (msg.class) {
          case 'success':
            cl = 'icon-check';
            break;

          case 'error':
            cl = 'icon-warning';
            break;
        }

        return cl;
      }
    }
   };
</script>

<style scoped lang="stylus">
  .custom-notify-box
    .notifications
      top 0px
      width inherit
      position absolute

      .message
        top 0px
        left 0px
        width inherit
        position relative
        box-sizing border-box

        &.first-of-type
          border-top none

        &.error
          background-color #ffdede
          border-bottom 1px solid darken(#ffdede, 5)

        &.success
          background-color #d7f0e6
          border-bottom 1px solid darken(#d7f0e6, 5)

        &.warn
          background-color #fffac3
          border-bottom 1px solid darken(#fffac3, 5)

        &.info
          background-color #d9e4f3
          border-bottom 1px solid darken(#d9e4f3, 5)

        &.ng-leave
          transition all linear 0.3s
          -webkit-transition all linear 0.3s
          opacity(100)
          max-height 100px
          min-height 28px
          overflow hidden

        &.ng-leave-active
          opacity(30)
          max-height 1px
          min-height 1px
          overflow hidden

        &.ng-enter
          transition all linear 0.2s
          -webkit-transition all linear 0.2s
          opacity(10)
          max-height 1px
          min-height 1px
          overflow hidden

        &.ng-enter-active
          opacity(100)
          max-height 100px
          min-height 28px
          overflow hidden

        .msg-txt
          color #333333
          font-size 14px
          max-width 1180px
          margin-left auto
          padding 20px 30px
          text-align center
          margin-right auto

        .close-notification
          top 50%
          right 12px
          padding 4px
          font-size 18px
          cursor pointer
          position absolute
          transform translateY(-50%)
          color text-color-2

</style>