// This is the dayjs instance that we are using across the entire site
// You can import this using "import dayjs from '@dayjs';" wherever you need it 
// If you need a new plugin, please add it here, but be aware about the extra size that plugin could add to our vendor chunk
import dayjs from 'dayjs';
import customParserFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(relativeTime);
dayjs.extend(customParserFormat);
dayjs.extend(advancedFormat);
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(timezone);

export default dayjs;